import { Box, Button, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { RiArrowRightLine, RiBallPenLine } from "react-icons/ri";
import { useSignature } from "../../../service/signatureService";
import { AllLoading } from "../../../components/allLoading";
import { formatCurrency, formatedMonthDay } from "../../../utils/utils";

export function ShowSignature() {
  const { getSignature, data, isLoading } = useSignature();

  useEffect(() => {
    getSignature();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="center"
      justifyItems="center"
      bg="gray.50"
      height="100%"
      pb={20}
      pt={10}
    >
      {isLoading && <AllLoading />}

      {data && data.id && (
        <Box>
          <Box>
            {cardInformationPlan("PLANO ATUAL", data.plan.name, true, 500)}
          </Box>
          <Box mt={5}>
            {cardInformationPlan(
              "NÚMERO DE VEÍCULOS",
              data.cars_number.toString(),
              false,
              500
            )}
          </Box>
          <Box mt={5}>
            {cardInformationPlan(
              "VALOR DA MENSALIDADE",
              `R$${formatCurrency(data.plan.monthly_price)}`,
              false,
              500
            )}
          </Box>
          <Box mt={5} display="flex" gap={2}>
            <Box>
              {cardInformationPlan(
                "DATA DE INÍCIO",
                formatedMonthDay(data.signature_date),
                false,
                245
              )}
            </Box>
            <Box>
              {cardInformationPlan(
                "DIA DO VENCIMENTO",
                data.due_day.toString(),
                false,
                245
              )}
            </Box>
          </Box>

          <Box display="flex" gap={2} mt={5}>
            <Box width={245}>
              <Text
                fontSize="x-large"
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontWeight="bold"
                height={55}
              >
                STATUS:
              </Text>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width={245}
              height={55}
              borderRadius={999}
              bg={data.status === "active" ? "green" : "red"}
            >
              <Text fontSize="lg" color="white">
                {data.status === "active" ? "ATIVA" : "DESATIVADA"}
              </Text>
            </Box>
          </Box>

          <Box
            mt={10}
            display="flex"
            width={500}
            gap={4}
            justifyContent="center"
          >
            {data.status === "active" && (
              <Button
                leftIcon={<RiBallPenLine />}
                colorScheme="teal"
                variant="outline"
                as="a"
                href="/atualiza-plano"
              >
                Alterar plano
              </Button>
            )}

            <Button
              rightIcon={<RiArrowRightLine />}
              colorScheme="teal"
              variant="link"
              as="a"
              href="/faturas"
            >
              Ver Faturas
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

function cardInformationPlan(
  title: string,
  value: string,
  isActive: boolean,
  width: number
) {
  return (
    <Box>
      <Text fontSize="lg" fontStyle="heading" fontWeight="bold">
        {title}
      </Text>
      <Box
        mt={2}
        width={width}
        height={50}
        bg={isActive ? "green.400" : "green.50"}
        borderWidth={2}
        borderColor="green.400"
        alignItems="center"
        justifyContent="center"
        display="flex"
        px={15}
        borderRadius={999}
      >
        <Text color={isActive ? "white" : "green.400"} fontWeight="bold">
          {value}
        </Text>
      </Box>
    </Box>
  );
}
