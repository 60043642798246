import { useEffect } from "react";
import { useOffice } from "../../service/officeService";
import { InputSelect } from "../form/inputSelect";
import { FieldError } from "react-hook-form";

interface IOptions {
  label: string;
  value?: string;
  icon?: any;
}

type Props = {
  name: string;
  onChange?: (value: any) => void;
  value?: object;
  label?: string;
  isDisabled?: boolean;
  defaultValue?: IOptions;
  width: string;
  isUpdate?: boolean;
  isTransfer?: boolean;
  dataUpdate?: string;
  id?: string;
  error?: FieldError;
};
export function OfficeSelect({
  name,
  onChange,
  value,
  isDisabled,
  defaultValue,
  width,
  isUpdate = false,
  isTransfer = false,
  dataUpdate = "",
  id = "",
  label = "",
  error,
}: Props) {
  const { getOffice, data, dataOptions, isLoading } = useOffice();

  useEffect(() => {
    if (isTransfer && id) {
      getOffice(id);
    } else if (!isTransfer) {
      getOffice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function nameOffice() {
    const dataOffice = data.find((it) => it.id === dataUpdate);
    if (dataOffice) {
      return `${dataOffice.name_ug} - ${dataOffice.cnpj}`;
    }

    return "";
  }

  return (
    <InputSelect
      options={dataOptions}
      name={name}
      width={width}
      label={label ? label : "UNIDADE DE GESTÃO"}
      placeholder="Selecione..."
      onChange={onChange}
      value={
        !isUpdate
          ? value
          : {
              label: nameOffice(),
              value: dataUpdate,
            }
      }
      isDisabled={isDisabled}
      defaultValue={defaultValue}
      isLoading={isLoading}
      error={error}
    />
  );
}
