import { Navigate } from "react-router-dom";
import { AuthTemplate } from "../components/authTemplate/AuthTemplate";
import { useAuth } from "../hooks/useAuth";
import { FullLoading } from "../components/fullLoading";

interface AuthRouteProps {
  children: JSX.Element;
  path?: string;
}

export function AuthRoute({ children, path }: AuthRouteProps) {
  const { isAuthenticated, isAtuhLoading } = useAuth();

  if (isAtuhLoading) {
    return <FullLoading />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return <AuthTemplate path={path}>{children}</AuthTemplate>;
}
