import { useState } from "react";
import { api } from "../../../../service/api";
import { CarDTO } from "../../../../dtos/CarDTO";

export function useShowCar() {
  const [isLoadingCar, setIsLoadingCar] = useState(false);
  const [dataCar, setDataCar] = useState<CarDTO>({} as CarDTO);

  const findCar = async (id: string) => {
    try {
      setIsLoadingCar(true);
      const response = await api.get("company/car/show/" + id);
      setDataCar(response.data);
    } catch (error) {
      throw error;
    } finally {
      setIsLoadingCar(false);
    }
  };

  return { isLoadingCar, findCar, dataCar };
}
