import {
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import LogoEtank from "../../../assets/Logo-e-tank-grande.png";
import { Input } from "../../../components/form/input";
import { InputPassword } from "../../../components/form/inputPassword";
import { InputMask } from "../../../components/form/inputMask";
import { AllLoading } from "../../../components/allLoading";
import { useEffect, useState } from "react";
import { CnpjDataDTO } from "../../../dtos/CnpjDataDTO";
import { api } from "../../../service/api";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { InputFilterSelect } from "../../../components/filter/inputFilterSelect";
import { formatCurrency } from "../../../utils/utils";
import { OptionsDTO } from "../../../dtos/OptionsDTO";
import { PlanDTO } from "../../../dtos/PlanDTO";
import { ModalContract } from "./modal/modal-contract";

type createFormData = {
  name: string;
  email: string;
  email_company: string;
  company_name: string;
  responsible: string;
  password: string;
  cellphone: string;
  cnpj: string;
  company_number: string;
  fantasy_name?: string;
  city: string;
  uf: string;
  place: string;
  cep: string;
  complement?: string;
  term?: boolean;
  due_day: number;
  plan_id: string;
  payment_type?: string;
};

const list_of_due_dates = [10, 15, 20, 25];

const createFormDataSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório."),
  company_name: yup.string().required("Campo obrigatório."),
  email: yup
    .string()
    .required("Email é um campo obrigatório.")
    .email("Email não é valido."),
  email_company: yup
    .string()
    .required("Email é um campo obrigatório.")
    .email("Email não é valido."),
  responsible: yup.string().required("Campo obrigatório."),
  password: yup.string().required("Campo obrigatório."),
  cellphone: yup.string().required("Campo obrigatório."),
  cnpj: yup.string().required("Campo obrigatório."),
  company_number: yup.string().required("Campo obrigatório."),
  fantasy_name: yup.string(),
  cep: yup.string().required("Campo obrigatório."),
  city: yup.string().required("Campo obrigatório."),
  uf: yup.string().required("Campo obrigatório."),
  place: yup.string().required("Campo obrigatório."),
  complement: yup.string(),
  term: yup.boolean(),
  due_day: yup.number().required("Campo obrigatório."),
  plan_id: yup.string().required("Campo obrigatório."),
  payment_type: yup.string(),
});

export function RegisterAccount() {
  const navigate = useNavigate();
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [loadingPlans, setLoadingPlans] = useState(false);

  const [isOpenModalContract, setIsOpenModalContract] = useState(false);

  const [plans, setPlans] = useState<PlanDTO[]>([]);
  const [planSelected, setPlanSelected] = useState<PlanDTO>({} as PlanDTO);

  const [plansOptions, setPlansOptions] = useState<OptionsDTO[]>([]);

  const { isAuthenticated, isAtuhLoading } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createFormDataSchema) });

  async function getPlans() {
    try {
      setLoadingPlans(true);
      const response = await api.get("company/plan/index");

      const planData = response.data;

      setPlans(planData);

      const newDataPlan: OptionsDTO[] = [];

      for (let index = 0; index < planData.length; index++) {
        const element = planData[index];
        newDataPlan.push({
          label: element.name,
          value: element.id,
        });
      }

      setPlansOptions(newDataPlan);
    } catch (error) {
    } finally {
      setLoadingPlans(false);
    }
  }

  useEffect(() => {
    getPlans();
  }, []);

  if (isAtuhLoading) return <Box />;

  if (isAuthenticated) {
    return <Navigate to="/home" replace />;
  }

  async function getCompanyCnpj(cnpj: string) {
    try {
      setLoading(true);
      const response = await api.post(`company/cnpj/index`, {
        cnpj,
      });
      const data: CnpjDataDTO = response.data;
      setValue("company_name", data.company_name);
      setValue("fantasy_name", data.fantasy_name);
      setValue("cnpj", data.cnpj);
      setValue("city", data.city);
      setValue("uf", data.state);
      setValue("place", data.public_place);
      setValue("cep", data.cep);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const handleSend: SubmitHandler<createFormData> = async (value) => {
    try {
      value.payment_type = "pix";
      if (!value.term) {
        toast({
          position: "top-right",
          title: "Criar conta",
          description: "Para continuar é necessário aceitar os termos.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }

      await api.post("company/account/create", value);

      navigate("/cadastro/sucesso");

      toast({
        position: "top-right",
        title: "Criar conta",
        description: "Conta cadastrada com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: "top-right",
        title: "Criar conta",
        description: "Não foi possível criar conta.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      w="100vw"
      h="120vh"
      justifyContent="center"
      alignItems="center"
      px={40}
      py={20}
      as="form"
      onSubmit={handleSubmit(handleSend)}
    >
      <ModalContract
        is_open={isOpenModalContract}
        onClose={() => {
          setIsOpenModalContract(false);
        }}
      />

      {(loading || isSubmitting) && <AllLoading />}

      <Stack flex={1} px={100} spacing={1}>
        <Flex gap={2}>
          <InputMask
            size="md"
            label="CNPJ:"
            placeholder="Informe o CNPJ"
            mask="99.999.999/9999-99"
            {...register("cnpj")}
            onChange={(e) => {
              let cnpj = e.target.value;
              if (cnpj.length === 18) {
                getCompanyCnpj(cnpj);
              }
            }}
            error={errors.cnpj}
          />

          <Input
            size="md"
            label="Responsável:"
            placeholder="Informe o responsável"
            {...register("responsible")}
            error={errors.responsible}
          />
        </Flex>
        <Input
          size="md"
          {...register("company_name")}
          error={errors.company_name}
          label="Razão social:"
          placeholder="Informe a razão social"
        />
        <Input
          size="md"
          {...register("fantasy_name")}
          error={errors.fantasy_name}
          label="Nome fantasia:"
          placeholder="Informe o nome fantasia"
        />
        <Flex gap={2}>
          <Input
            size="md"
            type="email"
            label="Email do responsável:"
            placeholder="Informe o email do responsável"
            {...register("email_company")}
            error={errors.email_company}
          />
          <InputMask
            size="md"
            label="Celular:"
            placeholder="Informe o celular"
            mask="(99)99999-99999"
            {...register("cellphone")}
            error={errors.cellphone}
          />
        </Flex>
        <Flex gap={2}>
          <InputMask
            size="md"
            label="CEP:"
            placeholder="Informe o CEP"
            width="376px"
            mask="99999-999"
            {...register("cep")}
          />
          <Input
            size="md"
            label="Município:"
            placeholder="Informe o Município"
            width="265px"
            {...register("city")}
          />
          <Input
            size="md"
            label="UF:"
            placeholder="UF"
            width="100px"
            textAlign="center"
            {...register("uf")}
          />
        </Flex>

        <Input
          size="md"
          label="Logradouro:"
          placeholder="Informe o Logradouro"
          {...register("place")}
        />

        <Flex gap={2}>
          <Input
            size="md"
            label="Número:"
            placeholder="Informe o número"
            {...register("company_number")}
            error={errors.company_number}
          />
          <Input
            size="md"
            label="Complemento:"
            placeholder="Informe o complemento"
            {...register("complement")}
          />
        </Flex>

        <Input
          size="md"
          label="Nome do usuário:"
          placeholder="Informe o complemento"
          {...register("name")}
          error={errors.name}
        />

        <Flex gap={2}>
          <Input
            size="md"
            label="Email:"
            type="email"
            placeholder="Informe o email"
            {...register("email")}
            error={errors.name}
          />

          <InputPassword
            size="md"
            type="password"
            label="Senha:"
            placeholder="Informe a senha"
            {...register("password")}
            error={errors.name}
          />
        </Flex>
      </Stack>

      <Flex alignItems="center" flexDirection="column" width="400px">
        <Image src={LogoEtank} width={200} alt="Etank" />

        <Text fontWeight="bold" mt={2} fontSize="36px" color="gray.800">
          CRIAR CONTA
        </Text>

        <Text color="gray.600" textAlign="center" maxWidth="400px">
          Contrate agora mesmo o e-tank para sua empresa
        </Text>

        <Box mt={10}>
          <Text fontWeight="bold" mb={2} fontSize="20px" color="gray.600">
            Planos:
          </Text>
          <Controller
            control={control}
            name="plan_id"
            render={({ field: { onChange, value } }) => (
              <InputFilterSelect
                name="plan_id"
                isLoading={loadingPlans}
                options={plansOptions}
                width="400px"
                placeholder="Selecione um plano"
                onChange={(item) => {
                  onChange(item.value);

                  const plan = plans.find((p) => p.id === item.value);
                  if (plan) {
                    setPlanSelected(plan);
                  }
                }}
                error={errors.plan_id}
              />
            )}
          />
          {planSelected && planSelected.id && (
            <Flex mt={5} alignItems="center" width="400px">
              <Box
                display="flex"
                minWidth={82}
                height={39}
                bg="green.400"
                px={5}
                borderRadius={999}
                alignItems="center"
                justifyItems="center"
              >
                <Text color="white" fontWeight="bold">
                  R$ {formatCurrency(planSelected.monthly_price)}
                </Text>
              </Box>
              <Text ml={2} fontWeight="bold">
                {planSelected.cars_number} Veículos
              </Text>
            </Flex>
          )}
        </Box>
        <Box mt={5}>
          <Text fontWeight="bold" mb={2} fontSize="20px" color="gray.600">
            Dia do vencimento:
          </Text>

          <Controller
            control={control}
            name="due_day"
            render={({ field: { onChange, value } }) => (
              <Box>
                <Flex mt={1} alignItems="center" width="400px" gap={2}>
                  {list_of_due_dates.map((item, index) => (
                    <Box
                      key={index}
                      display="flex"
                      minWidth={50}
                      height={39}
                      bg={item === value ? "green.600" : "green.50"}
                      borderColor="green.600"
                      borderWidth={1}
                      px={5}
                      borderRadius={999}
                      alignItems="center"
                      justifyItems="center"
                      cursor="pointer"
                      _hover={{
                        bg: "green.600",
                        transition: "1s",
                      }}
                      role="group"
                      onClick={() => {
                        onChange(item);
                      }}
                    >
                      <Text
                        color={item === value ? "white" : "green.600"}
                        _groupHover={{
                          color: "white",
                        }}
                        fontWeight="bold"
                      >
                        {item}
                      </Text>
                    </Box>
                  ))}
                </Flex>
                <Text color="red.500">{errors.due_day?.message}</Text>
              </Box>
            )}
          />
        </Box>

        <Box mt={10}>
          <Button
            type="submit"
            bg="green.600"
            color="white"
            _hover={{
              bg: "green.800",
            }}
            size="lg"
            width="100%"
          >
            CRIAR CONTA
          </Button>

          <Flex mt={5} alignItems="center">
            <Checkbox
              size="md"
              colorScheme="green"
              {...register("term")}
              borderColor="gray.600"
            >
              Declaro que li e concordo integralmente com todos os termos do
              contrato.
            </Checkbox>

            <Button
              size="sm"
              width="150px"
              onClick={() => {
                setIsOpenModalContract(true);
              }}
            >
              Ver contrato
            </Button>
          </Flex>
          {!!errors && <Text>{errors.term?.message}</Text>}
        </Box>
      </Flex>
    </Flex>
  );
}
