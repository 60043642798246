import { useState } from "react";
import { api } from "../../../../service/api";
import { LocatorDTO } from "../../../../dtos/LocatorDTO";

export function useListLocator() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<LocatorDTO[]>([]);

  const getLocator = async () => {
    try {
      setIsLoading(true);
      const response = await api.get("company/locator/list");
      setData(response.data);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, getLocator, data };
}
