import {
  FormControl,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormErrorMessage,
} from "@chakra-ui/react";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { FieldError } from "react-hook-form";

interface Props extends ChakraInputProps {
  name: string;
  label?: string;
  error?: FieldError;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { name, label, error, ...rest },
  ref
) => {
  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <FormLabel mb={-1} color="gray.800" htmlFor={name}>
          {label}
        </FormLabel>
      )}

      <ChakraInput
        {...rest}
        id={name}
        name={name}
        borderColor="gray.600"
        borderBottomWidth={2}
        focusBorderColor="green.400"
        variant="flushed"
        size="sm"
        _hover={{
          bgColor: "transparent",
        }}
        ref={ref}
      />
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export const InputLine = forwardRef(InputBase);
