import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  SimpleGrid,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { HeaderCard } from "../../../../components/headerCard";
import { useListTravel } from "../hooks/useListTravel";
import { RiCloseFill, RiMap2Line } from "react-icons/ri";
import { formatedDateTimer } from "../../../../utils/utils";
import { useCancelTravel } from "../hooks/useCancelTravel";
import { useRef, useState } from "react";
import { TravelDTO } from "../../../../dtos/TravelDTO";

export function ListTravel() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [TravelInfo, setTravelInfo] = useState<TravelDTO>({} as TravelDTO);

  const { dataList, refetch, loading } = useListTravel();
  const { cancelTravel, loadingCancel } = useCancelTravel();

  const cancelRef = useRef<any>(null);

  async function cancelTravelData(id: string) {
    try {
      await cancelTravel(id);

      toast({
        position: "top-right",
        title: "Frota em viagem",
        description: "Frota em viagem cancelada com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: "top-right",
        title: "Frota em viagem",
        description: "Não foi possível cancelar a frota em viagem.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      onClose();
      setTravelInfo({} as TravelDTO);
      refetch();
    }
  }

  return (
    <Box>
      <HeaderCard
        title="FROTAS EM VIAGENS"
        titleButton="Nova viagem"
        link="/frotas-em-viagens/nova"
      />

      {loading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={500}
        >
          <Spinner
            color="green.600"
            emptyColor="white"
            thickness="4px"
            size="xl"
            h={100}
            w={100}
          />
        </Box>
      )}

      <SimpleGrid
        flex="1"
        gap="4"
        minChildWidth="320px"
        alignItems="flex-start"
        px={1}
        py={1}
      >
        {dataList.length > 0 && !loading && (
          <Box p="4" borderRadius={8} height={500}>
            <TableContainer>
              <Table variant="striped" colorScheme="gray" size="sm">
                <Thead>
                  <Tr bg="green.600" height="10">
                    <Th color="white" textAlign="center">
                      CÓDIGO
                    </Th>
                    <Th color="white" textAlign="center">
                      PLACA
                    </Th>
                    <Th color="white" textAlign="center">
                      DESTINO
                    </Th>
                    <Th color="white" textAlign="center">
                      MOTIVO VIAGEM
                    </Th>
                    <Th color="white" textAlign="center">
                      MOTORISTA
                    </Th>
                    <Th color="white" textAlign="center">
                      TIPO
                    </Th>
                    <Th color="white" textAlign="center">
                      SAÍDA
                    </Th>
                    <Th color="white" textAlign="center">
                      CHEGADA
                    </Th>
                    <Th color="white" textAlign="center">
                      STATUS
                    </Th>
                    <Th color="white" textAlign="center">
                      AÇÕES
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {dataList.map((item, index) => (
                    <Tr key={index}>
                      <Td textAlign="center">
                        {item.car_fleet.code_car_fleet}
                      </Td>
                      <Td textAlign="center">{item.car_fleet.plaque}</Td>
                      <Td textAlign="center">{item.destination_travel}</Td>
                      <Td textAlign="center">{item.motive_travel}</Td>
                      <Td textAlign="center">{item.motorist.name}</Td>
                      <Td textAlign="center">{typeTravel(item.type)}</Td>
                      <Td textAlign="center">
                        {formatedDateTimer(item.departure_date, true)}
                      </Td>
                      <Td textAlign="center">
                        {formatedDateTimer(item.arrival_date, true)}
                      </Td>
                      <Td textAlign="center">{statusTravel(item.status)}</Td>
                      <Td textAlign="center" width="10">
                        <Button
                          size="sm"
                          fontSize="sm"
                          as="a"
                          href={`/frotas-em-viagens/datalhes/${item.id}`}
                        >
                          <Icon fontSize="16px" as={RiMap2Line} />
                        </Button>
                        <Button
                          size="sm"
                          ml={1}
                          fontSize="sm"
                          onClick={() => {
                            if (item.status === "progress") {
                              setTravelInfo(item);
                              onOpen();
                            } else {
                              toast({
                                position: "top-right",
                                title: "Frota em viagem",
                                description:
                                  "Não foi possível cancelar a frota em viagem.",
                                status: "warning",
                                duration: 9000,
                                isClosable: true,
                              });
                            }
                          }}
                        >
                          <Icon fontSize="16px" as={RiCloseFill} />
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </SimpleGrid>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Cancelar frota em viagem
            </AlertDialogHeader>

            <AlertDialogBody>
              {!loadingCancel && (
                <Text>
                  Deseja cancelar a viagem de{" "}
                  <strong>{TravelInfo?.origin_travel}</strong> para{" "}
                  <strong>{TravelInfo?.destination_travel}</strong> da frota{" "}
                  <strong>{TravelInfo?.car_fleet?.code_car_fleet}</strong>
                </Text>
              )}

              {loadingCancel && (
                <Flex width="100%" alignItems="center" justifyContent="center">
                  <Spinner
                    color="green.600"
                    emptyColor="white"
                    thickness="4px"
                    size="xl"
                    h={50}
                    w={50}
                  />
                </Flex>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              {!loadingCancel && (
                <>
                  <Button
                    colorScheme="red"
                    onClick={() => {
                      cancelTravelData(TravelInfo?.id);
                    }}
                  >
                    Sim
                  </Button>
                  <Button ml={3} onClick={onClose}>
                    Nāo
                  </Button>
                </>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}

function statusTravel(status: string) {
  if (status === "finished") {
    return <Badge colorScheme="green">Finalizada</Badge>;
  }

  if (status === "canceled") {
    return <Badge colorScheme="red">Cancelada</Badge>;
  }

  if (status === "progress") {
    return <Badge>Andamento</Badge>;
  }
}

function typeTravel(type: string) {
  if (type === "outward_trip") return "Somente Ida";

  return "Ida e Volta";
}
