import { useEffect, useState } from "react";
import { api } from "../../../../service/api";
import { useSearchParams } from "react-router-dom";

interface IResumeGeneral {
  quantity_requisition: number;
  quantity_attend: number;
  quantity_cars: number;
  quantity_supply: number;
  total_amount: number;
}

export function useResumeGeneral() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataList, setDataList] = useState<IResumeGeneral>(
    {} as IResumeGeneral
  );

  const [searchParams] = useSearchParams();

  const office_id = searchParams.get("office_id") ?? "";
  const furnisher_id = searchParams.get("furnisher_id") ?? "";
  const start_date = searchParams.get("start_date") ?? "";
  const end_date = searchParams.get("end_date") ?? "";

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `company/dash/requisition/resume/list?office_branch_id=${office_id}&furnisher_id=${furnisher_id}&start_date=${start_date}&end_date=${end_date}`
      );
      const data = response.data;
      setDataList({
        ...data,
      });
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [office_id, furnisher_id, start_date, end_date]);

  return {
    dataList,
    loading,
    error,
    refetch: fetchData,
  };
}
