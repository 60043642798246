import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { FieldError } from "react-hook-form";

interface IOptions {
  label: string;
  value?: string;
  icon?: any;
}

type Props = {
  name: string;
  options: IOptions[];
  placeholder: string;
  label?: string;
  colorLabel?: string;
  error?: FieldError;
  isLoading?: boolean;
  onChange?: (value: any) => void;
  isDisabled?: boolean;
  defaultValue?: IOptions;
  width: string;
  value?: object;
};

export function InputFilterSelect({
  options,
  placeholder,
  label,
  colorLabel = "white",
  error,
  name,
  isLoading = false,
  onChange,
  isDisabled = false,
  defaultValue,
  width,
  value,
}: Props) {
  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <FormLabel
          fontWeight={colorLabel !== "white" ? "bold" : "normal"}
          color={colorLabel}
          htmlFor={name}
        >
          {label}
        </FormLabel>
      )}
      <Select
        name={name}
        size="md"
        onChange={onChange}
        isLoading={isLoading}
        loadingMessage={() => "Carregando..."}
        noOptionsMessage={() => "Sem opções"}
        focusBorderColor="green.600"
        selectedOptionStyle="check"
        placeholder={placeholder}
        options={options}
        isDisabled={isDisabled}
        defaultValue={defaultValue}
        value={value}
        chakraStyles={{
          control: (provided) => ({
            ...provided,
            borderColor: "#E2E8F0",
            borderWidth: 2,
            mt: -1,
            width: width,
            bg: "white",
          }),
        }}
      />
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
}
