import { Box, Flex, Heading } from "@chakra-ui/react";
import { MultiStep } from "../../../../components/multiStep";
import { ProcessInfo, SelectProcess } from "./step/select-process";
import { FunisherInfo, SelectFurnisher } from "./step/select-furnisher";
import { FormDataFleet } from "./step/form-data-fleet";
import { FormUnregisteredVehicle } from "./step/form-unregistered-vehicle";
import {
  ProductOrService,
  ProductOrServiceInfo,
} from "./step/product-or-service";
import { useState } from "react";
import { DataResume } from "./step/data-resume";
import { RequistionTypeCar } from "../types/requistion-type-car";
import { CateogryInfo, SelectCategory } from "./step/select-categories";

export function CreateRequisition() {
  const [step, setStep] = useState(1);

  const [dataProcess, setDataProcess] = useState<ProcessInfo>(
    {} as ProcessInfo
  );

  const [dataFurnisher, setDataFurnisher] = useState<FunisherInfo>(
    {} as FunisherInfo
  );

  const [dataCar, setDataCar] = useState<RequistionTypeCar>(
    {} as RequistionTypeCar
  );

  const [dataCategory, setDataCategory] = useState<CateogryInfo>(
    {} as CateogryInfo
  );

  const [dataProductOrService, setDataProductOrService] =
    useState<ProductOrServiceInfo>({} as ProductOrServiceInfo);

  return (
    <Box>
      <Flex justifyContent="center">
        <Box>
          <Box mt={5} width={300}>
            <Heading fontSize="x-large" mb={5}>
              Requisição de entrada
            </Heading>
            <MultiStep size={6} currentStep={step} />
          </Box>

          <Box mt={5}>
            {step === 1 && (
              <SelectProcess
                callBackSuccess={(data) => {
                  setDataProcess(data);
                  setStep(2);
                }}
              />
            )}
            {step === 2 && (
              <SelectFurnisher
                callBackSuccess={(data) => {
                  setDataFurnisher(data);
                  setStep(3);
                }}
              />
            )}
            {step === 3 && dataProcess.operation !== "not_registered" && (
              <FormDataFleet
                property={dataProcess.operation}
                callBackSuccess={(data) => {
                  setDataCar(data);
                  setStep(4);
                }}
              />
            )}
            {step === 3 && dataProcess.operation === "not_registered" && (
              <FormUnregisteredVehicle
                callBackSuccess={(data) => {
                  setDataCar(data);
                  setStep(4);
                }}
              />
            )}
            {step === 4 && (
              <SelectCategory
                callBackSuccess={(data) => {
                  setDataCategory(data);
                  setStep(5);
                }}
              />
            )}
            {step === 5 && (
              <ProductOrService
                category={dataCategory.category_name}
                type={dataCar.type_fuel}
                furnisher_id={dataFurnisher.furnisher_id}
                office_id={dataFurnisher.office_branch_id}
                callBackSuccess={(data) => {
                  setDataProductOrService(data);
                  setStep(6);
                }}
              />
            )}
            {step === 6 && (
              <DataResume
                processInfo={dataProcess}
                funisherInfo={dataFurnisher}
                requistionTypeCar={dataCar}
                cateogryInfo={dataCategory}
                productOrServiceInfo={dataProductOrService}
              />
            )}
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}
