import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { HeaderCard } from "../../../../components/headerCard";
import { RiArrowLeftSLine } from "react-icons/ri";
import { useShowMovement } from "../hooks/useShowMovement";
import { useParams } from "react-router-dom";
import { AllLoading } from "../../../../components/allLoading";
import { useEffect, useRef } from "react";
import { formatedDateTimer } from "../../../../utils/utils";
import { statusList } from "../list";
import { useAuth } from "../../../../hooks/useAuth";
import { useUpdateStatusMovement } from "../hooks/useUpdateStatusMovement";

export function DetailFleetMovement() {
  let { id } = useParams();
  const { user } = useAuth();

  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<any>(null);

  const { showMovement, dataMovement, isLoadingMovement } = useShowMovement();

  const { updateStatusMovement, isLoadingUpdate } = useUpdateStatusMovement();

  async function sendUdate(status: string) {
    try {
      onClose();
      await updateStatusMovement(id as string, status);

      toast({
        position: "top-right",
        title: "Produto",
        description:
          status === "approved"
            ? "Movimentação aprovado com sucesso."
            : status === "finished"
            ? "Movimentação finalizada com sucesso."
            : "Movimentação cancelado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: "top-right",
        title: "Produto",
        description: "Não foi possível finalizar sua solicitação.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      onClose();
      showMovement(id as string);
    }
  }

  useEffect(() => {
    showMovement(id as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Box>
      <HeaderCard
        title="MOVIMENTAÇÃO DA FROTA"
        titleButton="Voltar"
        icon={RiArrowLeftSLine}
        link="/movimentacao-frota"
      />

      {(isLoadingMovement || isLoadingUpdate) && <AllLoading />}

      {!isLoadingMovement && dataMovement.id && (
        <Box width={745} margin="auto" mt={5} mb={10}>
          <Stack spacing="4">
            <Card>
              <CardHeader>
                <Heading size="sm">INFORMAÇÕES DA SOLICITAÇÃO:</Heading>
              </CardHeader>

              <Box px={5}>
                <Box display="flex" alignItems="center">
                  <Heading size="xs" textTransform="uppercase" color="gray.800">
                    Status:
                  </Heading>
                  <Text
                    ml={1}
                    textAlign="center"
                    fontSize="sm"
                    color="gray.600"
                  >
                    {statusList(dataMovement.status)}
                  </Text>
                </Box>
              </Box>

              <CardBody>
                <Stack divider={<StackDivider />} spacing="4">
                  <Box display="flex" gap={4}>
                    {cardInfo("OPERAÇÃO:", dataMovement.operation)}
                    {cardInfo(
                      "DATA DA MOVIMENTAÇÃO:",
                      formatedDateTimer(dataMovement.date_of_movement, true)
                    )}
                    {cardInfo(
                      "MOTIVO DA MOVIMENTAÇÃO:",
                      dataMovement.motive_movement
                    )}
                  </Box>
                  <Box display="flex" gap={4}>
                    {cardInfo(
                      "UNIDADE DE GESTÃO:",
                      dataMovement.office_branch.name_ug
                    )}
                    {dataMovement.office_branch_destination &&
                      cardInfo(
                        "UNIDADE DE GESTÃO DE DESTINO:",
                        dataMovement.office_branch_destination.name_ug
                      )}
                  </Box>
                  <Box display="flex" gap={4}>
                    {cardInfo("APROVADOR:", dataMovement.user_approver.name)}
                  </Box>
                  <Box display="flex" gap={4}>
                    {cardInfo("OBSERVAÇÕES:", dataMovement.observation)}
                  </Box>
                </Stack>
              </CardBody>
            </Card>
          </Stack>
          <Stack spacing="4" mt={5}>
            <Card>
              <CardHeader>
                <Heading size="sm">INFORMAÇÕES DA FROTA:</Heading>
              </CardHeader>

              <CardBody>
                <Stack divider={<StackDivider />} spacing="4">
                  <Box display="flex" gap={4}>
                    {cardInfo(
                      "TIPO:",
                      dataMovement.car_fleet.structure_type.type
                    )}
                    {cardInfo("GRUPO:", dataMovement.car_fleet.group_name)}
                    {cardInfo("CÓDIGO:", dataMovement.car_fleet.code_car_fleet)}
                  </Box>

                  <Box display="flex" gap={4}>
                    {cardInfo("PLACA:", dataMovement.car_fleet.plaque)}
                    {cardInfo("MARCA:", dataMovement.car_fleet.mark)}
                    {cardInfo("MODELO:", dataMovement.car_fleet.model)}
                  </Box>
                </Stack>
              </CardBody>
            </Card>
          </Stack>

          {dataMovement.status === "progress" &&
            dataMovement.user_id === user.id && (
              <Box
                display="flex"
                width="100%"
                alignItems="center"
                justifyContent="flex-end"
                gap={2}
              >
                <Button
                  type="submit"
                  height={10}
                  colorScheme="red"
                  mt="6"
                  size="lg"
                  onClick={() => {
                    onOpen();
                  }}
                >
                  Reprovar
                </Button>
                <Button
                  type="submit"
                  bg="green.600"
                  color="white"
                  alignSelf="flex-end"
                  height={10}
                  _hover={{
                    bg: "green.800",
                  }}
                  mt="6"
                  size="lg"
                  onClick={() => {
                    sendUdate("approved");
                  }}
                >
                  Aprovar
                </Button>
              </Box>
            )}

          {dataMovement.status === "approved" &&
            dataMovement.user_id_solicitor === user.id && (
              <Box
                display="flex"
                width="100%"
                alignItems="center"
                justifyContent="flex-end"
                gap={2}
              >
                <Button
                  type="submit"
                  bg="green.600"
                  color="white"
                  alignSelf="flex-end"
                  height={10}
                  _hover={{
                    bg: "green.800",
                  }}
                  mt="6"
                  size="lg"
                  onClick={() => {
                    sendUdate("finished");
                  }}
                >
                  Finalizar
                </Button>
              </Box>
            )}
        </Box>
      )}

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Reprovar movimentação.
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>Deseja reprovar movimentação?</Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    sendUdate("canceled");
                  }}
                >
                  Sim
                </Button>
                <Button ml={3} onClick={onClose}>
                  Nāo
                </Button>
              </>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}

function cardInfo(title: string, descrition: string) {
  return (
    <Box>
      <Heading size="xs" textTransform="uppercase" color="gray.800">
        {title}
      </Heading>
      <Text pt="1" textAlign="center" fontSize="sm" color="gray.600">
        {descrition}
      </Text>
    </Box>
  );
}
