import { Box, Button, Flex, Image, Link, Stack, Text } from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { SubmitHandler, useForm, Controller } from "react-hook-form";

import LogoEtank from "../../../assets/Logo-e-tank-grande.png";
import { Input } from "../../../components/form/input";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { InputPin } from "../../../components/form/inputPin";
import { InputPassword } from "../../../components/form/inputPassword";
import { api } from "../../../service/api";

type authFormData = {
  email: string;
  recover_token: string;
  password: string;
  re_password: string;
};

const authFormDataSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email é um campo obrigatório.")
    .email("Email não é valido."),
  recover_token: yup
    .string()
    .required("Campo obrigatório.")
    .min(6, "Campo obrigatório.")
    .max(6, "Campo obrigatório."),
  password: yup.string().required("Campo obrigatório."),
  re_password: yup
    .string()
    .required("Confirme a senha")
    .oneOf([yup.ref("password"), ""], "Confirme a senha"),
});

export function UpdatePassword() {
  const navigate = useNavigate();
  let { email } = useParams();

  const { isAuthenticated, isAtuhLoading } = useAuth();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(authFormDataSchema) });

  if (isAtuhLoading) return <Box />;

  if (isAuthenticated) {
    return <Navigate to="/home" replace />;
  }

  const handleSend: SubmitHandler<authFormData> = async (value) => {
    try {
      await api.post("company/updatepassword", {
        email: value.email,
        recover_token: value.recover_token,
        password: value.password,
        confirm_password: value.re_password,
      });
      navigate("/login");
    } catch (error) {
      navigate("/login");
    }
  };

  return (
    <Flex w="100vw" h="100vh" alignItems="center" justifyContent="center">
      <Flex
        as="form"
        width="100%"
        maxWidth={450}
        p="8"
        borderRadius={8}
        flexDir="column"
        onSubmit={handleSubmit(handleSend)}
      >
        <Image src={LogoEtank} width={200} alt="Etank" />

        <Text fontWeight="bold" mt={5} fontSize="36px" color="gray.800">
          Atualização da senha
        </Text>

        <Text color="gray.600">
          Entre com o código de recuperação e a nova senha
        </Text>

        <Stack mt={5} spacing={4}>
          <Input
            label="Email"
            type="email"
            isDisabled
            {...register("email", { value: email })}
            error={errors.email}
            placeholder="Informe o email"
          />

          <Controller
            control={control}
            name="recover_token"
            render={({ field: { onChange, value } }) => (
              <InputPin
                label="Código de segurança"
                name="recover_token"
                onChange={onChange}
                error={errors.recover_token}
              />
            )}
          />

          <InputPassword
            type="password"
            label="Senha"
            {...register("password")}
            error={errors.password}
            placeholder="Informe a nova senha"
          />

          <InputPassword
            type="password"
            label="Confirme a senha"
            {...register("re_password")}
            error={errors.re_password}
            placeholder="Confirme a nova senha"
          />
        </Stack>

        <Button
          isLoading={isSubmitting}
          type="submit"
          bg="green.600"
          color="white"
          _hover={{
            bg: "green.800",
          }}
          mt="6"
          size="lg"
        >
          Atualizar senha
        </Button>

        <Flex mt={5} alignItems="center" justifyContent="center">
          <Link href="/login" color="green.600" fontWeight="bold">
            Voltar
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
}
