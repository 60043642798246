import {
  Badge,
  Box,
  Button,
  Icon,
  SimpleGrid,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { HeaderCard } from "../../../../components/headerCard";
import { useListMovement } from "../hooks/useListMovement";
import { RiAlignJustify } from "react-icons/ri";
import { useEffect } from "react";
import { formatedDateTimer } from "../../../../utils/utils";

export function ListFleetMovement() {
  const { getMovement, dataMovement, isLoadingMovement } = useListMovement();

  useEffect(() => {
    getMovement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <HeaderCard
        title="MOVIMENTAÇÃO DA FROTA"
        titleButton="Nova movimentaçao"
        link="/movimentacao-frota/novo"
      />

      {isLoadingMovement && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={500}
        >
          <Spinner
            color="green.600"
            emptyColor="white"
            thickness="4px"
            size="xl"
            h={100}
            w={100}
          />
        </Box>
      )}

      <SimpleGrid
        flex="1"
        gap="4"
        minChildWidth="320px"
        alignItems="flex-start"
        px={1}
        py={1}
      >
        {dataMovement.length > 0 && !isLoadingMovement && (
          <Box p="4" borderRadius={8} height={500}>
            <TableContainer>
              <Table variant="striped" colorScheme="gray" size="sm">
                <Thead>
                  <Tr bg="green.600" height="10">
                    <Th color="white" textAlign="center">
                      DATA
                    </Th>
                    <Th color="white" textAlign="center">
                      CÓDIGO
                    </Th>
                    <Th color="white" textAlign="center">
                      PLACA
                    </Th>
                    <Th color="white" textAlign="center">
                      MARCA
                    </Th>
                    <Th color="white" textAlign="center">
                      MODELO
                    </Th>
                    <Th color="white" textAlign="center">
                      ODÔMETRO
                    </Th>
                    <Th color="white" textAlign="center">
                      UG ORIGEM
                    </Th>
                    <Th color="white" textAlign="center">
                      UG DESTINO
                    </Th>
                    <Th color="white" textAlign="center">
                      OPERAÇÃO
                    </Th>
                    <Th color="white" textAlign="center">
                      STATUS
                    </Th>
                    <Th color="white" textAlign="center">
                      AÇÕES
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {dataMovement.map((item, index) => (
                    <Tr key={index}>
                      <Td textAlign="center">
                        {formatedDateTimer(item.date_of_movement, true)}
                      </Td>
                      <Td textAlign="center">
                        {item.car_fleet?.code_car_fleet}
                      </Td>
                      <Td textAlign="center">{item.car_fleet?.plaque}</Td>
                      <Td textAlign="center">{item.car_fleet?.mark}</Td>
                      <Td textAlign="center">{item.car_fleet?.model}</Td>
                      <Td textAlign="center">{item.end_odometer}</Td>
                      <Td textAlign="center">{item.office_branch?.name_ug}</Td>
                      <Td textAlign="center">
                        {item.office_branch_destination &&
                          item.office_branch_destination?.name_ug}
                      </Td>

                      <Td textAlign="center">{item.operation}</Td>
                      <Td textAlign="center">{statusList(item.status)}</Td>
                      <Td textAlign="center" width="10">
                        <Button
                          size="sm"
                          fontSize="sm"
                          as="a"
                          href={`/movimentacao-frota/detalhes/${item.id}`}
                        >
                          <Icon fontSize="16px" as={RiAlignJustify} />
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </SimpleGrid>
    </Box>
  );
}

export function statusList(status: string) {
  if (status === "finished") {
    return <Badge colorScheme="green">Finalizada</Badge>;
  }

  if (status === "approved") {
    return <Badge colorScheme="blue">Aprovado</Badge>;
  }

  if (status === "canceled") {
    return <Badge colorScheme="red">Cancelada</Badge>;
  }

  if (status === "progress") {
    return <Badge>Andamento</Badge>;
  }
}
