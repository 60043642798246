import { useState } from "react";
import { api } from "../../../../service/api";
import { RequisitionDTO } from "../../../../dtos/RequisitionDTO";

interface Props {
  office_branch_id?: string;
  furnisher_id?: string;
  status?: string;
  start_date?: string;
  end_date?: string;
  request_type?: string;
}

export function useListRequisition() {
  const [isLoadingRequisition, setIsLoadingRequisition] = useState(false);
  const [dataRequisition, setDataRequisition] = useState<RequisitionDTO[]>([]);

  const getRequisitions = async ({
    office_branch_id = "",
    furnisher_id = "",
    status = "",
    start_date = "",
    end_date = "",
    request_type = "",
  }: Props) => {
    try {
      setIsLoadingRequisition(true);
      const response = await api.get(
        `company/requisition/listAll?office_branch_id=${office_branch_id}&furnisher_id=${furnisher_id}&status=${status}&start_date=${start_date}&end_date=${end_date}&request_type=${request_type}`
      );
      setDataRequisition(response.data);
    } catch (error) {
      throw error;
    } finally {
      setIsLoadingRequisition(false);
    }
  };

  return { isLoadingRequisition, getRequisitions, dataRequisition };
}
