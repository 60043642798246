import { useEffect, useState } from "react";
import { api } from "../../../../service/api";
import { ReviewsDTO } from "../../../../dtos/ReviewsDTO";

export function useListReviews(id: string) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataList, setDataList] = useState<ReviewsDTO[]>([]);

  const fetchData = async (idFleet: string) => {
    try {
      setLoading(true);
      const response = await api.get(`company/maintenance/list/${idFleet}`);
      setDataList(response.data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  return {
    dataList,
    loading,
    error,
    refetch: fetchData,
  };
}
