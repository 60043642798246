import { Box, Flex, Icon, Stack, Text } from "@chakra-ui/react";
import { RiArrowRightSLine } from "react-icons/ri";
import { LoadingFurnisher } from "../../components/loading/loading-furnisher";
import { useListPartner } from "../../hooks/useListPartner";
import { useEffect } from "react";

export interface FunisherInfo {
  furnisher_id: string;
  furnisher_name: string;
  office_branch_id: string;
}
type Props = {
  callBackSuccess: (data: FunisherInfo) => void;
};

export function SelectFurnisher({ callBackSuccess }: Props) {
  const { getPartner, data, isLoading } = useListPartner();

  useEffect(() => {
    getPartner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Text fontSize="large">
        Selecione um um fornecedor conveniado para prosseguir
      </Text>
      <Box mt={5}>
        {isLoading ? (
          <LoadingFurnisher />
        ) : (
          <Stack spacing="4">
            {data.map((item, index) => {
              return (
                <Flex
                  onClick={() => {
                    callBackSuccess({
                      furnisher_id: item.furnisher.id,
                      furnisher_name: item.furnisher.fantasy_name,
                      office_branch_id: item.office_branch.id,
                    });
                  }}
                  key={index}
                  justifyContent="space-between"
                  height={67}
                  width={745}
                  bg="green.50"
                  borderRadius={10}
                  borderWidth={1}
                  borderColor="green.400"
                  alignItems="center"
                  transition="0.5s"
                  px={10}
                  _hover={{
                    cursor: "pointer",
                    borderColor: "green.600",
                    transition: "0.5s",
                  }}
                >
                  <Box>
                    <Flex
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Flex>
                        <Text
                          fontFamily="heading"
                          fontWeight="bold"
                          fontSize="large"
                        >
                          {item.furnisher.fantasy_name}
                        </Text>

                        <Text fontFamily="heading" ml={10}>
                          {item.furnisher.cnpj}
                        </Text>
                      </Flex>
                    </Flex>
                    <Text>UG: {item.office_branch.name_ug}</Text>
                  </Box>

                  <Icon fontSize="16px" as={RiArrowRightSLine} />
                </Flex>
              );
            })}
          </Stack>
        )}
      </Box>
    </Box>
  );
}
