import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Text,
  useToast,
} from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { HeaderCard } from "../../../../components/headerCard";
import { RiArrowLeftSLine } from "react-icons/ri";
import { InputLine } from "../../../../components/form/InputLine";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../service/api";
import { InputLineMask } from "../../../../components/form/inputLineMask";
import { useState } from "react";
import { CnpjDTO } from "../../../../dtos/CnpjDTO";
import { AllLoading } from "../../../../components/allLoading";

type createFormData = {
  cnpj: string;
  company_name: string;
  cost_center_number: string;
  name_ug: string;
  responsible: string;
  zipe_code: string;
  address: string;
  number: string;
  district: string;
  complement?: string;
  city: string;
  state: string;
  phone: string;
  cell_phone: string;
};

const createFormDataSchema = yup.object().shape({
  cnpj: yup.string().required("Campo obrigatório."),
  company_name: yup.string().required("Campo obrigatório."),
  cost_center_number: yup.string().required("Campo obrigatório."),
  name_ug: yup.string().required("Campo obrigatório."),
  responsible: yup.string().required("Campo obrigatório."),
  zipe_code: yup.string().required("Campo obrigatório."),
  address: yup.string().required("Campo obrigatório."),
  number: yup.string().required("Campo obrigatório."),
  district: yup.string().required("Campo obrigatório."),
  city: yup.string().required("Campo obrigatório."),
  state: yup.string().required("Campo obrigatório."),
  phone: yup.string().required("Campo obrigatório."),
  cell_phone: yup.string().required("Campo obrigatório."),
});

export function CreateOffice() {
  const toast = useToast();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createFormDataSchema) });

  async function getCompany(cnpj: string) {
    try {
      if (cnpj.length < 18) return;
      setLoading(true);
      const response = await api.post("company/cnpj/index", { cnpj });
      const data: CnpjDTO = response.data;

      setValue("company_name", data.company_name);
      setValue("zipe_code", data.cep);
      setValue("address", data.public_place);
      setValue("district", data.neighborhood);
      setValue("city", data.city + "/" + data.state);
      setValue("state", data.state);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  const handleSend: SubmitHandler<createFormData> = async (value) => {
    try {
      await api.post("company/office/create", value);

      toast({
        position: "top-right",
        title: "Criar conta",
        description: "Cadastro realizado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      navigate("/unidade-de-gestao");
    } catch (error) {
      toast({
        position: "top-right",
        title: "Criar conta",
        description: "Não foi possível cadastrar.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <HeaderCard
        title="UNIDADES DE GESTÃO"
        titleButton="Voltar"
        icon={RiArrowLeftSLine}
        link="/unidade-de-gestao"
      />

      {loading && <AllLoading />}

      <Flex
        as="form"
        px={5}
        py={5}
        direction="column"
        onSubmit={handleSubmit(handleSend)}
      >
        <Grid gap={2} templateColumns="repeat(4, 2fr)">
          <GridItem
            colSpan={2}
            p="4"
            pb={10}
            bg="green.50"
            minH={260}
            borderRadius={8}
          >
            <Text fontWeight="bold" mb="4" color="green.800">
              IDENTIFICAÇÃO:
            </Text>

            <Grid templateColumns="repeat(5, 1fr)" gap={2}>
              <GridItem w="100%" colSpan={2}>
                <Controller
                  control={control}
                  name="cnpj"
                  render={({ field: { onChange, value } }) => (
                    <InputLineMask
                      mask="99.999.999-9999-99"
                      width="100%"
                      name="cnpj"
                      label="CNPJ"
                      placeholder="Informe o CNPJ"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        getCompany(e.target.value);
                      }}
                      error={errors.cnpj}
                    />
                  )}
                />
              </GridItem>
              <GridItem colSpan={3} w="100%">
                <InputLine
                  width="100%"
                  label="RAZÃO SOCIAL"
                  placeholder="Informe o nome comercial do produto"
                  {...register("company_name")}
                  error={errors.company_name}
                />
              </GridItem>
            </Grid>

            <Grid templateColumns="repeat(5, 1fr)" gap={2} mt={5}>
              <GridItem w="100%" colSpan={2}>
                <InputLine
                  width="100%"
                  label="N DO CENTRO DE CUSTO"
                  placeholder="Informe o centro de custo"
                  {...register("cost_center_number")}
                  error={errors.cost_center_number}
                />
              </GridItem>
              <GridItem colSpan={3} w="100%">
                <InputLine
                  width="100%"
                  label="NOME DA UG"
                  placeholder="Informe a nome da UG"
                  {...register("name_ug")}
                  error={errors.name_ug}
                />
              </GridItem>
            </Grid>

            <Flex gap="2" mt={5}>
              <InputLine
                width="100%"
                label="RESPONSÁVEL"
                placeholder="Informe o nome do responsável"
                {...register("responsible")}
                error={errors.responsible}
              />
            </Flex>

            <Flex gap="2" mt={5}>
              <InputLine width="100%" name="obs" label="OBSERVAÇÕES" />
            </Flex>
          </GridItem>

          <GridItem
            colSpan={2}
            p="4"
            pb={10}
            bg="green.50"
            minH={260}
            borderRadius={8}
          >
            <Text fontWeight="bold" mb="4" color="green.800">
              LOCALIZAÇÃO E CONTATO:
            </Text>

            <Flex gap="2">
              <InputLine
                width="300px"
                label="CEP"
                placeholder="Informe o CEP"
                {...register("zipe_code")}
                error={errors.zipe_code}
              />
              <InputLine
                width="100%"
                label="LOGRADOURO"
                placeholder="Informe o logradouro"
                {...register("address")}
                error={errors.address}
              />
            </Flex>

            <Flex gap="2" mt={5}>
              <InputLine
                width="300px"
                label="NÚMERO"
                placeholder="Informe o número"
                {...register("number")}
                error={errors.number}
              />
              <InputLine
                width="100%"
                label="BAIRRO"
                placeholder="Informe o bairro"
                {...register("district")}
                error={errors.district}
              />
            </Flex>

            <Flex gap="2" mt={5}>
              <InputLine
                width="300px"
                name="complemente"
                label="COMPLEMENTO"
                placeholder="Informe o número"
              />
              <InputLine
                width="100%"
                label="MUNICÍPIO/UF"
                placeholder="Informe o bairro"
                {...register("city")}
                error={errors.city}
              />
            </Flex>

            <Flex gap="2" mt={5}>
              <InputLineMask
                width="300px"
                label="TELEFONE"
                placeholder="Informe o número de telefone"
                mask="(99)99999-99999"
                {...register("phone")}
                error={errors.phone}
              />
              <InputLineMask
                width="100%"
                label="CELULAR"
                placeholder="Informe o número de celular"
                mask="(99)99999-99999"
                {...register("cell_phone")}
                error={errors.cell_phone}
              />
            </Flex>
          </GridItem>
        </Grid>

        <Button
          type="submit"
          bg="green.600"
          color="white"
          alignSelf="flex-end"
          height={10}
          _hover={{
            bg: "green.800",
          }}
          mt="6"
          size="lg"
          isLoading={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  );
}
