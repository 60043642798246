import { useState } from "react";
import { api } from "../../../../service/api";
import { SctructuretypeDTO } from "../../../../dtos/SctructuretypeDTO";
import { OptionsDTO } from "../../../../dtos/OptionsDTO";
import { MarkDTO } from "../../../../dtos/MarkDTO";

export function useListMarkModel() {
  const [isLoadingMark, setIsLoadingMark] = useState(false);
  const [isLoadingModel, setIsLoadingModel] = useState(false);
  const [dataMark, setDataMark] = useState<MarkDTO[]>([]);
  const [dataModel, setDataModel] = useState<SctructuretypeDTO[]>([]);
  const [dataOptionsMark, setDataOptionsMark] = useState<OptionsDTO[]>([]);
  const [dataOptionsModel, setDataOptionsTypeModel] = useState<OptionsDTO[]>(
    []
  );

  const getLiMark = async (group: string) => {
    try {
      setIsLoadingMark(true);
      const response = await api.get("company/mark/index/" + group);
      const markData = response.data;
      setDataMark(markData);

      const newDataMark: OptionsDTO[] = [];

      for (let index = 0; index < markData.length; index++) {
        const element = markData[index];

        newDataMark.push({
          label: element.name,
          value: element.id,
        });
      }

      setDataOptionsMark(newDataMark);
    } catch (error) {
      throw error;
    } finally {
      setIsLoadingMark(false);
    }
  };

  const getLiMarkModel = async (group: string, markName: string) => {
    try {
      setIsLoadingModel(true);
      const response = await api.get(
        `company/vehicle/index/${group}/${markName}`
      );
      const modelMarkData = response.data;
      setDataModel(modelMarkData);

      const newDataModel: OptionsDTO[] = [];

      for (let index = 0; index < modelMarkData.length; index++) {
        const element = modelMarkData[index];

        newDataModel.push({
          label: element.name,
          value: element.id,
        });
      }

      setDataOptionsTypeModel(newDataModel);
    } catch (error) {
      throw error;
    } finally {
      setIsLoadingModel(false);
    }
  };

  return {
    isLoadingMark,
    isLoadingModel,
    getLiMark,
    getLiMarkModel,
    dataMark,
    dataModel,
    dataOptionsMark,
    dataOptionsModel,
  };
}
