import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CurrencyInput from "react-currency-input";
import { FieldError } from "react-hook-form";

type Props = {
  name: string;
  label?: string;
  error?: FieldError;
  value?: string;
  width?: string;
  precision?: string;
  onChange?: (value: any) => void;
};

export function InputPriceAll({
  value,
  onChange,
  label,
  name,
  error,
  width = "100%",
  precision = "2",
}: Props) {
  const [inFocus, setInFocus] = useState(false);

  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <FormLabel color="gray.800" fontWeight="bold" htmlFor={name}>
          {label}
        </FormLabel>
      )}

      <Box mt="4.5px">
        <CurrencyInput
          decimalSeparator=","
          thousandSeparator="."
          precision={precision}
          style={{
            marginTop: 0.1,
            height: "2.5rem",
            backgroundColor: "transparent",
            borderRadius: "0.375rem",
            borderWidth: 1,
            borderColor:
              inFocus && !error ? "#347885" : !!error ? "#E53E3E" : "#E2E8F0",
            fontSize: "1rem",
            fontFamily: "Titillium Web",
            width,
            outline: "2px solid transparent",
            outlineOffset: "2px",
            textAlign: "center",
            boxShadow: `0px 0px 0px 0px ${
              inFocus && !error
                ? "#69B0B9"
                : !!error
                ? "#E53E3E"
                : "transparent"
            }`,
          }}
          onFocus={(e: any) => {
            setInFocus(true);
          }}
          onBlur={() => {
            setInFocus(false);
          }}
          value={value}
          onChangeEvent={onChange}
        />
      </Box>
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
}
