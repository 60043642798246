import { Box, Flex, Grid, Text } from "@chakra-ui/react";

interface MultiStepProps {
  size: number;
  currentStep: number;
}

export function MultiStep({ size, currentStep }: MultiStepProps) {
  return (
    <Flex direction="column">
      <Text fontSize="xs" color="gray.600">
        passo {currentStep} de {size}
      </Text>

      <Grid templateColumns={`repeat(${size}, 1fr)`} gap="2" mt={1}>
        {Array.from({ length: size }, (_, i) => i + 1).map((step) => (
          <Box
            key={step}
            height="4px"
            borderRadius={1}
            bg={currentStep >= step ? "green.400" : "gray.400"}
          />
        ))}
      </Grid>
    </Flex>
  );
}
