import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { formatCurrency } from "../../utils/utils";

type Props = {
  isTotal?: boolean;
  title: string;
  subTitle?: string;
  price?: number;
  isWdAll?: boolean;
  isLoading?: boolean;
  icon: any;
};

export function CardInfoData({
  title,
  subTitle = "",
  price = 0,
  icon,
  isTotal = false,
  isWdAll = false,
  isLoading = false,
}: Props) {
  return (
    <Box
      width={isWdAll ? "100%" : "352px"}
      height="100px"
      borderRadius="6px"
      bg={isTotal ? "green.600" : "gray.400"}
      px={5}
      py={4}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Text color={isTotal ? "white" : "gray.600"} fontSize="md">
          {title}
        </Text>
        {icon}
      </Flex>

      {subTitle && !isLoading && (
        <Text
          fontWeight="bold"
          color={isTotal ? "white" : "gray.800"}
          mt={2}
          fontSize="18px"
        >
          {subTitle}
        </Text>
      )}

      {isLoading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="50px"
        >
          <Spinner
            color="green.600"
            emptyColor="white"
            thickness="4px"
            size="md"
            h="25px"
            w="25px"
          />
        </Box>
      )}

      {price > 0 && (
        <Text
          fontWeight="bold"
          color={isTotal ? "white" : "gray.800"}
          mt={2}
          fontSize="26px"
        >
          R$ {formatCurrency(price)}
        </Text>
      )}
    </Box>
  );
}
