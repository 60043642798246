import { useState } from "react";
import { api } from "./api";
import { SignatureDTO } from "../dtos/SignatureDTO";

export function useSignature() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<SignatureDTO>({} as SignatureDTO);

  const getSignature = async () => {
    try {
      setIsLoading(true);
      const response = await api.get("company/signature/show");
      setData(response.data);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, getSignature, data };
}
