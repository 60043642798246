import { useState } from "react";
import { api } from "./api";
import { OptionsDTO } from "../dtos/OptionsDTO";
import { CarDTO } from "../dtos/CarDTO";

export function useCarService() {
  const [isLoadingCar, setIsLoadingCar] = useState(false);
  const [dataCar, setDataCar] = useState<CarDTO[]>([]);
  const [dataOptionsCar, setDataOptionsCar] = useState<OptionsDTO[]>([]);

  const getCarService = async (id: string) => {
    try {
      setIsLoadingCar(true);
      console.log(id);
      const response = await api.get("company/car/list/" + id);

      const officeData = response.data;
      setDataCar(officeData);

      const newDataCar: OptionsDTO[] = [];

      for (let index = 0; index < officeData.length; index++) {
        const element = officeData[index];

        newDataCar.push({
          label:
            "COD: " +
            element.code_car_fleet +
            " - " +
            "PLACA: " +
            element.plaque +
            " - " +
            element.mark +
            " - " +
            element.structure_type.type,
          value: element.id,
        });
      }

      setDataOptionsCar(newDataCar);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingCar(false);
    }
  };

  return { isLoadingCar, dataOptionsCar, getCarService, dataCar };
}
