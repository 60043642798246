import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  SimpleGrid,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { HeaderCard } from "../../../../components/headerCard";
import { RiAddLine, RiCheckFill, RiCloseFill } from "react-icons/ri";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useListRequisition } from "../hooks/useListRequisition";
import { FilterRequistion } from "./filter";
import {
  formatCurrency,
  formatedDateTimer,
  lmWord,
  typeOperagions,
} from "../../../../utils/utils";
import { ModalFinalizeRequest } from "./modal/modal-finalize-request";
import { RequisitionDTO } from "../../../../dtos/RequisitionDTO";
import { useCancelRequisition } from "../hooks/useCancelRequisition";

export function ListRequisition() {
  const [searchParams] = useSearchParams();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const cancelRef = useRef<any>(null);

  const { getRequisitions, dataRequisition, isLoadingRequisition } =
    useListRequisition();

  const { cancelRequisition, loadingCancel } = useCancelRequisition();

  const [requistionInfo, setRequistionInfo] = useState({} as RequisitionDTO);

  const [requistionFinalizeModal, setRequistionFinalizeModal] = useState(false);

  const office_id = searchParams.get("office_id");
  const furnisher_id = searchParams.get("furnisher_id");
  const start_date = searchParams.get("start_date");
  const end_date = searchParams.get("end_date");
  const status_filter = searchParams.get("status");

  async function cancelRequisitionData(id: string) {
    try {
      await cancelRequisition(id);

      toast({
        position: "top-right",
        title: "Requisição",
        description: "Requisição cancelada com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: "top-right",
        title: "Requisição",
        description: "Não foi possível cancelar requisição.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      onClose();
      setRequistionInfo({} as RequisitionDTO);
      getRequisitions({});
    }
  }

  useEffect(() => {
    getRequisitions({
      office_branch_id: office_id ?? "",
      furnisher_id: furnisher_id ?? "",
      status: status_filter ?? "",
      start_date: start_date ?? "",
      end_date: end_date ?? "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [office_id, furnisher_id, start_date, end_date, status_filter]);

  return (
    <Box>
      <ModalFinalizeRequest
        is_open={requistionFinalizeModal}
        data={requistionInfo}
        onClose={() => {
          setRequistionFinalizeModal(false);
          setRequistionInfo({} as RequisitionDTO);
        }}
        onCloseSend={() => {
          setRequistionFinalizeModal(false);
          setRequistionInfo({} as RequisitionDTO);
          getRequisitions({});
        }}
      />

      <HeaderCard isFilter>
        <Box height="120px" flexDirection="column" width="100%">
          <Flex
            mt={5}
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <FilterRequistion />

            <Button
              mt={6}
              as="a"
              size="sm"
              fontSize="sm"
              colorScheme="primary"
              leftIcon={<Icon as={RiAddLine} />}
              href="/requisicoes/nova"
            >
              Nova requisição
            </Button>
          </Flex>
        </Box>
      </HeaderCard>

      {isLoadingRequisition && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={500}
        >
          <Spinner
            color="green.600"
            emptyColor="white"
            thickness="4px"
            size="xl"
            h={100}
            w={100}
          />
        </Box>
      )}

      <SimpleGrid
        flex="1"
        gap="4"
        minChildWidth="320px"
        alignItems="flex-start"
        px={1}
        py={1}
      >
        {dataRequisition.length > 0 && !isLoadingRequisition && (
          <Box p="4" borderRadius={8} height={500} overflow="scroll">
            <TableContainer>
              <Table variant="striped" colorScheme="gray" size="sm">
                <Thead>
                  <Tr bg="green.600" height="10">
                    <Th color="white" textAlign="center">
                      STATUS
                    </Th>
                    <Th color="white" textAlign="center">
                      DATA
                    </Th>
                    <Th color="white" textAlign="center">
                      N REQ
                    </Th>
                    <Th color="white" textAlign="center">
                      CÓDIGO
                    </Th>
                    <Th color="white" textAlign="center">
                      PLACA
                    </Th>
                    <Th color="white" textAlign="center">
                      MARCA
                    </Th>
                    <Th color="white" textAlign="center">
                      MODELO
                    </Th>
                    <Th color="white" textAlign="center">
                      PROCESSO
                    </Th>
                    <Th color="white" textAlign="center">
                      PRODUTO
                    </Th>
                    <Th color="white" textAlign="center">
                      QTD
                    </Th>
                    <Th color="white" textAlign="center">
                      VALOR
                    </Th>
                    <Th color="white" textAlign="center">
                      TOTAL
                    </Th>
                    <Th color="white" textAlign="center">
                      AÇÕES
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {dataRequisition.map((item, index) => (
                    <Tr key={index}>
                      <Td textAlign="center">
                        {statusRquisition(item.status)}
                      </Td>
                      <Td textAlign="center">
                        {formatedDateTimer(item.end_date, true)}
                      </Td>
                      <Td textAlign="center">{item.code_requisition}</Td>
                      <Td textAlign="center">{item.fleet_code}</Td>
                      <Td textAlign="center">{item.plaque}</Td>
                      <Td textAlign="center">{lmWord(item.mark, 10)}</Td>
                      <Td textAlign="center">{lmWord(item.model, 10)}</Td>
                      <Td textAlign="center">
                        {typeOperagions(item.operation)}
                      </Td>
                      <Td textAlign="center">{item.product_name}</Td>
                      <Td textAlign="center" color="blue">
                        {item.quantity_solicit > 0
                          ? formatCurrency(item.quantity_solicit)
                          : formatCurrency(item.quantity_solicit)}
                      </Td>
                      <Td textAlign="center" color="green">
                        {formatCurrency(item.unitary_price)}
                      </Td>
                      <Td textAlign="center" color="green">
                        {formatCurrency(item.total_price)}
                      </Td>
                      <Td textAlign="center" width="10">
                        <Button
                          size="sm"
                          ml={1}
                          fontSize="sm"
                          onClick={() => {
                            setRequistionInfo(item);
                            setRequistionFinalizeModal(true);
                          }}
                          colorScheme="teal"
                          variant="outline"
                          title="Aprovar requisição"
                          isDisabled={item.status !== "progress"}
                        >
                          <Icon fontSize="16px" as={RiCheckFill} />
                        </Button>

                        <Button
                          size="sm"
                          ml={1}
                          fontSize="sm"
                          onClick={() => {
                            setRequistionInfo(item);
                            onOpen();
                          }}
                          colorScheme="red"
                          variant="outline"
                          title="Cancelar requisição"
                          isDisabled={item.status !== "progress"}
                        >
                          <Icon fontSize="16px" as={RiCloseFill} />
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </SimpleGrid>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Remover Motorista
            </AlertDialogHeader>

            <AlertDialogBody>
              {!loadingCancel && (
                <Text>
                  Deseja cancelar a requisição:{" "}
                  <strong>
                    PLACA: {requistionInfo?.plaque} - {requistionInfo?.name}
                  </strong>
                </Text>
              )}

              {loadingCancel && (
                <Flex width="100%" alignItems="center" justifyContent="center">
                  <Spinner
                    color="green.600"
                    emptyColor="white"
                    thickness="4px"
                    size="xl"
                    h={50}
                    w={50}
                  />
                </Flex>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              {!loadingCancel && (
                <>
                  <Button
                    colorScheme="red"
                    onClick={() => {
                      cancelRequisitionData(requistionInfo?.id);
                    }}
                  >
                    Sim
                  </Button>
                  <Button ml={3} onClick={onClose}>
                    Nāo
                  </Button>
                </>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}

function statusRquisition(status: string) {
  if (status === "finished") {
    return <Badge colorScheme="green">Finalizada</Badge>;
  }

  if (status === "canceled") {
    return <Badge colorScheme="red">Cancelada</Badge>;
  }

  if (status === "progress") {
    return <Badge>Andamento</Badge>;
  }
}
