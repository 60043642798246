import { useState } from "react";
import { api } from "../../../../service/api";

export function useFinishTravel() {
  const [loadingFinish, setloadingFinish] = useState(false);

  const finishTravel = async (id: string) => {
    try {
      setloadingFinish(true);
      await api.put("company/trip/finished/" + id);
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      setloadingFinish(false);
    }
  };

  return { loadingFinish, finishTravel };
}
