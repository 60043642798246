import {
  Badge,
  Box,
  Flex,
  SimpleGrid,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { FilterRequistion } from "./filter";
import { useListRequisition } from "../../launch/requisition/hooks/useListRequisition";
import { HeaderCard } from "../../../components/headerCard";
import {
  formatCurrency,
  formatedDateTimer,
  lmWord,
  typeOperagions,
} from "../../../utils/utils";

export function RequestsIssued() {
  const [searchParams] = useSearchParams();
  let { request_type } = useParams();

  const { getRequisitions, dataRequisition, isLoadingRequisition } =
    useListRequisition();

  const office_id = searchParams.get("office_id");
  const furnisher_id = searchParams.get("furnisher_id");
  const start_date = searchParams.get("start_date");
  const end_date = searchParams.get("end_date");
  const status_filter = searchParams.get("status");

  useEffect(() => {
    getRequisitions({
      office_branch_id: office_id ?? "",
      furnisher_id: furnisher_id ?? "",
      status: status_filter ?? "",
      start_date: start_date ?? "",
      end_date: end_date ?? "",
      request_type: request_type === "entrada" ? "entry" : "exit",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [office_id, furnisher_id, start_date, end_date, status_filter]);

  return (
    <Box>
      <HeaderCard isFilter>
        <Box height="120px" flexDirection="column" width="100%">
          <Flex
            mt={5}
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <FilterRequistion request_type={request_type} />
          </Flex>
        </Box>
      </HeaderCard>

      {isLoadingRequisition && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={500}
        >
          <Spinner
            color="green.600"
            emptyColor="white"
            thickness="4px"
            size="xl"
            h={100}
            w={100}
          />
        </Box>
      )}

      <SimpleGrid
        flex="1"
        gap="4"
        minChildWidth="320px"
        alignItems="flex-start"
        px={1}
        py={1}
      >
        {dataRequisition.length > 0 && !isLoadingRequisition && (
          <Box p="4" borderRadius={8} height={500} overflow="scroll">
            <TableContainer>
              <Table variant="striped" colorScheme="gray" size="sm">
                <Thead>
                  <Tr bg="green.600" height="10">
                    <Th color="white" textAlign="center">
                      STATUS
                    </Th>
                    <Th color="white" textAlign="center">
                      DATA
                    </Th>
                    <Th color="white" textAlign="center">
                      N REQ
                    </Th>
                    <Th color="white" textAlign="center">
                      CÓDIGO
                    </Th>
                    <Th color="white" textAlign="center">
                      PLACA
                    </Th>
                    <Th color="white" textAlign="center">
                      MARCA
                    </Th>
                    <Th color="white" textAlign="center">
                      MODELO
                    </Th>
                    <Th color="white" textAlign="center">
                      PROCESSO
                    </Th>
                    <Th color="white" textAlign="center">
                      PRODUTO
                    </Th>
                    <Th color="white" textAlign="center">
                      QTD
                    </Th>
                    <Th color="white" textAlign="center">
                      V. UNITÁRIO
                    </Th>
                    <Th color="white" textAlign="center">
                      TOTAL
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {dataRequisition.map((item, index) => (
                    <Tr key={index}>
                      <Td textAlign="center">
                        {statusRquisition(item.status)}
                      </Td>
                      <Td textAlign="center">
                        {formatedDateTimer(item.end_date, true)}
                      </Td>
                      <Td textAlign="center">{item.code_requisition}</Td>
                      <Td textAlign="center">{item.fleet_code}</Td>
                      <Td textAlign="center">{item.plaque}</Td>
                      <Td textAlign="center">{lmWord(item.mark, 10)}</Td>
                      <Td textAlign="center">{lmWord(item.model, 10)}</Td>
                      <Td textAlign="center">
                        {typeOperagions(item.operation)}
                      </Td>
                      <Td textAlign="center">{item.product_name}</Td>
                      <Td textAlign="center" color="blue">
                        {item.quantity_solicit > 0
                          ? formatCurrency(item.quantity_solicit)
                          : formatCurrency(item.quantity_applied)}
                      </Td>
                      <Td textAlign="center" color="green">
                        R$ {formatCurrency(item.unitary_price)}
                      </Td>
                      <Td textAlign="center" color="green">
                        R$ {formatCurrency(item.total_price)}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </SimpleGrid>
    </Box>
  );
}

function statusRquisition(status: string) {
  if (status === "finished") {
    return <Badge colorScheme="green">Finalizada</Badge>;
  }

  if (status === "canceled") {
    return <Badge colorScheme="red">Cancelada</Badge>;
  }

  if (status === "progress") {
    return <Badge>Andamento</Badge>;
  }
}
