import { Box, Flex, Image, Text } from "@chakra-ui/react";
import {
  BOMB_ICON,
  LUBRICANT_ICON,
  PARTS_ICON,
  SERVICE_ICON,
} from "../../../../../assets";

export interface CateogryInfo {
  category_name: string;
}
type Props = {
  callBackSuccess: (data: CateogryInfo) => void;
};

export function SelectCategory({ callBackSuccess }: Props) {
  return (
    <Box>
      <Text fontSize="large">Selecione uma das opções</Text>
      <Box mt={5} width={745}>
        <Flex gap={2}>
          {BoxContent("Combustíveis", "#FE0606", BOMB_ICON, function () {
            callBackSuccess({
              category_name: "Combustíveis",
            });
          })}
          {BoxContent("Lubrificantes", "#E7BD23", LUBRICANT_ICON, function () {
            callBackSuccess({
              category_name: "Lubrificantes",
            });
          })}
          {BoxContent("Peças", "#005DFF", PARTS_ICON, function () {
            callBackSuccess({
              category_name: "Peças",
            });
          })}
          {BoxContent("Serviços", "#69B0B9", SERVICE_ICON, function () {
            callBackSuccess({
              category_name: "Serviços",
            });
          })}
        </Flex>
      </Box>
    </Box>
  );
}

function BoxContent(title: string, bg: any, icon: string, onClick: () => void) {
  return (
    <Box
      onClick={onClick}
      display="flex"
      width={180}
      height={180}
      bg={bg}
      borderRadius={10}
      transition="0.5s"
      _hover={{
        cursor: "pointer",
        transition: "0.5s",
      }}
      alignItems="center"
      alignContent="center"
      justifyContent="center"
      flexDir="column"
    >
      <Image width={100} height={100} src={icon} />
      <Text color="white" fontFamily="heading" fontWeight="bold" mt={2}>
        {title}
      </Text>
    </Box>
  );
}
