import { Badge } from "@chakra-ui/react";
import { statusContract } from "../../../../../utils/utils";

type Props = {
  status: string;
};

export function ItemStatus({ status }: Props) {
  if (status === "registered") {
    return <Badge>{statusContract(status)}</Badge>;
  }

  if (status === "confirmed") {
    return <Badge colorScheme="green">{statusContract(status)}</Badge>;
  }

  if (status === "canceled") {
    return <Badge colorScheme="red">{statusContract(status)}</Badge>;
  }
}
