import {
  Box,
  Button,
  Icon,
  SimpleGrid,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { HeaderCard } from "../../../../components/headerCard";
import { useListMaintenance } from "../hooks/useListMaintenance";
import { RiSettings4Line } from "react-icons/ri";

export function MaintenanceList() {
  const { dataList, loading } = useListMaintenance();

  return (
    <Box>
      <HeaderCard title="REVISÕES PROGRAMADAS" />

      {loading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={500}
        >
          <Spinner
            color="green.600"
            emptyColor="white"
            thickness="4px"
            size="xl"
            h={100}
            w={100}
          />
        </Box>
      )}

      <SimpleGrid
        flex="1"
        gap="4"
        minChildWidth="320px"
        alignItems="flex-start"
        px={1}
        py={1}
      >
        {dataList.length > 0 && !loading && (
          <Box p="4" borderRadius={8} height={700} overflow="scroll">
            <TableContainer>
              <Table variant="striped" colorScheme="gray" size="sm">
                <Thead>
                  <Tr bg="green.600" height="10">
                    <Th color="white" textAlign="center">
                      CÓDIGO
                    </Th>
                    <Th color="white" textAlign="center">
                      PLACA
                    </Th>
                    <Th color="white" textAlign="center">
                      TIPO DE FROTA
                    </Th>
                    <Th color="white" textAlign="center">
                      MARCA
                    </Th>
                    <Th color="white" textAlign="center">
                      MODELO
                    </Th>
                    <Th color="white" textAlign="center">
                      ANO/MODELO
                    </Th>
                    <Th color="white" textAlign="center">
                      PROGRAMADAS
                    </Th>
                    <Th color="white" textAlign="center">
                      PENDENTES
                    </Th>
                    <Th color="white" textAlign="center">
                      AÇÕES
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {dataList.map((item, index) => (
                    <Tr key={index}>
                      <Td textAlign="center">{item.code_car_fleet}</Td>
                      <Td textAlign="center">{item.plaque}</Td>
                      <Td textAlign="center">{item.structure_type.type}</Td>
                      <Td textAlign="center">{item.mark}</Td>
                      <Td textAlign="center">{item.model}</Td>
                      <Td textAlign="center">{item.year_model}</Td>
                      <Td textAlign="center">
                        {item.maintenance_review_progress.length}
                      </Td>
                      <Td textAlign="center">
                        {item.products_ten_waiting.length}
                      </Td>

                      <Td textAlign="center" width="10">
                        <Button
                          size="sm"
                          fontSize="sm"
                          as="a"
                          href={`/revisoes-programadas/car/${item.id}`}
                        >
                          <Icon fontSize="16px" as={RiSettings4Line} />
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </SimpleGrid>
    </Box>
  );
}
