import { useState } from "react";
import { api } from "../../../../service/api";
import { OptionsDTO } from "../../../../dtos/OptionsDTO";

export function useListMarkModelCar() {
  const [isLoadingMarkModel, setIsLoadingCarMarkModel] = useState(false);
  const [dataOptionsMarkModel, setDataOptionsMarkModel] = useState<
    OptionsDTO[]
  >([]);

  const getMarkModelCar = async (markName: string) => {
    try {
      setIsLoadingCarMarkModel(true);
      const response = await api.get("/company/vehicle/index/" + markName);
      const markCarModelData = response.data;

      const newDataMarkCar: OptionsDTO[] = [];

      for (let index = 0; index < markCarModelData.length; index++) {
        const element = markCarModelData[index];
        newDataMarkCar.push({
          label: element.name,
          value: element.id,
        });
      }

      setDataOptionsMarkModel(newDataMarkCar);
    } catch (error) {
      setDataOptionsMarkModel([]);
    } finally {
      setIsLoadingCarMarkModel(false);
    }
  };

  return {
    isLoadingMarkModel,
    getMarkModelCar,
    dataOptionsMarkModel,
  };
}
