import { Box, Flex, Image, Link, Text } from "@chakra-ui/react";
import LogoEtank from "../../../../assets/Logo-e-tank-grande.png";

export function RegisterAccountSuccess() {
  return (
    <Flex
      w="100vw"
      h="100vh"
      justifyContent="center"
      alignItems="center"
      px={40}
      py={20}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width={400}
      >
        <Image src={LogoEtank} width={200} alt="Etank" />

        <Text fontWeight="bold" mt={5} fontSize="36px" color="gray.800">
          Conta criada com sucesso
        </Text>

        <Text color="gray.600" textAlign="center">
          Sua conta foi criada com sucesso, agora falta pouco para você
          controlar sua fronta no E-tank. Sua conta vai passar por uma avaliação
          e você vai receber um email com a confirmação da ativação da sua conta
        </Text>

        <Flex mt={5} alignItems="center" justifyContent="center">
          <Link href="/login" color="green.600" fontWeight="bold">
            Voltar
          </Link>
        </Flex>
      </Box>
    </Flex>
  );
}
