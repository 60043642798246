import {
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  SimpleGrid,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { HeaderCard } from "../../../../components/headerCard";
import { CardInfoData } from "../../../../components/cardInfoData";
import {
  RiCarFill,
  RiRepeatOneLine,
  RiSquareLine,
  RiToolsFill,
} from "react-icons/ri";
import { formatedDateTimer, lmWord } from "../../../../utils/utils";
import { useShowCar } from "../../../registrations/car/hooks/useShowCar";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useListReviews } from "../hooks/useListReviews";
import { ModalFinalizeReview } from "./modal/modal-finalize-review";
import { ModalCreateReview } from "./modal/modal-create-review";
import { ReviewsDTO } from "../../../../dtos/ReviewsDTO";

export function ReviewDetailMaintenance() {
  let { id } = useParams();

  const [isOpenModalCreate, setIsOpenModalCreate] = useState(false);
  const [isOpenModalFinalize, setIsOpenModalFinalize] = useState(false);
  const [dataReview, setDataReview] = useState<ReviewsDTO>({} as ReviewsDTO);

  const { findCar, dataCar, isLoadingCar } = useShowCar();
  const { dataList, loading, refetch } = useListReviews(id as string);

  useEffect(() => {
    if (id) {
      findCar(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Box>
      <HeaderCard
        title="REVISÕES PROGRAMADAS"
        titleButton="Nova revisão"
        isModal
        onPressModal={() => {
          setIsOpenModalCreate(true);
        }}
      />

      <ModalFinalizeReview
        data={dataReview}
        is_open={isOpenModalFinalize}
        onClose={() => {
          setIsOpenModalFinalize(false);
        }}
        onCloseSend={() => {
          setIsOpenModalFinalize(false);
          refetch(id as string);
        }}
      />

      <ModalCreateReview
        carData={dataCar}
        is_open={isOpenModalCreate}
        onClose={() => {
          setIsOpenModalCreate(false);
        }}
        onCloseSend={() => {
          setIsOpenModalCreate(false);
          refetch(id as string);
        }}
      />

      {loading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={500}
        >
          <Spinner
            color="green.600"
            emptyColor="white"
            thickness="4px"
            size="xl"
            h={100}
            w={100}
          />
        </Box>
      )}

      {!loading && !isLoadingCar && dataCar.id && (
        <Flex mt={5} px={5} alignItems="center" gap={2}>
          <CardInfoData
            icon={<Icon fontSize="25px" as={RiCarFill} />}
            title="Modelo"
            subTitle={lmWord(dataCar.model ?? "", 35)}
          />

          <CardInfoData
            icon={<Icon fontSize="25px" as={RiRepeatOneLine} />}
            title="Código"
            subTitle={dataCar.code_car_fleet}
          />

          <CardInfoData
            icon={<Icon fontSize="25px" as={RiSquareLine} />}
            title="Placa"
            subTitle={dataCar.plaque}
          />
        </Flex>
      )}

      <SimpleGrid
        flex="1"
        gap="4"
        minChildWidth="320px"
        alignItems="flex-start"
        px={1}
        py={1}
      >
        {!loading && dataList.length > 0 && (
          <Box p="4" borderRadius={8} height={500}>
            <TableContainer>
              <Table variant="striped" colorScheme="gray" size="sm">
                <Thead>
                  <Tr bg="green.600" height="10">
                    <Th color="white" textAlign="center">
                      TIPO DE REVISÃO
                    </Th>
                    <Th color="white" textAlign="center">
                      DATA PREVISTA
                    </Th>
                    <Th color="white" textAlign="center">
                      ODÔMETRO
                    </Th>
                    <Th color="white" textAlign="center">
                      NOME DA OFICINA
                    </Th>
                    <Th color="white" textAlign="center">
                      MECÂNICO
                    </Th>
                    <Th color="white" textAlign="center">
                      OBSERVAÇÕES
                    </Th>
                    <Th color="white" textAlign="center">
                      STATUS
                    </Th>

                    <Th color="white" textAlign="center">
                      AÇÕES
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {dataList.map((item, index) => (
                    <Tr key={index}>
                      <Td textAlign="center">{item.type_review}</Td>
                      <Td textAlign="center">
                        {formatedDateTimer(item.expected_date, true)}
                      </Td>
                      <Td textAlign="center">{item.review_odometer}</Td>
                      <Td textAlign="center">{item.company_name}</Td>
                      <Td textAlign="center">{item.mechanic_name}</Td>
                      <Td textAlign="center">{item.observation}</Td>

                      <Td textAlign="center">{statusReview(item.status)}</Td>

                      <Td textAlign="center" width="10">
                        <Button
                          size="sm"
                          ml={1}
                          fontSize="sm"
                          onClick={() => {
                            setDataReview(item);
                            setIsOpenModalFinalize(true);
                          }}
                        >
                          <Icon fontSize="16px" as={RiToolsFill} />
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </SimpleGrid>
    </Box>
  );
}

function statusReview(status: string) {
  if (status === "finished") {
    return <Badge colorScheme="green">Finalizada</Badge>;
  }

  if (status === "canceled") {
    return <Badge colorScheme="red">Cancelada</Badge>;
  }

  if (status === "waiting") {
    return <Badge colorScheme="yellow">Pendente</Badge>;
  }

  if (status === "progress") {
    return <Badge>Andamento</Badge>;
  }
}
