import { Box, Button, Stack, Text } from "@chakra-ui/react";
import { RiBarcodeBoxLine, RiChat1Line } from "react-icons/ri";
type Props = {
  linkBillet: string;
  linkContact: string;
};

export function NotificationPay({ linkBillet, linkContact }: Props) {
  return (
    <Box
      width="100%"
      height={100}
      bg="white"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Text fontWeight="bold" fontSize="lg">
        Sua assinatura ainda não esta ativa. Verifique se o boleto foi pago ou
        entre em contato com a equipe e-tank
      </Text>
      <Box mt="1">
        <Stack direction="row" spacing={4}>
          <Button
            leftIcon={<RiBarcodeBoxLine />}
            colorScheme="teal"
            variant="solid"
            as="a"
            href={linkBillet}
          >
            Ver boleto
          </Button>
          <Button
            rightIcon={<RiChat1Line />}
            colorScheme="teal"
            variant="outline"
            as="a"
            href={linkContact}
          >
            Entrar em contato
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}
