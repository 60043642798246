import { useEffect, useRef } from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Stack,
  StackDivider,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  clientTypePayment,
  creditTypePayment,
  formatCurrency,
  formatedMonthDay,
} from "../../../../utils/utils";
import { useShowPartner } from "../hooks/useShowPartner";
import { useParams } from "react-router-dom";
import { HeaderCard } from "../../../../components/headerCard";
import { RiArrowLeftSLine } from "react-icons/ri";
import { AllLoading } from "../../../../components/allLoading";
import { ItemStatus } from "../components/status";
import { useUpdatePartner } from "../hooks/useUpdatePartner";

export function PartnerContrat() {
  let { id } = useParams();

  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { showPartner, data, isLoading } = useShowPartner();
  const { updatePartner, isLoadingUpdate } = useUpdatePartner();

  const cancelRef = useRef<any>(null);

  useEffect(() => {
    showPartner(id as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function calculationNewPrice(price: number, discount: number) {
    let calcDiscount = (discount / 100) * -1;
    let newPrice = price * (1 + calcDiscount);
    return newPrice;
  }

  async function sendUdate(status: string) {
    try {
      await updatePartner(id as string, status);

      toast({
        position: "top-right",
        title: "Produto",
        description:
          status === "confirmed"
            ? "Contrato aprovado com sucesso."
            : "Contrato cancelado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: "top-right",
        title: "Produto",
        description: "Não foi possível finalizar sua solicitação.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      onClose();
      showPartner(id as string);
    }
  }

  function cardInfo(title: string, descrition: string) {
    return (
      <Box>
        <Heading size="xs" textTransform="uppercase" color="gray.800">
          {title}
        </Heading>
        <Text pt="1" textAlign="center" fontSize="sm" color="gray.600">
          {descrition}
        </Text>
      </Box>
    );
  }

  return (
    <Box>
      <HeaderCard
        title="CONTRATO"
        titleButton="Voltar"
        icon={RiArrowLeftSLine}
        link="/parceiros"
      />

      {(isLoading || isLoadingUpdate) && <AllLoading />}
      <Flex justifyContent="center">
        <Box>
          {data.id && !isLoading && (
            <Flex px={10} py={5} width="100%" flexDirection="column">
              <Flex flex={1} alignItems="center" justifyContent="space-between">
                <Box minHeight={300} borderRadius={8} width="879px">
                  <Card>
                    <CardHeader>
                      <Heading size="md">INFORMAÇÕES DO FORNECEDOR:</Heading>
                    </CardHeader>

                    <Box px={5}>
                      <Box display="flex" alignItems="center">
                        <Heading
                          size="xs"
                          textTransform="uppercase"
                          color="gray.800"
                        >
                          Status:
                        </Heading>
                        <Text
                          ml={1}
                          textAlign="center"
                          fontSize="sm"
                          color="gray.600"
                        >
                          {ItemStatus({ status: data.status })}
                        </Text>
                      </Box>
                    </Box>

                    <CardBody>
                      <Stack divider={<StackDivider />} spacing="4">
                        <Box display="flex" gap={4}>
                          {cardInfo(
                            "UG DE GESTÃO:",
                            data.office_branch.name_ug
                          )}
                          {cardInfo("FORNECEDOR:", data.furnisher.fantasy_name)}
                          {cardInfo("N DO CONTRATO:", data.contract_number)}
                          {cardInfo(
                            "VENCIMENTO DO CONTRATO:",
                            formatedMonthDay(data.due_date ?? "")
                          )}
                          {cardInfo(
                            "DATA ASSINATURA:",
                            formatedMonthDay(data.signature_data)
                          )}
                        </Box>

                        <Box display="flex" gap={4}>
                          {cardInfo(
                            "% DE DESCONTO:",
                            formatCurrency(data.discount).toString()
                          )}
                          {cardInfo(
                            "INÍCIO DO DESCONTO:",
                            formatedMonthDay(data.discount_start)
                          )}
                          {cardInfo(
                            "FIM DO DESCONTO:",
                            formatedMonthDay(data.discount_end)
                          )}
                          {cardInfo(
                            "FORMA DE PAGAMENTO:",
                            clientTypePayment(data.form_of_payment)
                          )}
                        </Box>
                        <Box display="flex" gap={4}>
                          {cardInfo("OBSERVAÇÕES:", data.description ?? "")}
                        </Box>
                      </Stack>
                    </CardBody>
                  </Card>

                  {data.credit_balance && (
                    <Card mt={5}>
                      <CardHeader>
                        <Heading size="md">INFORMAÇÕES DO CRÉDITO:</Heading>
                      </CardHeader>

                      <CardBody>
                        <Stack divider={<StackDivider />} spacing="4">
                          <Box display="flex" gap={4}>
                            {cardInfo(
                              "TOTAL ENTRADA:",
                              formatCurrency(
                                Number(data.credit_balance)
                              ).toString()
                            )}
                            {cardInfo(
                              "TOTAL SAÍDA:",
                              formatCurrency(
                                Number(data.credit_amount_used)
                              ).toString()
                            )}
                            {cardInfo(
                              "TOTAL CRÉDITO:",
                              formatCurrency(
                                Number(data.credit_total_balance)
                              ).toString()
                            )}
                          </Box>
                          <Box>
                            <CardBody padding={0}>
                              <TableContainer>
                                <Table
                                  variant="striped"
                                  colorScheme="gray"
                                  size="sm"
                                >
                                  <Thead>
                                    <Tr bg="green.600" height="10">
                                      <Th color="white">DATA</Th>
                                      <Th color="white" textAlign="center">
                                        VALOR DO CRÉDITO
                                      </Th>
                                      <Th color="white" textAlign="center">
                                        FORMA DE PAGAMENTO
                                      </Th>
                                      <Th color="white" textAlign="center">
                                        N DOCUMENTO
                                      </Th>
                                    </Tr>
                                  </Thead>
                                  <Tbody>
                                    {data.furnisher_credit_history.map(
                                      (item, index) => (
                                        <Tr key={index}>
                                          <Td>
                                            {formatedMonthDay(item.credit_date)}
                                          </Td>

                                          <Td textAlign="center">
                                            R${" "}
                                            {formatCurrency(item.credit_amount)}
                                          </Td>
                                          <Td textAlign="center">
                                            {creditTypePayment(
                                              item.form_of_payment
                                            )}
                                          </Td>
                                          <Td textAlign="center">
                                            {item.document_number}
                                          </Td>
                                        </Tr>
                                      )
                                    )}
                                  </Tbody>
                                </Table>
                              </TableContainer>
                            </CardBody>
                          </Box>
                        </Stack>
                      </CardBody>
                    </Card>
                  )}

                  <Card mt={5}>
                    <CardHeader>
                      <Heading size="md">INFORMAÇÕES DO PRODUTO:</Heading>
                    </CardHeader>

                    <CardBody>
                      <TableContainer>
                        <Table variant="striped" colorScheme="gray" size="sm">
                          <Thead>
                            <Tr bg="green.600" height="10">
                              <Th color="white">NOME COMERCIAL</Th>
                              <Th color="white" textAlign="center">
                                UND
                              </Th>
                              <Th color="white" textAlign="center">
                                QUANTIDADE
                              </Th>
                              <Th color="white" textAlign="center">
                                PREÇO ATUAL
                              </Th>
                              <Th color="white" textAlign="center">
                                % DE DESCONTO
                              </Th>
                              <Th color="white" textAlign="center">
                                NOVO PREÇO
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {data.furnisher_office_products.map(
                              (item, index) => (
                                <Tr key={index}>
                                  <Td>{item.product_furnisher.name}</Td>

                                  <Td textAlign="center">
                                    {item.product_furnisher.unit}
                                  </Td>
                                  <Td textAlign="center">
                                    {formatCurrency(
                                      Number(item.product_furnisher.quantity)
                                    )}
                                  </Td>
                                  <Td textAlign="center">
                                    R${" "}
                                    {formatCurrency(
                                      item.product_furnisher.price
                                    )}
                                  </Td>
                                  <Td textAlign="center">
                                    {formatCurrency(item.discount)}%
                                  </Td>
                                  <Td textAlign="center">
                                    R${" "}
                                    {formatCurrency(
                                      calculationNewPrice(
                                        item.product_furnisher.price,
                                        item.discount
                                      )
                                    )}
                                  </Td>
                                </Tr>
                              )
                            )}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </CardBody>
                  </Card>
                  {data.status === "registered" && (
                    <Box
                      display="flex"
                      width="100%"
                      alignItems="center"
                      justifyContent="flex-end"
                      gap={2}
                    >
                      <Button
                        type="submit"
                        height={10}
                        colorScheme="red"
                        mt="6"
                        size="lg"
                        onClick={() => {
                          onOpen();
                        }}
                      >
                        Reprovar contrato
                      </Button>
                      <Button
                        type="submit"
                        bg="green.600"
                        color="white"
                        alignSelf="flex-end"
                        height={10}
                        _hover={{
                          bg: "green.800",
                        }}
                        mt="6"
                        size="lg"
                        onClick={() => {
                          sendUdate("confirmed");
                        }}
                      >
                        Aprovar contrato
                      </Button>
                    </Box>
                  )}
                </Box>
              </Flex>

              <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isCentered
                motionPreset="slideInBottom"
                closeOnEsc={false}
                closeOnOverlayClick={false}
              >
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                      Contrato de fornecimento.
                    </AlertDialogHeader>

                    <AlertDialogBody>
                      <Text>Deseja reprovar o contrato?</Text>
                    </AlertDialogBody>

                    <AlertDialogFooter>
                      <>
                        <Button
                          colorScheme="red"
                          onClick={() => {
                            sendUdate("canceled");
                          }}
                        >
                          Sim
                        </Button>
                        <Button ml={3} onClick={onClose}>
                          Nāo
                        </Button>
                      </>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
            </Flex>
          )}
        </Box>
      </Flex>
    </Box>
  );
}
