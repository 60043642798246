import { Box, Flex, Icon, SimpleGrid, Spinner, Text } from "@chakra-ui/react";

import ReactApexChart from "react-apexcharts";
import { HeaderCard } from "../../../components/headerCard";
import { FilterHome } from "./filter";
import { CardInfoData } from "../../../components/cardInfoData";
import {
  RiCarFill,
  RiMoneyDollarCircleFill,
  RiRepeatOneLine,
} from "react-icons/ri";
import { formatCurrency } from "../../../utils/utils";
import { useDailyVolume } from "./hooks/useDailyVolume";
import { useVolumeGeneral } from "./hooks/useVolumeGeneral";
import { useCostGeneral } from "./hooks/useCostGeneral";
import { useResumeGeneral } from "./hooks/useResumeGeneral";

export function Home() {
  const { categories, dataList, loading, error } = useDailyVolume();

  const {
    categories: optionsVolumeGeneral,
    dataList: dataVolumeGeneral,
    loading: loadingVolumeGeneral,
  } = useVolumeGeneral();

  const {
    categories: optionsCost,
    dataList: dataCost,
    loading: loadingCost,
  } = useCostGeneral();

  const { dataList: dataListResume, loading: loadingResume } =
    useResumeGeneral();

  return (
    <Box>
      <HeaderCard isFilter>
        <Box height="160px" flexDirection="column">
          <Text fontWeight="bold" mt={5} fontSize="26px" color="white">
            Filtros
          </Text>
          <Flex justifyContent="space-between" width="100%" alignItems="center">
            <FilterHome />
          </Flex>
        </Box>
      </HeaderCard>

      <Flex mt={5} px={5} alignItems="center" gap={2}>
        <CardInfoData
          isLoading={loadingResume}
          isWdAll
          icon={<Icon fontSize="25px" as={RiRepeatOneLine} />}
          title="N de requisições"
          subTitle={
            dataListResume.quantity_requisition
              ? dataListResume.quantity_requisition.toString()
              : "0"
          }
        />

        <CardInfoData
          isLoading={loadingResume}
          isWdAll
          icon={<Icon fontSize="25px" as={RiRepeatOneLine} />}
          title="N de atendimentos"
          subTitle={
            dataListResume.quantity_attend
              ? dataListResume.quantity_attend.toString()
              : "0"
          }
        />

        <CardInfoData
          isLoading={loadingResume}
          isWdAll
          icon={<Icon fontSize="25px" as={RiCarFill} />}
          title="N de veículos"
          subTitle={
            dataListResume.quantity_cars
              ? dataListResume.quantity_cars.toString()
              : "0"
          }
        />
        <CardInfoData
          isLoading={loadingResume}
          isWdAll
          icon={<Icon fontSize="25px" as={RiRepeatOneLine} />}
          title="Q. fornecida"
          subTitle={formatCurrency(
            dataListResume.quantity_supply ?? 0
          ).toString()}
        />
        <CardInfoData
          isLoading={loadingResume}
          isWdAll
          icon={
            <Icon fontSize="25px" color="white" as={RiMoneyDollarCircleFill} />
          }
          title="Valor Total"
          isTotal
          subTitle={
            "R$ " + formatCurrency(dataListResume.total_amount ?? 0).toString()
          }
        />
      </Flex>

      <SimpleGrid
        flex="1"
        gap="4"
        minChildWidth="320px"
        alignItems="flex-start"
        px={5}
        py={5}
      >
        <Box p="8" bg="green.50" borderRadius={8} pb="4">
          <Text fontWeight="bold" mb="4" color="green.800">
            Valor diário por produto
          </Text>
          <Box minHeight={200}>
            {!loading && categories && !error && dataList && (
              <ReactApexChart
                options={categories}
                series={dataList.series}
                type="line"
                height={200}
              />
            )}

            {loading && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={200}
              >
                <Spinner
                  color="green.600"
                  emptyColor="white"
                  thickness="4px"
                  size="xl"
                  h={100}
                  w={100}
                />
              </Box>
            )}

            {!loading && error && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={200}
              >
                <Text>Sem dados de relatórios</Text>
              </Box>
            )}
          </Box>
        </Box>
      </SimpleGrid>

      <SimpleGrid
        flex="1"
        gap="4"
        minChildWidth="320px"
        alignItems="flex-start"
        px={5}
        mb={5}
      >
        <Box p="8" bg="green.50" borderRadius={8}>
          <Text fontWeight="bold" mb="4" color="green.800">
            Volume geral por produto
          </Text>
          <Box>
            {!loadingVolumeGeneral &&
              optionsVolumeGeneral &&
              dataVolumeGeneral && (
                <ReactApexChart
                  options={optionsVolumeGeneral}
                  series={dataVolumeGeneral.series}
                  type="bar"
                  height={200}
                />
              )}

            {loadingVolumeGeneral && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={200}
              >
                <Spinner
                  color="green.600"
                  emptyColor="white"
                  thickness="4px"
                  size="xl"
                  h={100}
                  w={100}
                />
              </Box>
            )}

            {!loadingVolumeGeneral && !dataVolumeGeneral && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={200}
              >
                <Text>Sem dados de relatórios</Text>
              </Box>
            )}
          </Box>
        </Box>

        <Box p="8" bg="green.50" borderRadius={8}>
          <Text fontWeight="bold" mb="4" color="green.800">
            Custo geral por produto
          </Text>
          <Box>
            {!loadingCost && optionsCost && dataCost && (
              <ReactApexChart
                options={optionsCost}
                series={dataCost.series}
                type="bar"
                height={200}
              />
            )}

            {loadingCost && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={200}
              >
                <Spinner
                  color="green.600"
                  emptyColor="white"
                  thickness="4px"
                  size="xl"
                  h={100}
                  w={100}
                />
              </Box>
            )}

            {!loadingCost && !dataCost && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={200}
              >
                <Text>Sem dados de relatórios</Text>
              </Box>
            )}
          </Box>
        </Box>
      </SimpleGrid>
    </Box>
  );
}
