import { useState } from "react";
import { api } from "../../../../service/api";
import { CarGroupDTO } from "../../../../dtos/CarGroupDTO";
import { OptionsDTO } from "../../../../dtos/OptionsDTO";

export function useListCarGroup() {
  const [isLoadingCar, setIsLoadingCar] = useState(false);
  const [dataCar, setDataCar] = useState<CarGroupDTO[]>([]);
  const [dataOptionsCar, setDataOptionsCar] = useState<OptionsDTO[]>([]);

  const getListCarGroup = async (group_name: string) => {
    try {
      setIsLoadingCar(true);
      const response = await api.get("/company/requisition/car/" + group_name);
      const carData = response.data;
      setDataCar(carData);

      const newDataCar: OptionsDTO[] = [];

      for (let index = 0; index < carData.length; index++) {
        const element = carData[index];
        newDataCar.push({
          label:
            "COD: " +
            element.code_car_fleet +
            " - " +
            "PLACA: " +
            element.plaque +
            " - " +
            element.mark +
            " - " +
            element.structure_type.type,
          value: element.id,
        });
      }

      setDataOptionsCar(newDataCar);
    } catch (error) {
      setDataOptionsCar([]);
      setDataCar([]);
    } finally {
      setIsLoadingCar(false);
    }
  };

  return { isLoadingCar, getListCarGroup, dataCar, dataOptionsCar };
}
