import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { HeaderCard } from "../../../../components/headerCard";
import { RiArrowLeftSLine } from "react-icons/ri";
import { InputSelect } from "../../../../components/form/inputSelect";
import { InputLine } from "../../../../components/form/InputLine";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useListMotoristCar } from "../../requisition/hooks/useListMotoristCar";
import { useCarService } from "../../../../service/CarService";
import { DataMotiveTravel } from "../data/data-motive-travel";
import { useListUserApprover } from "../../fleetMovement/hooks/useListUserApprover";
import { InputPrice } from "../../../../components/form/inputPrice";
import { useZipCode } from "../../../../service/zipCodeService";
import { InputLineMask } from "../../../../components/form/inputLineMask";
import { AllLoading } from "../../../../components/allLoading";
import { formatCurrency } from "../../../../utils/utils";
import { api } from "../../../../service/api";

type createFormData = {
  car_fleet_id: string;
  motorist_id: string;
  user_id: string;
  type: string;
  zip_code_origin: string;
  origin_travel: string;
  zip_code_destination: string;
  destination_travel: string;
  motive_travel: string;
  departure_date: string;
  arrival_date: string;
  distance: string;
  distance_round_trip?: string;
  departure_odometer: string;
  arrival_odometer?: string;
  observation?: string;
};

const createFormDataSchema = yup.object().shape({
  car_fleet_id: yup.string().required("Campo obrigatório."),
  motorist_id: yup.string().required("Campo obrigatório."),
  user_id: yup.string().required("Campo obrigatório."),
  type: yup.string().required("Campo obrigatório."),
  zip_code_origin: yup.string().required("Campo obrigatório."),
  origin_travel: yup.string().required("Campo obrigatório."),
  zip_code_destination: yup.string().required("Campo obrigatório."),
  destination_travel: yup.string().required("Campo obrigatório."),
  motive_travel: yup.string().required("Campo obrigatório."),
  departure_date: yup.string().required("Campo obrigatório."),
  arrival_date: yup.string().required("Campo obrigatório."),
  distance: yup.string().required("Campo obrigatório."),
  distance_round_trip: yup.string(),
  departure_odometer: yup.string().required("Campo obrigatório."),
  arrival_odometer: yup.string(),
  observation: yup.string(),
});

export function CreateTravel() {
  const toast = useToast();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createFormDataSchema) });

  const { getCarService, dataOptionsCar, isLoadingCar } = useCarService();

  const { getMotoristCar, dataOptionsMotorist, isLoadingMotorist } =
    useListMotoristCar();

  const { getListUserApprover, dataOptionsUser, isLoadingUser } =
    useListUserApprover();

  const { getZipCode, isLoading: isLoadingZipCode } = useZipCode();

  useEffect(() => {
    getCarService("");
    getMotoristCar();
    getListUserApprover();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const zipCodeOrigin = watch("zip_code_origin");
  const zipCodeDestination = watch("zip_code_destination");

  async function getAddressOrigin(zipcode: string) {
    const dataZipcode = await getZipCode(zipcode);
    const addressName = `${dataZipcode.localidade}-${dataZipcode.uf}`;
    setValue("origin_travel", addressName);
  }

  async function getAddressDestination(zipcode: string) {
    const dataZipcode = await getZipCode(zipcode);
    const addressName = `${dataZipcode.localidade}-${dataZipcode.uf}`;
    setValue("destination_travel", addressName);
  }

  useEffect(() => {
    if (zipCodeOrigin && zipCodeOrigin.length >= 9) {
      getAddressOrigin(zipCodeOrigin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCodeOrigin]);

  useEffect(() => {
    if (zipCodeDestination && zipCodeDestination.length >= 9) {
      getAddressDestination(zipCodeDestination);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCodeDestination]);

  const handleSend: SubmitHandler<createFormData> = async (value) => {
    try {
      const data = {
        ...value,
        distance: value.distance.replace(".", "").replace(",", "."),
        distance_round_trip: value.distance_round_trip
          ? value.distance_round_trip.replace(".", "").replace(",", ".")
          : 0,
      };

      await api.post("/company/trip/create", data);

      toast({
        position: "top-right",
        title: "Produto",
        description: "Frota em viagem cadastrada com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      navigate("/frotas-em-viagens");
    } catch (error) {
      toast({
        position: "top-right",
        title: "Produto",
        description: "Não foi possível cadastrar frota em viagem.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <HeaderCard
        title="FROTAS EM VIAGENS"
        titleButton="Voltar"
        icon={RiArrowLeftSLine}
        link="/frotas-em-viagens"
      />

      {isLoadingZipCode && <AllLoading />}

      <Flex justifyContent="center" width="100%">
        <Box>
          <Flex
            px={10}
            py={5}
            width="100%"
            flexDirection="column"
            as="form"
            onSubmit={handleSubmit(handleSend)}
          >
            <Flex flex={1} alignItems="center" justifyContent="space-between">
              <Box
                p="4"
                bg="green.50"
                minHeight={430}
                borderRadius={8}
                width="879px"
              >
                <Text fontWeight="bold" mb="4" color="green.800">
                  INFORMAÇÕES DA VIAGEM:
                </Text>

                <Flex gap="2" mt={5}>
                  <Controller
                    control={control}
                    name="car_fleet_id"
                    render={({ field: { onChange, value } }) => (
                      <InputSelect
                        label="VEÍCULO DA FROTA"
                        name="car_fleet_id"
                        isLoading={isLoadingCar}
                        options={dataOptionsCar}
                        width="410px"
                        placeholder="Selecione o veículo da frota"
                        onChange={(item) => {
                          onChange(item.value);
                        }}
                        error={errors.car_fleet_id}
                      />
                    )}
                  />

                  <InputLine
                    autoComplete="off"
                    type="date"
                    width="210px"
                    label="DATA DA IDA"
                    placeholder="Informe a data da ida"
                    {...register("departure_date")}
                    error={errors.departure_date}
                  />

                  <InputLine
                    autoComplete="off"
                    type="date"
                    width="210px"
                    label="DATA DA CHEGADA"
                    placeholder="Informe a data da chegada"
                    {...register("arrival_date")}
                    error={errors.arrival_date}
                  />
                </Flex>

                <Flex gap="2" mt={5}>
                  <InputLineMask
                    mask="99999-999"
                    autoComplete="off"
                    width="200px"
                    label="CEP ORIGEM"
                    placeholder="Informe o cep de origem"
                    {...register("zip_code_origin")}
                    error={errors.zip_code_origin}
                  />
                  <InputLine
                    autoComplete="off"
                    width="200px"
                    label="CIDADE DE ORIGEM"
                    placeholder="Informe a cidade de origem"
                    isDisabled
                    {...register("origin_travel")}
                    error={errors.origin_travel}
                  />
                  <InputLineMask
                    mask="99999-999"
                    autoComplete="off"
                    width="210px"
                    label="CEP DESTINO"
                    placeholder="Informe o cep de destino"
                    {...register("zip_code_destination")}
                    error={errors.zip_code_destination}
                  />
                  <InputLine
                    autoComplete="off"
                    width="210px"
                    label="CIDADE DE DESTINO"
                    placeholder="Informe a cidade de destino"
                    isDisabled
                    {...register("destination_travel")}
                    error={errors.destination_travel}
                  />
                </Flex>

                <Flex gap="2" mt={5}>
                  <Controller
                    control={control}
                    name="motorist_id"
                    render={({ field: { onChange, value } }) => (
                      <InputSelect
                        label="MOTORISTA"
                        name="motorist_id"
                        isLoading={isLoadingMotorist}
                        options={dataOptionsMotorist}
                        width="200px"
                        placeholder="Selecione motorista"
                        onChange={(item) => {
                          onChange(item.value);
                        }}
                        error={errors.motorist_id}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="motive_travel"
                    render={({ field: { onChange, value } }) => (
                      <InputSelect
                        label="MOTIVO DA VIAGEM"
                        name="motive_travel"
                        options={DataMotiveTravel}
                        width="200px"
                        placeholder="Selecione o motivo da viagem"
                        onChange={(item) => {
                          onChange(item.value);
                        }}
                        error={errors.motive_travel}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="user_id"
                    render={({ field: { onChange, value } }) => (
                      <InputSelect
                        width="210px"
                        label="USUÁRIO"
                        name="user_id"
                        placeholder="Selecione o usuário"
                        options={dataOptionsUser}
                        isLoading={isLoadingUser}
                        onChange={(item) => {
                          onChange(item.value);
                        }}
                        error={errors.user_id}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="distance"
                    render={({ field: { onChange, value } }) => (
                      <InputPrice
                        width="210px"
                        name="distance"
                        label="DISTÂNCIA (KM)"
                        value={value}
                        onChange={onChange}
                        error={errors.distance}
                      />
                    )}
                  />
                </Flex>

                <Flex gap="2" mt={5}>
                  <InputLine
                    autoComplete="off"
                    width="200px"
                    type="number"
                    label="ODO. PARTIDA"
                    placeholder="Informe o odo. partida"
                    textAlign="center"
                    {...register("departure_odometer")}
                    error={errors.departure_odometer}
                  />
                  <InputLine
                    autoComplete="off"
                    width="210px"
                    type="number"
                    label="ODO. CHEGADA"
                    placeholder="Informe o odo. de chegada"
                    textAlign="center"
                    {...register("arrival_odometer")}
                    error={errors.arrival_odometer}
                  />

                  <Controller
                    control={control}
                    name="type"
                    render={({ field: { onChange, value } }) => (
                      <InputSelect
                        width="210px"
                        label="TIPO DE VIAGEM"
                        name="type"
                        placeholder="Selecione o tipo de viagem"
                        options={[
                          {
                            label: "Somente Ida",
                            value: "outward_trip",
                          },
                          {
                            label: "Ida e Volta",
                            value: "round_trip",
                          },
                        ]}
                        onChange={(item) => {
                          onChange(item.value);

                          if (item.value === "round_trip") {
                            let distance = getValues("distance");
                            if (!distance) return;
                            const newDistance = Number(
                              distance.replace(".", "").replace(",", ".")
                            );
                            let distanceRoundTrip = newDistance * 2;

                            let distanceRoundTripFormat = formatCurrency(
                              distanceRoundTrip
                            ) as string;
                            setValue(
                              "distance_round_trip",
                              distanceRoundTripFormat
                            );
                          }
                        }}
                        error={errors.type}
                      />
                    )}
                  />
                  <InputLine
                    autoComplete="off"
                    width="200px"
                    label="DISTÂNCIA (IDA E VOLTA)"
                    placeholder="0,000"
                    textAlign="center"
                    isDisabled
                    {...register("distance_round_trip")}
                    error={errors.distance_round_trip}
                  />
                </Flex>

                <Flex mt={5} gap="2">
                  <InputLine
                    autoComplete="off"
                    width="100%"
                    label="OBSERVAÇÕES"
                    placeholder="Informe as observações"
                    name="teste"
                  />
                </Flex>
              </Box>
            </Flex>

            <Button
              type="submit"
              bg="green.600"
              color="white"
              alignSelf="flex-end"
              height={10}
              _hover={{
                bg: "green.800",
              }}
              mt="6"
              size="lg"
              isLoading={isSubmitting}
            >
              Salvar
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}
