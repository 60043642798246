import { useState } from "react";
import { api } from "../../../../service/api";
import { OfficeDTO } from "../../../../dtos/OfficeDTO";

export function useShowOffice() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<OfficeDTO>({} as OfficeDTO);

  const findOffice = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await api.get("company/office/show/" + id);
      setData(response.data);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, findOffice, data };
}
