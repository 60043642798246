import { useState } from "react";
import { api } from "../../../../service/api";
import { PartnerDTO } from "../../../../dtos/PartnerDTO";
import { OptionsDTO } from "../../../../dtos/OptionsDTO";

export function useListPartner() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<PartnerDTO[]>([]);

  const [dataOptionsFurnisher, setDataOptionsFurnisher] = useState<
    OptionsDTO[]
  >([]);
  const [dataOptionsOffice, setDataOptionsOffice] = useState<OptionsDTO[]>([]);

  const getPartner = async () => {
    try {
      setIsLoading(true);
      const response = await api.get("/company/partner/list");
      const dataPartner: PartnerDTO[] = response.data;
      setData(dataPartner);

      const newDataOffice: OptionsDTO[] = [];
      const newDataFurnisher: OptionsDTO[] = [];

      for (let index = 0; index < dataPartner.length; index++) {
        const element = dataPartner[index];

        const existOffice = newDataOffice.find(
          (it) => it.value === element.office_branch.id
        );

        if (!existOffice) {
          newDataOffice.push({
            label:
              element.office_branch.name_ug +
              " - " +
              element.office_branch.cnpj,
            value: element.office_branch.id,
          });
        }

        newDataFurnisher.push({
          label:
            element.furnisher.fantasy_name + " - " + element.furnisher.cnpj,
          value: element.furnisher.id,
        });
      }

      setDataOptionsFurnisher(newDataFurnisher);
      setDataOptionsOffice(newDataOffice);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    getPartner,
    data,
    dataOptionsFurnisher,
    dataOptionsOffice,
  };
}
