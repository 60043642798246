import { useState } from "react";
import { api } from "../../../../service/api";
import { ProductDataDTO } from "../../../../dtos/ProductDataDTO";

interface Props {
  furnisher_id: string;
  office_id: string;
  category: string;
  type: string;
}

export function useListProduct() {
  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [dataProduct, setDataProduct] = useState<ProductDataDTO[]>([]);

  const getProducts = async ({
    furnisher_id,
    office_id,
    category,
    type,
  }: Props) => {
    try {
      setIsLoadingProduct(true);
      const response = await api.get(
        `company/product/list/${furnisher_id}/${office_id}?category=${category}&type=${type}`
      );
      setDataProduct(response.data);
    } catch (error) {
      throw error;
    } finally {
      setIsLoadingProduct(false);
    }
  };

  return { isLoadingProduct, getProducts, dataProduct };
}
