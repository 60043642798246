export const DataMotiveTravel = [
  { label: "Manutenção Corretiva", value: "Manutenção Corretiva" },
  {
    label: "Participação em Treinamento",
    value: "Participação em Treinamento",
  },
  { label: "Revisão Programada", value: "Revisão Programada" },
  {
    label: "Transferência de Colaborador",
    value: "Transferência de Colaborador",
  },
  { label: "Transferência de Frota", value: "Transferência de Frota" },
  { label: "Transporte de Cargas", value: "Transporte de Cargas" },
  { label: "Transporte de Passageiros", value: "Transporte de Passageiros" },
  {
    label: "Viagem para Atendimento Médico",
    value: "Viagem para Atendimento Médico",
  },
  { label: "Outros (especificar)", value: "Outros (especificar)" },
];
