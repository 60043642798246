import axios from "axios";
import { Cookies } from "react-cookie-consent";

const token = Cookies.get("entankcompany.token");
//http://0.0.0.0:8000
//https://api.etank.com.br
export const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
