import {
  FormControl,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormErrorMessage,
} from "@chakra-ui/react";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { FieldError } from "react-hook-form";

interface Props extends ChakraInputProps {
  name: string;
  label?: string;
  error?: FieldError;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { name, label, error, ...rest },
  ref
) => {
  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <FormLabel color="white" htmlFor={name}>
          {label}
        </FormLabel>
      )}

      <ChakraInput
        mt={-1}
        {...rest}
        id={name}
        name={name}
        borderColor="gray.400"
        borderWidth={1}
        focusBorderColor="green.600"
        bgColor="white"
        variant="filled"
        size="md"
        _hover={{
          bgColor: "white",
        }}
        _focus={{
          bgColor: "white",
        }}
        ref={ref}
      />
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export const InputFilter = forwardRef(InputBase);
