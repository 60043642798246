import { useState } from "react";
import { api } from "../../../../service/api";
import { OptionsDTO } from "../../../../dtos/OptionsDTO";
import { UsersDTO } from "../../../../dtos/UsersDTO";

export function useListUserApprover() {
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [dataUser, setDataUser] = useState<UsersDTO[]>([]);
  const [dataOptionsUser, setDataOptionsUser] = useState<OptionsDTO[]>([]);

  const getListUserApprover = async () => {
    try {
      setIsLoadingUser(true);
      const response = await api.get("company/admin/user/list");

      const userData = response.data;
      setDataUser(userData);

      const newDataUser: OptionsDTO[] = [];

      for (let index = 0; index < userData.length; index++) {
        const element = userData[index];

        newDataUser.push({
          label: element.name,
          value: element.id,
        });
      }

      setDataOptionsUser(newDataUser);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingUser(false);
    }
  };

  return { isLoadingUser, dataOptionsUser, getListUserApprover, dataUser };
}
