import {
  Box,
  Button,
  Flex,
  Icon,
  SimpleGrid,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { HeaderCard } from "../../../components/headerCard";
import { useListMileage } from "./hooks/useListMileage";
import { lmWord } from "../../../utils/utils";
import { FilterMileage } from "./filter";
import { RiBarChartBoxLine } from "react-icons/ri";

export function Mileage() {
  const { dataList, loading } = useListMileage();

  return (
    <Box>
      <HeaderCard isFilter>
        <Box height="120px" flexDirection="column" width="100%">
          <Flex
            mt={5}
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <FilterMileage />
          </Flex>
        </Box>
      </HeaderCard>

      {loading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={500}
        >
          <Spinner
            color="green.600"
            emptyColor="white"
            thickness="4px"
            size="xl"
            h={100}
            w={100}
          />
        </Box>
      )}

      <SimpleGrid
        flex="1"
        gap="4"
        minChildWidth="320px"
        alignItems="flex-start"
        px={1}
        py={1}
      >
        {dataList.length > 0 && !loading && (
          <Box p="4" borderRadius={8} height={500} overflow="scroll">
            <TableContainer>
              <Table variant="striped" colorScheme="gray" size="sm">
                <Thead>
                  <Tr bg="green.600" height="10">
                    <Th color="white" textAlign="center">
                      CÓDIGO
                    </Th>
                    <Th color="white" textAlign="center">
                      PLACA
                    </Th>
                    <Th color="white" textAlign="center">
                      MARCA
                    </Th>
                    <Th color="white" textAlign="center">
                      MODELO
                    </Th>
                    <Th color="white" textAlign="center">
                      QTDE
                    </Th>
                    <Th color="white" textAlign="center">
                      VOLUME ABAST.
                    </Th>
                    <Th color="white" textAlign="center">
                      1 ODO
                    </Th>
                    <Th color="white" textAlign="center">
                      ÚLTIMO ODO.
                    </Th>
                    <Th color="white" textAlign="center">
                      TOTAL RODADO
                    </Th>
                    <Th color="white" textAlign="center">
                      CONSUMO P.
                    </Th>
                    <Th color="white" textAlign="center">
                      CONSUMO R.
                    </Th>
                    <Th color="white" textAlign="center">
                      UND. CONSUMO
                    </Th>
                    <Th color="white" textAlign="center">
                      AÇÕES
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {dataList.map((item, index) => (
                    <Tr key={index}>
                      <Td textAlign="center">{item.code_car_fleet}</Td>
                      <Td textAlign="center">{item.plaque}</Td>
                      <Td textAlign="center">{lmWord(item.mark, 10)}</Td>
                      <Td textAlign="center">{lmWord(item.model, 10)}</Td>
                      <Td textAlign="center" color="blue">
                        {item.historicDetail.length}
                      </Td>
                      <Td textAlign="center" color="blue">
                        {item.historicResume.quantity}
                      </Td>
                      <Td textAlign="center" color="blue">
                        {item.initial_odometer}
                      </Td>
                      <Td textAlign="center" color="blue">
                        {item.historicDetail.length > 0 &&
                          item.historicDetail[item.historicDetail.length - 1]
                            .end_odometer}
                      </Td>
                      <Td textAlign="center" color="blue">
                        {item.historicResume.km}
                      </Td>

                      <Td textAlign="center" color="blue">
                        {item.indicationCons.consumer_pattern}
                      </Td>

                      <Td textAlign="center" color="blue">
                        {item.indicationCons.consumer_realized.toFixed(2)}
                      </Td>

                      <Td textAlign="center">{item.consumer_unit}</Td>
                      <Td textAlign="center" width="10">
                        <Button
                          size="sm"
                          fontSize="sm"
                          as="a"
                          href={`/quilometragem-da-frota/${item.id}`}
                        >
                          <Icon fontSize="16px" as={RiBarChartBoxLine} />
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </SimpleGrid>
    </Box>
  );
}
