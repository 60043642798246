import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { InputFilterSelect } from "../../../../../components/filter/inputFilterSelect";
import { useListGroup } from "../../hooks/useListGroup";
import { useListCarGroup } from "../../hooks/useListCarGroup";
import { useListMotoristCar } from "../../hooks/useListMotoristCar";
import { RequistionTypeCar } from "../../types/requistion-type-car";

type Props = {
  property: string;
  callBackSuccess: (data: RequistionTypeCar) => void;
};

export function FormDataFleet({ callBackSuccess, property }: Props) {
  const { getListGroup, isLoadingGroup, dataOptionsGroup } = useListGroup();
  const { getListCarGroup, dataOptionsCar, dataCar, isLoadingCar } =
    useListCarGroup();
  const { getMotoristCar, dataOptionsMotorist, isLoadingMotorist } =
    useListMotoristCar();

  const [groupName, setGroupName] = useState("");
  const [carId, setCarId] = useState("");
  const [motoristName, setMotoristName] = useState("");

  useEffect(() => {
    getListGroup(property);
    getMotoristCar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property]);

  useEffect(() => {
    getListCarGroup(groupName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupName]);

  function sendFleet() {
    const infoCar = dataCar.find((it) => it.id === carId);
    if (infoCar) {
      callBackSuccess({
        car_fleet_id: infoCar.id,
        fleet_code: infoCar.code_car_fleet,
        group_name: groupName,
        mark: infoCar.mark,
        model: infoCar.model,
        name: infoCar.structure_type.type,
        motorist_name: motoristName,
        plaque: infoCar.plaque,
        type_fuel: infoCar.type_fuel,
      });
    }
  }

  return (
    <Box>
      <Text fontSize="large">Selecione as informações para continuar</Text>
      <Box display="flex" mt={5} width={745} flexDirection="column">
        <Flex gap="2">
          <InputFilterSelect
            colorLabel="#1E293B"
            label="GRUPO"
            name="group_name"
            isLoading={isLoadingGroup}
            options={dataOptionsGroup}
            width="100%"
            placeholder="Selecione grupo"
            onChange={(item) => {
              setGroupName(item.label);
            }}
          />
          <InputFilterSelect
            colorLabel="#1E293B"
            label="MOTORISTA"
            name="motorist"
            isLoading={isLoadingMotorist}
            options={dataOptionsMotorist}
            width="100%"
            placeholder="Selecione motorista"
            onChange={(item) => {
              setMotoristName(item.label);
            }}
          />
        </Flex>
        <Flex gap="2" mt={5}>
          <InputFilterSelect
            colorLabel="#1E293B"
            label="VEÍCULO DA FROTA"
            name="fleet"
            isLoading={isLoadingCar}
            options={dataOptionsCar}
            width="100%"
            placeholder="Selecione o veículo da frota"
            onChange={(item) => {
              setCarId(item.value);
            }}
          />
        </Flex>

        <Button
          type="submit"
          bg="green.600"
          color="white"
          alignSelf="flex-end"
          height={10}
          _hover={{
            bg: "green.800",
          }}
          mt="6"
          size="lg"
          isDisabled={!groupName || !carId || !motoristName}
          onClick={sendFleet}
        >
          Continuar
        </Button>
      </Box>
    </Box>
  );
}
