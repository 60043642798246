import { Flex, IconButton } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { RiCloseFill, RiFilterLine } from "react-icons/ri";
import { useListPartner } from "../../../launch/requisition/hooks/useListPartner";
import { InputFilterSelect } from "../../../../components/filter/inputFilterSelect";
import { InputFilter } from "../../../../components/filter/inputFilter";
import { OptionsDTO } from "../../../../dtos/OptionsDTO";

export function FilterHome() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { getPartner, dataOptionsFurnisher, dataOptionsOffice, isLoading } =
    useListPartner();

  const [newDataOptionsFurnisher, setDataOptionsFurnisher] = useState<
    OptionsDTO[]
  >([]);

  const office_id = searchParams.get("office_id");
  const furnisher_id = searchParams.get("furnisher_id");
  const start_date = searchParams.get("start_date");
  const end_date = searchParams.get("end_date");
  const status_filter = searchParams.get("status");

  const [officeId, setOfficeId] = useState(office_id ?? "");
  const [furnisherId, setFurnisherId] = useState(furnisher_id ?? "");
  const [startDate, setStartDate] = useState(start_date ?? "");
  const [endDate, setEndDate] = useState(end_date ?? "");
  const [status, setStatus] = useState(status_filter ?? "");

  useEffect(() => {
    let arOptions: OptionsDTO[] = [];

    for (let index = 0; index < dataOptionsFurnisher.length; index++) {
      const element = dataOptionsFurnisher[index];
      const existOption = arOptions.find(
        (item) => item.value === element.value
      );

      if (!existOption) {
        arOptions.push(element);
      }
    }

    setDataOptionsFurnisher(arOptions);
  }, [dataOptionsFurnisher]);

  useEffect(() => {
    getPartner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleFilter() {
    setSearchParams((state) => {
      if (officeId) {
        state.set("office_id", officeId);
      } else {
        state.delete("office_id");
      }

      if (furnisherId) {
        state.set("furnisher_id", furnisherId);
      } else {
        state.delete("furnisher_id");
      }

      if (startDate) {
        state.set("start_date", startDate);
      } else {
        state.delete("start_date");
      }

      if (endDate) {
        state.set("end_date", endDate);
      } else {
        state.delete("end_date");
      }

      if (status) {
        state.set("status", status);
      } else {
        state.delete("status");
      }

      return state;
    });
  }

  function handleClear() {
    setSearchParams((state) => {
      state.delete("office_id");
      setOfficeId("");

      state.delete("furnisher_id");
      setFurnisherId("");

      state.delete("start_date");
      setStartDate("");

      state.delete("end_date");
      setEndDate("");

      state.delete("status");
      setStatus("");
      return state;
    });

    window.location.reload();
  }

  return (
    <Flex gap="2">
      <InputFilterSelect
        isLoading={isLoading}
        label="UND GESTÃO:"
        name="client_id"
        options={dataOptionsOffice}
        width="350px"
        placeholder="Selecione..."
        onChange={(item) => {
          setOfficeId(item.value);
        }}
      />
      <InputFilterSelect
        isLoading={isLoading}
        label="FORNECEDOR:"
        name="product_id"
        options={newDataOptionsFurnisher}
        width="350px"
        placeholder="Selecione..."
        onChange={(item) => {
          setFurnisherId(item.value);
        }}
      />

      <InputFilter
        width="150px"
        type="date"
        label="DATA INÍCIO:"
        name="start_date"
        value={startDate}
        onChange={(e) => {
          setStartDate(e.target.value);
        }}
      />
      <InputFilter
        width="150px"
        type="date"
        label="DATA FINAL:"
        name="end_date"
        value={endDate}
        onChange={(e) => {
          setEndDate(e.target.value);
        }}
      />

      {(officeId || furnisherId || status || startDate || endDate) && (
        <>
          <IconButton
            ml={3}
            mt={7}
            aria-label="Filtro"
            icon={<RiFilterLine />}
            onClick={handleFilter}
          />
          <IconButton
            mt={7}
            aria-label="Limpar"
            colorScheme="red"
            variant="outline"
            onClick={handleClear}
            icon={<RiCloseFill />}
          />
        </>
      )}
    </Flex>
  );
}
