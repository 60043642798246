import { Flex, Skeleton, Stack } from "@chakra-ui/react";

export function LoadingFurnisher() {
  return (
    <Stack spacing="4">
      {[1, 2, 3, 5, 6].map((_, index) => {
        return (
          <Flex
            key={index}
            height={67}
            width={745}
            bg="white"
            borderRadius={10}
            borderWidth={1}
            borderColor="green.400"
          >
            <Skeleton
              height={65}
              startColor="green.400"
              endColor="green.600"
              borderRadius={10}
              fadeDuration={1}
              width="100%"
            />
          </Flex>
        );
      })}
    </Stack>
  );
}
