import { useState } from "react";
import { api } from "../../../../service/api";
import { SctructuretypeDTO } from "../../../../dtos/SctructuretypeDTO";
import { OptionsDTO } from "../../../../dtos/OptionsDTO";

export function useListGroup() {
  const [isLoadingGroup, setIsLoadingGroup] = useState(false);
  const [dataGroup, setDataGroup] = useState<SctructuretypeDTO[]>([]);
  const [dataOptionsGroup, setDataOptionsGroup] = useState<OptionsDTO[]>([]);

  const getListGroup = async (typePropert: string) => {
    try {
      setIsLoadingGroup(true);
      const response = await api.get("company/car/Sctructuretype/list");
      const typeStructData = response.data;
      setDataGroup(typeStructData);

      const newDataStruct: OptionsDTO[] = [];

      for (let index = 0; index < typeStructData.length; index++) {
        const element = typeStructData[index];
        const existGroup = newDataStruct.find(
          (item) => item.label === element.group_type
        );

        if (
          !existGroup &&
          typePropert === "fleet_vehicle" &&
          element.group_type !== "Comboio"
        ) {
          newDataStruct.push({
            label: element.group_type,
            value: element.id,
          });
        } else if (
          !existGroup &&
          typePropert === "tank_convoy" &&
          element.group_type === "Comboio"
        ) {
          newDataStruct.push({
            label: element.group_type,
            value: element.id,
          });
        }
      }

      setDataOptionsGroup(newDataStruct);
    } catch (error) {
      throw error;
    } finally {
      setIsLoadingGroup(false);
    }
  };

  return {
    isLoadingGroup,
    getListGroup,
    dataGroup,
    dataOptionsGroup,
  };
}
