import {
  Box,
  Button,
  Flex,
  Icon,
  SimpleGrid,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { HeaderCard } from "../../../../components/headerCard";
import { formatedMonthDay, clientTypePayment } from "../../../../utils/utils";
import { RiAlignJustify } from "react-icons/ri";
import { useListPartner } from "../hooks/useListPartner";
import { useEffect } from "react";
import { ItemStatus } from "../components/status";

export function ListPartner() {
  const { getPartner, data, isLoading } = useListPartner();

  useEffect(() => {
    getPartner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <HeaderCard isFilter>
        <Flex width="100%" justifyContent="space-between">
          <Text fontSize="22px" fontWeight="bold" color="white">
            PARCEIROS
          </Text>
        </Flex>
      </HeaderCard>

      {isLoading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={500}
        >
          <Spinner
            color="green.600"
            emptyColor="white"
            thickness="4px"
            size="xl"
            h={100}
            w={100}
          />
        </Box>
      )}

      <SimpleGrid
        flex="1"
        gap="4"
        minChildWidth="320px"
        alignItems="flex-start"
        px={1}
        py={1}
      >
        {data.length > 0 && !isLoading && (
          <Box p="4" borderRadius={8} height={500}>
            <TableContainer>
              <Table variant="striped" colorScheme="gray" size="sm">
                <Thead>
                  <Tr bg="green.600" height="10">
                    <Th color="white" textAlign="center">
                      STATUS
                    </Th>
                    <Th color="white" textAlign="center">
                      N DO CONTRATO
                    </Th>
                    <Th color="white" textAlign="center">
                      UG. GESTÃO
                    </Th>
                    <Th color="white" textAlign="center">
                      FORNECEDOR
                    </Th>
                    <Th color="white" textAlign="center">
                      FORMA DE PAGAMENTO
                    </Th>
                    <Th color="white" textAlign="center">
                      DATA ASSINATURA
                    </Th>
                    <Th color="white" textAlign="center">
                      AÇÕES
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((item, index) => (
                    <Tr key={index}>
                      <Td textAlign="center">
                        {ItemStatus({ status: item.status })}
                      </Td>
                      <Td textAlign="center">{item.contract_number}</Td>
                      <Td textAlign="center">{item.office_branch.name_ug}</Td>
                      <Td textAlign="center">{item.furnisher.fantasy_name}</Td>                   
                      <Td textAlign="center">{clientTypePayment(item.form_of_payment)}</Td>
                      <Td textAlign="center">
                        {formatedMonthDay(item.signature_data)}
                      </Td>
                      <Td textAlign="center" width="10">
                        <Button
                          as="a"
                          size="sm"
                          fontSize="sm"
                          href={`/parceiros/contrato/${item.id}`}
                        >
                          <Icon fontSize="16px" as={RiAlignJustify} />
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </SimpleGrid>
    </Box>
  );
}
