import { Flex, IconButton } from "@chakra-ui/react";
import { InputFilterSelect } from "../../../../../components/filter/inputFilterSelect";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { InputFilter } from "../../../../../components/filter/inputFilter";
import { RiCloseFill, RiFileExcel2Fill, RiFilterLine } from "react-icons/ri";
import { URL_WEB } from "../../../../../utils/utils";
import { useAuth } from "../../../../../hooks/useAuth";

type Props = {
  stock_id: string;
};

export function FilterMovimentation({ stock_id }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { user } = useAuth();

  const start_date = searchParams.get("start_date");
  const end_date = searchParams.get("end_date");
  const status_filter = searchParams.get("status");

  const [startDate, setStartDate] = useState(start_date ?? "");
  const [endDate, setEndDate] = useState(end_date ?? "");
  const [status, setStatus] = useState(status_filter ?? "");

  function handleFilter() {
    setSearchParams((state) => {
      if (startDate) {
        state.set("start_date", startDate);
      } else {
        state.delete("start_date");
      }

      if (endDate) {
        state.set("end_date", endDate);
      } else {
        state.delete("end_date");
      }

      if (status) {
        state.set("status", status);
      } else {
        state.delete("status");
      }

      return state;
    });
  }

  function handleClear() {
    setSearchParams((state) => {
      state.delete("start_date");
      setStartDate("");

      state.delete("end_date");
      setEndDate("");

      state.delete("status");
      setStatus("");
      return state;
    });

    window.location.reload();
  }

  return (
    <Flex gap="1">
      <InputFilterSelect
        label="STATUS:"
        name="request_type"
        options={[
          {
            label: "Entradas",
            value: "entry",
          },
          {
            label: "Saídas",
            value: "exit",
          },
        ]}
        value={{
          label:
            status === "exit"
              ? "Saídas"
              : status === "entry"
              ? "Entradas"
              : "Selecione...",
          value: status,
        }}
        width="200px"
        placeholder="Selecione..."
        onChange={(item) => {
          setStatus(item.value);
        }}
      />
      <InputFilter
        width="200px"
        type="date"
        label="DATA INÍCIO:"
        name="start_date"
        value={startDate}
        onChange={(e) => {
          setStartDate(e.target.value);
        }}
      />
      <InputFilter
        width="200px"
        type="date"
        label="DATA FINAL:"
        name="end_date"
        value={endDate}
        onChange={(e) => {
          setEndDate(e.target.value);
        }}
      />

      {(status || startDate || endDate) && (
        <>
          <IconButton
            ml={3}
            mt={7}
            aria-label="Filtro"
            icon={<RiFilterLine />}
            onClick={handleFilter}
          />
          <IconButton
            mt={7}
            aria-label="Limpar"
            colorScheme="red"
            variant="outline"
            onClick={handleClear}
            icon={<RiCloseFill />}
          />
          <IconButton
            mt={7}
            aria-label="Excel"
            colorScheme="teal"
            variant="outline"
            as="a"
            target="_blank"
            href={`${URL_WEB}/company/report/movementstock?user_id=${
              user.id
            }&status=${status ?? ""}&start_date=${start_date ?? ""}&end_date=${
              end_date ?? ""
            }&stock_id=${stock_id}`}
            icon={<RiFileExcel2Fill />}
          />
        </>
      )}
    </Flex>
  );
}
