import { useEffect, useState } from "react";
import { api } from "../../../../service/api";
import { TravelDTO } from "../../../../dtos/TravelDTO";

export function useListTravel() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataList, setDataList] = useState<TravelDTO[]>([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await api.get("company/trip/list");
      setDataList(response.data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    dataList,
    loading,
    error,
    refetch: fetchData,
  };
}
