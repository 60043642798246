import { Flex, Image, Text } from "@chakra-ui/react";

import LogoEtank from "../../assets/Logo-e-tank-grande.png";

export function Footer() {
  return (
    <Flex>
      <Image src={LogoEtank} height={10} alt="Etank" />
      <Flex direction="column" ml={5}>
        <Text color="green.600">Termos de uso | Política de privacidade</Text>
        <Text color="green.600">
          Copyright e-Tank 2024 - Todos os direitos reservados
        </Text>
      </Flex>
    </Flex>
  );
}
