import { useState } from "react";
import { api } from "../../../../service/api";
import { PartnerContractDTO } from "../../../../dtos/PartnerContractDTO";

export function useShowPartner() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<PartnerContractDTO>(
    {} as PartnerContractDTO
  );

  const showPartner = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await api.get("/company/partner/show/" + id);
      setData(response.data);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, showPartner, data };
}
