import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { HeaderCard } from "../../../../components/headerCard";
import { RiArrowLeftSLine } from "react-icons/ri";
import { InputSelect } from "../../../../components/form/inputSelect";
import { InputLine } from "../../../../components/form/InputLine";
import { useState } from "react";
import { InputLineMask } from "../../../../components/form/inputLineMask";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../service/api";
import { AllLoading } from "../../../../components/allLoading";
import { CnpjDTO } from "../../../../dtos/CnpjDTO";

const formatRegister = {
  cnpj: "99.999.999/9999-99",
  cpf: "999.999.999-99",
};

type createFormData = {
  type_registry: string;
  registration_number: string;
  name: string;
  contract_number: string;
  zip_code?: string;
  public_place?: string;
  neighborhood?: string;
  complement?: string;
  city?: string;
  state?: string;
  phone?: string;
  email?: string;
};

const createFormDataSchema = yup.object().shape({
  type_registry: yup.string().required("Campo obrigatório."),
  registration_number: yup.string().required("Campo obrigatório."),
  name: yup.string().required("Campo obrigatório."),
  contract_number: yup.string().required("Campo obrigatório."),
  zip_code: yup.string(),
  public_place: yup.string(),
  neighborhood: yup.string(),
  complement: yup.string(),
  district: yup.string(),
  city: yup.string(),
  state: yup.string(),
  phone: yup.string(),
  email: yup.string(),
});

export function CreateLocator() {
  const toast = useToast();
  const navigate = useNavigate();

  const [isCnpj, setIsCnpj] = useState(true);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createFormDataSchema) });

  async function getCompany(cnpj: string) {
    try {
      if (cnpj.length < 18 || !isCnpj) return;
      setLoading(true);
      const response = await api.post("company/cnpj/index", { cnpj });
      const data: CnpjDTO = response.data;

      setValue("name", data.company_name);
      setValue("zip_code", data.cep);
      setValue("public_place", data.public_place);
      setValue("neighborhood", data.neighborhood);
      setValue("city", data.city);
      setValue("state", data.state);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  const handleSend: SubmitHandler<createFormData> = async (value) => {
    try {
      await api.post("company/locator/create", value);

      toast({
        position: "top-right",
        title: "Motorista",
        description: "Cadastro realizado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      navigate("/locadores");
    } catch (error) {
      toast({
        position: "top-right",
        title: "Motorista",
        description: "Não foi possível cadastrar.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <HeaderCard
        title="CADASTRO DO LOCADOR"
        titleButton="Voltar"
        icon={RiArrowLeftSLine}
        link="/locadores"
      />

      {loading && <AllLoading />}

      <Flex justifyContent="center" width="100%">
        <Box>
          <Flex
            px={10}
            py={5}
            width="100%"
            flexDirection="column"
            as="form"
            onSubmit={handleSubmit(handleSend)}
          >
            <Flex flex={1} alignItems="center" justifyContent="space-between">
              <Box
                p="4"
                bg="green.50"
                minHeight={370}
                borderRadius={8}
                width="879px"
              >
                <Text fontWeight="bold" mb="4" color="green.800">
                  INFORMAÇÕES DO LOCADOR:
                </Text>

                <Flex gap="2">
                  <Box width="auto">
                    <Controller
                      control={control}
                      name="type_registry"
                      render={({ field: { onChange, value } }) => (
                        <InputSelect
                          width="200px"
                          label="TIPO DE REGISTRO:"
                          name="type_registry"
                          placeholder="Selecione o tipo de registro"
                          options={[
                            {
                              label: "CNPJ",
                              value: "CNPJ",
                            },
                            {
                              label: "CPF",
                              value: "CPF",
                            },
                          ]}
                          onChange={(item) => {
                            onChange(item.value);
                            if (item.value === "CNPJ") {
                              setIsCnpj(true);
                            } else {
                              setIsCnpj(false);
                            }
                          }}
                          error={errors.type_registry}
                        />
                      )}
                    />
                  </Box>

                  <InputLineMask
                    mask={isCnpj ? formatRegister.cnpj : formatRegister.cpf}
                    autoComplete="off"
                    width="200px"
                    label="N DO REGISTRO"
                    placeholder="Informe o numero do registro"
                    {...register("registration_number")}
                    error={errors.registration_number}
                    onChange={(e) => {
                      getCompany(e.target.value);
                    }}
                  />

                  <InputLine
                    autoComplete="off"
                    width="430px"
                    label="NOME"
                    placeholder="Informe o nome"
                    {...register("name")}
                    error={errors.name}
                  />
                </Flex>

                <Flex gap="2" mt={5}>
                  <InputLine
                    autoComplete="off"
                    width="200px"
                    label="NUMERO CONTRATO"
                    placeholder="Informe o numero do contrato"
                    {...register("contract_number")}
                    error={errors.contract_number}
                  />

                  <InputLine
                    autoComplete="off"
                    width="200px"
                    label="CEP"
                    placeholder="Informe o cep"
                    {...register("zip_code")}
                    error={errors.zip_code}
                  />

                  <InputLine
                    autoComplete="off"
                    width="210px"
                    label="LONGRADOURO"
                    placeholder="Informe o logradouro"
                    {...register("public_place")}
                    error={errors.public_place}
                  />
                  <InputLine
                    autoComplete="off"
                    width="210px"
                    label="BAIRRO"
                    placeholder="Informe o bairro"
                    {...register("neighborhood")}
                    error={errors.neighborhood}
                  />
                </Flex>

                <Flex gap="2" mt={5}>
                  <InputLine
                    autoComplete="off"
                    width="200px"
                    label="COMPLEMENTO"
                    placeholder="Informe o complemento"
                    {...register("complement")}
                    error={errors.complement}
                  />

                  <InputLine
                    autoComplete="off"
                    width="200px"
                    label="MUNICÍPIO"
                    placeholder="Informe o município"
                    {...register("city")}
                    error={errors.city}
                  />

                  <InputLine
                    autoComplete="off"
                    width="210px"
                    label="ESTADO"
                    placeholder="Informe o estado"
                    {...register("state")}
                    error={errors.state}
                  />
                  <InputLineMask
                    mask="(99)99999-9999"
                    autoComplete="off"
                    width="210px"
                    label="TELEFONE"
                    placeholder="Informe o telefone"
                    {...register("phone")}
                    error={errors.phone}
                  />
                </Flex>

                <Flex mt={5} gap="2">
                  <InputLine
                    autoComplete="off"
                    width="410px"
                    label="EMAIL"
                    placeholder="Informe o email"
                    {...register("email")}
                    error={errors.email}
                  />
                  <InputLine
                    autoComplete="off"
                    width="430px"
                    name="description"
                    label="OBSERVAÇÕES"
                    placeholder="Informe a observações"
                  />
                </Flex>
              </Box>
            </Flex>

            <Button
              type="submit"
              bg="green.600"
              color="white"
              alignSelf="flex-end"
              height={10}
              _hover={{
                bg: "green.800",
              }}
              mt="6"
              size="lg"
              isLoading={isSubmitting}
            >
              Salvar
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}
