import {
  FormControl,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormErrorMessage,
  Flex,
} from "@chakra-ui/react";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { FieldError } from "react-hook-form";

interface Props extends ChakraInputProps {
  name: string;
  label?: string;
  size?: "lg" | "md" | "sm";
  error?: FieldError;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { name, label, size = "lg", error, ...rest },
  ref
) => {
  return (
    <FormControl isInvalid={!!error}>
      <Flex flexDirection="column">
        <FormLabel
          color="gray.800"
          fontSize="sm"
          fontWeight="bold"
          htmlFor={name}
        >
          CÓDIGO DO ADESIVO:
        </FormLabel>

        <ChakraInput
          autoComplete="off"
          {...rest}
          id={name}
          name={name}
          borderColor="green.600"
          borderWidth={1}
          mt={-2}
          focusBorderColor="green.800"
          bgColor="green.600"
          variant="filled"
          color="white"
          size={size}
          _hover={{
            bgColor: "green.600",
          }}
          _focus={{
            bgColor: "green.600",
          }}
          ref={ref}
        />
      </Flex>
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export const BoxInputCode = forwardRef(InputBase);
