import { useState } from "react";
import { api } from "./api";
import { InvoceDTO } from "../dtos/InvoceDTO";

export function useInvoce() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<InvoceDTO[]>([]);

  const getInvoce = async () => {
    try {
      setIsLoading(true);
      const response = await api.get("company/invoice/list");
      setData(response.data);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, getInvoce, data };
}
