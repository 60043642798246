import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { HeaderCard } from "../../../../components/headerCard";
import { RiArrowLeftSLine } from "react-icons/ri";
import { InputLine } from "../../../../components/form/InputLine";
import { InputSelect } from "../../../../components/form/inputSelect";
import { OfficeSelect } from "../../../../components/officeSelect";
import { DataMotive } from "../data/data-motive";
import { useEffect, useState } from "react";
import { useCarService } from "../../../../service/CarService";
import { useListUserApprover } from "../hooks/useListUserApprover";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../service/api";

type createFormData = {
  office_branch_id: string;
  office_branch_destination_id?: string;
  car_fleet_id: string;
  date_of_movement: string;
  end_odometer: string;
  operation: string;
  motive_movement: string;
  user_id: string;
  fleet_name?: string;
  mark?: string;
  model?: string;
  patrimony_number?: string;
  plaque?: string;
  observation?: string;
};

const createFormDataSchema = yup.object().shape({
  office_branch_id: yup.string().required("Campo obrigatório."),
  office_branch_destination_id: yup.string(),
  car_fleet_id: yup.string().required("Campo obrigatório."),
  date_of_movement: yup.string().required("Campo obrigatório."),
  end_odometer: yup.string().required("Campo obrigatório."),
  operation: yup.string().required("Campo obrigatório."),
  motive_movement: yup.string().required("Campo obrigatório."),
  user_id: yup.string().required("Campo obrigatório."),
  fleet_name: yup.string(),
  mark: yup.string(),
  model: yup.string(),
  patrimony_number: yup.string(),
  plaque: yup.string(),
  observation: yup.string(),
});

export function CreateFleetMovement() {
  const { getCarService, dataOptionsCar, dataCar, isLoadingCar } =
    useCarService();

  const { getListUserApprover, dataOptionsUser, isLoadingUser } =
    useListUserApprover();

  const [selectUgId, setSelectUgId] = useState("");
  const [operationName, setOperationName] = useState("");
  const [selectCar, setSelectCar] = useState();

  const toast = useToast();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createFormDataSchema) });

  const handleSend: SubmitHandler<createFormData> = async (value) => {
    try {
      await api.post("company/frota/movement/create", value);

      toast({
        position: "top-right",
        title: "Movimentação",
        description: "Cadastro realizado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      navigate("/movimentacao-frota");
    } catch (error) {
      toast({
        position: "top-right",
        title: "Movimentação",
        description: "Não foi possível cadastrar.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getCarService(selectUgId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectUgId]);

  useEffect(() => {
    getListUserApprover();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectCar) {
      const infoCar = dataCar.find((it) => it.id === selectCar);
      console.log(infoCar);
      if (infoCar) {
        setValue("fleet_name", infoCar.structure_type.type);
        setValue("mark", infoCar.mark);
        setValue("model", infoCar.model);
        setValue("plaque", infoCar.plaque);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCar]);

  return (
    <Box>
      <HeaderCard
        title="MOVIMENTAÇÃO DA FROTA"
        titleButton="Voltar"
        icon={RiArrowLeftSLine}
        link="/movimentacao-frota"
      />

      <Flex justifyContent="center" width="100%">
        <Box>
          <Flex
            px={10}
            py={5}
            width="100%"
            flexDirection="column"
            as="form"
            onSubmit={handleSubmit(handleSend)}
          >
            <Flex flex={1} alignItems="center" justifyContent="space-between">
              <Box
                p="4"
                bg="green.50"
                minHeight={370}
                borderRadius={8}
                width="879px"
              >
                <Text fontWeight="bold" mb="4" color="green.800">
                  INFORMAÇÕES DA MOVIMENTAÇÃO:
                </Text>

                <Flex gap="2">
                  <Box width="auto">
                    <Controller
                      control={control}
                      name="operation"
                      render={({ field: { onChange, value } }) => (
                        <InputSelect
                          width="200px"
                          label="OPERAÇÃO"
                          name="type_registry"
                          placeholder="Selecione o tipo de registro"
                          options={[
                            {
                              label: "Baixa",
                              value: "Baixa",
                            },
                            {
                              label: "Transferência",
                              value: "Transferência",
                            },
                          ]}
                          onChange={(item) => {
                            setOperationName(item.value);
                            onChange(item.value);
                          }}
                          error={errors.operation}
                        />
                      )}
                    />
                  </Box>

                  <InputLine
                    type="date"
                    autoComplete="off"
                    width="200px"
                    label="DATA DA MOVIMENTAÇÃO"
                    {...register("date_of_movement")}
                    error={errors.date_of_movement}
                  />

                  <Controller
                    control={control}
                    name="motive_movement"
                    render={({ field: { onChange, value } }) => (
                      <InputSelect
                        width="430px"
                        label="MOTIVO DA MOVIMENTAÇÃO"
                        name="motive_movement"
                        placeholder="Selecione o motivo da movimentação"
                        options={
                          operationName === "Transferência"
                            ? DataMotive.transfer
                            : DataMotive.low
                        }
                        onChange={(item) => {
                          onChange(item.value);
                        }}
                        error={errors.motive_movement}
                      />
                    )}
                  />
                </Flex>

                <Flex gap="2" mt={5}>
                  <Controller
                    control={control}
                    name="office_branch_id"
                    render={({ field: { onChange, value } }) => (
                      <OfficeSelect
                        width="200px"
                        label="UG DE ORIGEM"
                        name="office_branch_id"
                        onChange={(item) => {
                          setSelectUgId(item.value);
                          onChange(item.value);
                        }}
                        error={errors.office_branch_id}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="office_branch_destination_id"
                    render={({ field: { onChange, value } }) => (
                      <OfficeSelect
                        width="200px"
                        label="UG DE DESTINO"
                        name="office_branch_destination_id"
                        isTransfer
                        id={selectUgId}
                        onChange={(item) => {
                          onChange(item.value);
                        }}
                        error={errors.office_branch_destination_id}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="car_fleet_id"
                    render={({ field: { onChange, value } }) => (
                      <InputSelect
                        width="210px"
                        label="FROTA"
                        name="car_fleet_id"
                        placeholder="Selecione.."
                        options={dataOptionsCar}
                        isLoading={isLoadingCar}
                        onChange={(item) => {
                          onChange(item.value);
                          setSelectCar(item.value);
                        }}
                        error={errors.car_fleet_id}
                      />
                    )}
                  />

                  <InputLine
                    autoComplete="off"
                    width="210px"
                    label="MARCA"
                    isDisabled
                    placeholder="Informe a marca"
                    {...register("mark")}
                    error={errors.mark}
                  />
                </Flex>

                <Flex gap="2" mt={5}>
                  <InputLine
                    autoComplete="off"
                    width="200px"
                    label="MODELO"
                    isDisabled
                    placeholder="Informe o modelo"
                    {...register("model")}
                    error={errors.model}
                  />

                  <InputLine
                    type="number"
                    autoComplete="off"
                    width="200px"
                    label="ODÔMETRO"
                    placeholder="Informe o odômetro atual"
                    {...register("end_odometer")}
                    error={errors.end_odometer}
                  />

                  <InputLine
                    autoComplete="off"
                    width="210px"
                    label="N PATRIMONIO"
                    isDisabled
                    placeholder="Informe o numero patrimonio"
                    {...register("patrimony_number")}
                    error={errors.patrimony_number}
                  />
                  <InputLine
                    autoComplete="off"
                    width="210px"
                    label="PLACA"
                    isDisabled
                    placeholder="Informe a placa"
                    {...register("plaque")}
                    error={errors.plaque}
                  />
                </Flex>

                <Flex mt={5} gap="2">
                  <Controller
                    control={control}
                    name="user_id"
                    render={({ field: { onChange, value } }) => (
                      <InputSelect
                        width="410px"
                        label="USUÁRIO APROVADOR"
                        name="user_id"
                        placeholder="Selecione.."
                        options={dataOptionsUser}
                        isLoading={isLoadingUser}
                        onChange={(item) => {
                          onChange(item.value);
                        }}
                        error={errors.user_id}
                      />
                    )}
                  />

                  <InputLine
                    autoComplete="off"
                    width="430px"
                    label="OBSERVAÇÕES"
                    placeholder="observações..."
                    {...register("observation")}
                    error={errors.observation}
                  />
                </Flex>
              </Box>
            </Flex>

            <Button
              type="submit"
              bg="green.600"
              color="white"
              alignSelf="flex-end"
              height={10}
              _hover={{
                bg: "green.800",
              }}
              mt="6"
              size="lg"
              isLoading={isSubmitting}
            >
              Salvar
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}
