import { useState } from "react";
import { api } from "../../../../service/api";

export function useCancelTravel() {
  const [loadingCancel, setLoadingCancel] = useState(false);

  const cancelTravel = async (id: string) => {
    try {
      setLoadingCancel(true);
      await api.put("company/trip/cancel/" + id);
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      setLoadingCancel(false);
    }
  };

  return { loadingCancel, cancelTravel };
}
