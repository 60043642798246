import { useState } from "react";
import { api } from "../../../../service/api";
import { SctructuretypeDTO } from "../../../../dtos/SctructuretypeDTO";
import { OptionsDTO } from "../../../../dtos/OptionsDTO";

export function useLiStstructureType() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<SctructuretypeDTO[]>([]);
  const [dataType, setDataType] = useState<SctructuretypeDTO[]>([]);
  const [dataOptions, setDataOptions] = useState<OptionsDTO[]>([]);
  const [dataOptionsType, setDataOptionsType] = useState<OptionsDTO[]>([]);

  const getLiStstructureGroup = async () => {
    try {
      setIsLoading(true);
      const response = await api.get("company/car/Sctructuretype/list");
      const typeStructData = response.data;
      setData(typeStructData);

      const newDataStruct: OptionsDTO[] = [];

      for (let index = 0; index < typeStructData.length; index++) {
        const element = typeStructData[index];
        const existGroup = newDataStruct.find(
          (item) => item.label === element.group_type
        );

        if (!existGroup) {
          newDataStruct.push({
            label: element.group_type,
            value: element.id,
          });
        }
      }

      setDataOptions(newDataStruct);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const getLiStstructureType = (
    groupName: string,
    groupData: SctructuretypeDTO[]
  ) => {
    setDataOptionsType([]);

    const result = groupData.filter((item) => item.group_type === groupName);

    const newDataStruct: OptionsDTO[] = [];

    for (let index = 0; index < result.length; index++) {
      const element = result[index];
      newDataStruct.push({
        label: element.type,
        value: element.id,
      });
    }

    setDataOptionsType(newDataStruct);
    setDataType(result);
  };

  return {
    isLoading,
    getLiStstructureGroup,
    getLiStstructureType,
    data,
    dataType,
    dataOptions,
    dataOptionsType,
  };
}
