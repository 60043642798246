import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  useToast,
} from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { Input } from "../../../../../components/form/input";
import { useEffect } from "react";
import { InputSelectBox } from "../../../../../components/form/inputSelectBox";
import { CarDTO } from "../../../../../dtos/CarDTO";
import { api } from "../../../../../service/api";
import { useListUserApprover } from "../../../fleetMovement/hooks/useListUserApprover";

type createFormData = {
  car_fleet_id: string;
  type_review: string;
  expected_date: string;
  expected_odometer: string;
  company_name: string;
  mechanic_name: string;
  email_user: string;
  observation?: string;
};

const createFormDataSchema = yup.object().shape({
  car_fleet_id: yup.string().required("Campo obrigatório."),
  type_review: yup.string().required("Campo obrigatório."),
  expected_date: yup.string().required("Campo obrigatório."),
  expected_odometer: yup.string().required("Campo obrigatório."),
  company_name: yup.string().required("Campo obrigatório."),
  mechanic_name: yup.string().required("Campo obrigatório."),
  email_user: yup.string().required("Campo obrigatório."),
  observation: yup.string(),
});

type Props = {
  carData: CarDTO;
  is_open: boolean;
  onClose: () => void;
  onCloseSend: () => void;
};
export function ModalCreateReview({
  is_open,
  onClose,
  onCloseSend,
  carData,
  ...rest
}: Props) {
  const toast = useToast();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createFormDataSchema) });

  const { getListUserApprover, dataOptionsUser, isLoadingUser } =
    useListUserApprover();

  useEffect(() => {
    if (is_open) {
      setValue("car_fleet_id", carData.id);
      setValue("company_name", "");
      setValue("expected_date", "");
      setValue("expected_odometer", "");
      setValue("mechanic_name", "");
      setValue("observation", "");
      setValue("type_review", "");
      setValue("email_user", "");
      getListUserApprover();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_open, carData]);

  const handleSend: SubmitHandler<createFormData> = async (value) => {
    try {
      const data = {
        ...value,
      };

      await api.post("company/maintenance/create", data);

      toast({
        position: "top-right",
        title: "Revisão programada",
        description: "Revisão adicionado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onCloseSend();
    } catch (error) {
      let errorMessas = error as any;
      toast({
        position: "top-right",
        title: "Revisão programada",
        description: errorMessas.response.data.error
          ? errorMessas.response.data.error
          : "Não foi possível adicionar revisão.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Modal
        motionPreset="slideInBottom"
        isOpen={is_open}
        onClose={onClose}
        isCentered
        {...rest}
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent display="flex" flexDir="column">
          <ModalHeader textAlign="center">NOVA REVISÃO</ModalHeader>
          <ModalCloseButton />
          <ModalBody px={10} pb={10}>
            <Stack
              divider={<StackDivider />}
              spacing="4"
              as="form"
              onSubmit={handleSubmit(handleSend)}
            >
              <Flex gap="2" flexDirection="row">
                <Input
                  label="CÓDIGO FROTA"
                  isDisabled
                  size="md"
                  name="end_odometer"
                  width="100%"
                  placeholder="Código da frota"
                  value={carData.code_car_fleet}
                />

                <Input
                  label="PLACA"
                  isDisabled
                  size="md"
                  name="end_odometer"
                  width="100%"
                  placeholder="Placa"
                  value={carData.plaque}
                />
                <Input
                  label="MODELO"
                  isDisabled
                  size="md"
                  name="end_odometer"
                  width="100%"
                  placeholder="modelo"
                  value={carData.model}
                />
              </Flex>

              <Flex gap="2" flexDirection="row">
                <Controller
                  control={control}
                  name="type_review"
                  render={({ field: { onChange, value } }) => (
                    <InputSelectBox
                      label="TIPO DE REVISÃO"
                      name="type_review"
                      options={[
                        {
                          label: "Revisão de garantia",
                          value: "Revisão de garantia",
                        },
                        {
                          label: "Revisão preventiva",
                          value: "Revisão preventiva",
                        },
                      ]}
                      width="100%"
                      placeholder="Selecione..."
                      onChange={(item) => {
                        onChange(item.value);
                      }}
                      error={errors.type_review}
                    />
                  )}
                />

                <Input
                  label="DATA PREVISTA"
                  size="md"
                  width="100%"
                  type="date"
                  {...register("expected_date")}
                  error={errors.expected_date}
                />
                <Input
                  label="ODÔMETRO PREVISTO"
                  type="number"
                  textAlign="center"
                  size="md"
                  width="100%"
                  placeholder="Informe o odômetro previsto"
                  {...register("expected_odometer")}
                  error={errors.expected_odometer}
                />
              </Flex>

              <Flex gap="2" flexDirection="row">
                <Input
                  label="NOME DA OFICINA"
                  size="md"
                  placeholder="Informe o nome da oficina"
                  {...register("company_name")}
                  error={errors.company_name}
                />

                <Controller
                  control={control}
                  name="email_user"
                  render={({ field: { onChange, value } }) => (
                    <InputSelectBox
                      label="USUÁRIO"
                      name="email_user"
                      options={dataOptionsUser}
                      isLoading={isLoadingUser}
                      width="100%"
                      placeholder="Selecione..."
                      onChange={(item) => {
                        onChange(item.value);
                      }}
                      error={errors.email_user}
                    />
                  )}
                />

                <Input
                  label="MECÂNICO"
                  size="md"
                  placeholder="Informe o nome do mecánico"
                  {...register("mechanic_name")}
                  error={errors.mechanic_name}
                />
              </Flex>

              <Flex gap="2" flexDirection="column">
                <Input
                  label="OBSERVAÇÕES"
                  size="md"
                  width="100%"
                  placeholder="Observações.."
                  {...register("observation")}
                  error={errors.observation}
                />
              </Flex>

              <Button
                width="170px"
                type="submit"
                bg="green.600"
                color="white"
                alignSelf="flex-end"
                height={10}
                _hover={{
                  bg: "green.800",
                }}
                mt="6"
                size="lg"
                isLoading={isSubmitting}
              >
                Salvar
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
