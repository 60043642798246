import {
  Badge,
  Box,
  Flex,
  Icon,
  SimpleGrid,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { HeaderCard } from "../../../../components/headerCard";
import { CardInfoData } from "../../../../components/cardInfoData";
import {
  RiArrowLeftSLine,
  RiCarFill,
  RiRepeatOneLine,
  RiSquareLine,
} from "react-icons/ri";
import {
  formatCurrency,
  formatedDateTimer,
  lmWord,
  typeOperagions,
} from "../../../../utils/utils";
import { useParams } from "react-router-dom";
import { useShowMileage } from "../hooks/useShowMileage";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { FilterDatailMileage } from "../filterDatail";

export function MileageDetail() {
  let { id } = useParams();

  const { dataList, loading } = useShowMileage(id as string);

  return (
    <Box>
      <HeaderCard
        title="DETALHES DOS ABASTECIMENTOS"
        titleButton="Voltar"
        icon={RiArrowLeftSLine}
        link="/quilometragem-da-frota"
      />

      {loading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={500}
        >
          <Spinner
            color="green.600"
            emptyColor="white"
            thickness="4px"
            size="xl"
            h={100}
            w={100}
          />
        </Box>
      )}

      {!loading && dataList.id && (
        <>
          <Flex mt={5} px={5} alignItems="center" gap={2}>
            <CardInfoData
              icon={<Icon fontSize="25px" as={RiCarFill} />}
              title="Marca"
              subTitle={lmWord(dataList.mark ?? "", 35)}
              isWdAll
            />
            <CardInfoData
              icon={<Icon fontSize="25px" as={RiCarFill} />}
              title="Modelo"
              subTitle={lmWord(dataList.model ?? "", 35)}
              isWdAll
            />

            <CardInfoData
              icon={<Icon fontSize="25px" as={RiRepeatOneLine} />}
              title="Código"
              subTitle={dataList.code_car_fleet}
              isWdAll
            />

            <CardInfoData
              icon={<Icon fontSize="25px" as={RiSquareLine} />}
              title="Placa"
              subTitle={dataList.plaque}
              isWdAll
            />
          </Flex>
          <Flex mt={5} px={5} alignItems="center" gap={2}>
            <CardInfoData
              icon={<Icon fontSize="25px" as={RiRepeatOneLine} />}
              title="Volume Abastecimento"
              subTitle={formatCurrency(
                dataList.historicResume.quantity
              ).toString()}
              isWdAll
            />
            <CardInfoData
              icon={<Icon fontSize="25px" as={RiRepeatOneLine} />}
              title="KM"
              subTitle={formatCurrency(dataList.historicResume.km).toString()}
              isWdAll
            />

            <CardInfoData
              icon={<Icon fontSize="25px" as={RiRepeatOneLine} />}
              title="Unidade De Consumo"
              subTitle={dataList.consumer_unit.toUpperCase()}
              isWdAll
            />

            {/* <CardInfoData
              icon={<Icon fontSize="25px" as={RiSquareLine} />}
              title="Placa"
              subTitle={dataList.plaque}
              isWdAll
            /> */}
            <Box width="100%" height="100px">
              <ReactApexChart
                options={getOptions()}
                series={
                  dataSeries(
                    dataList.indicationCons.consumer_pattern,
                    dataList.indicationCons.consumer_realized
                  ).series
                }
                type="bar"
                height={100}
              />
            </Box>
          </Flex>
        </>
      )}

      <Flex px={5}>
        {!loading && <FilterDatailMileage fleetId={id as string} />}
      </Flex>

      <SimpleGrid
        flex="1"
        gap="4"
        minChildWidth="320px"
        alignItems="flex-start"
        px={1}
        py={1}
      >
        {!loading &&
          dataList.requisitions &&
          dataList.requisitions.length > 0 && (
            <Box p="4" borderRadius={8} height={500} overflow="scroll">
              <TableContainer>
                <Table variant="striped" colorScheme="gray" size="sm">
                  <Thead>
                    <Tr bg="green.600" height="10">
                      <Th color="white" textAlign="center">
                        STATUS
                      </Th>
                      <Th color="white" textAlign="center">
                        DATA
                      </Th>
                      <Th color="white" textAlign="center">
                        N REQ
                      </Th>
                      <Th color="white" textAlign="center">
                        CÓDIGO
                      </Th>
                      <Th color="white" textAlign="center">
                        PLACA
                      </Th>
                      <Th color="white" textAlign="center">
                        MARCA
                      </Th>
                      <Th color="white" textAlign="center">
                        MODELO
                      </Th>
                      <Th color="white" textAlign="center">
                        PROCESSO
                      </Th>
                      <Th color="white" textAlign="center">
                        ODM
                      </Th>
                      <Th color="white" textAlign="center">
                        PRODUTO
                      </Th>
                      <Th color="white" textAlign="center">
                        QTD
                      </Th>
                      <Th color="white" textAlign="center">
                        VALOR
                      </Th>
                      <Th color="white" textAlign="center">
                        TOTAL
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {dataList.requisitions.map((item, index) => (
                      <Tr key={index}>
                        <Td textAlign="center">
                          {statusRquisition(item.status)}
                        </Td>
                        <Td textAlign="center">
                          {formatedDateTimer(item.end_date, true)}
                        </Td>
                        <Td textAlign="center">{item.code_requisition}</Td>
                        <Td textAlign="center">{item.fleet_code}</Td>
                        <Td textAlign="center">{item.plaque}</Td>
                        <Td textAlign="center">{lmWord(item.mark, 10)}</Td>
                        <Td textAlign="center">{lmWord(item.model, 10)}</Td>
                        <Td textAlign="center">
                          {typeOperagions(item.operation)}
                        </Td>
                        <Td textAlign="center">{item.end_odometer}</Td>
                        <Td textAlign="center">{item.product_name}</Td>
                        <Td textAlign="center" color="blue">
                          {item.quantity_solicit > 0
                            ? formatCurrency(item.quantity_solicit)
                            : formatCurrency(item.quantity_solicit)}
                        </Td>
                        <Td textAlign="center" color="green">
                          {formatCurrency(item.unitary_price)}
                        </Td>
                        <Td textAlign="center" color="green">
                          {formatCurrency(item.total_price)}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          )}
      </SimpleGrid>
    </Box>
  );
}

function statusRquisition(status: string) {
  if (status === "finished") {
    return <Badge colorScheme="green">Finalizada</Badge>;
  }

  if (status === "canceled") {
    return <Badge colorScheme="red">Cancelada</Badge>;
  }

  if (status === "progress") {
    return <Badge>Andamento</Badge>;
  }
}

function dataSeries(consumerpattern: number, consumerPealized: number) {
  const data = {
    series: [
      {
        name: "Consumo",
        data: [consumerpattern, consumerPealized],
      },
    ],
  };

  return data;
}

function getOptions() {
  const options: ApexOptions = {
    chart: {
      height: 100,
      type: "bar",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ["#69B0B9"],
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return (formatCurrency(Number(val)) as string) + "" ?? "0";
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },

    xaxis: {
      categories: ["Padrão", "Realizado"],
      position: "bottom",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return (formatCurrency(Number(val)) as string) ?? "0";
        },
      },
    },
  };
  return options;
}
