export const DataMotive = {
  low: [
    { label: "Busca e apreenção", value: "Busca e apreenção" },
    { label: "Cancelamento de contrato", value: "Cancelamento de contrato" },
    { label: "Desmobilização de obra", value: "Desmobilização de obra" },
    { label: "Depreciação de patrimônio", value: "Depreciação de patrimônio" },
    { label: "Distrato contratual", value: "Distrato contratual" },
    { label: "Resgate de seguradora", value: "Resgate de seguradora" },
    { label: "Roubo/furto", value: "Roubo/furto" },
    { label: "Termino de contrato", value: "Termino de contrato" },
    { label: "Venda de patrimônio", value: "Venda de patrimônio" },
  ],
  transfer: [
    { label: "Abertura de obra", value: "Abertura de obra" },
    { label: "Empréstimo temporário", value: "Empréstimo temporário" },
    { label: "Transferência entre UGs", value: "Transferência entre UGs" },
    { label: "Desmobilização de obra", value: "Desmobilização de obra" },
    { label: "Mobilização de obra", value: "Mobilização de obra" },
  ],
};
