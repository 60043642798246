import { useState } from "react";
import { api } from "../../../../service/api";

export function useDeleteCar() {
  const [loadingDelete, setLoadingDelete] = useState(false);

  const deleteCar = async (id: string) => {
    try {
      setLoadingDelete(true);
      await api.delete("company/car/delete/" + id);
    } catch (error) {
      throw error;
    } finally {
      setLoadingDelete(false);
    }
  };

  return { loadingDelete, deleteCar };
}
