import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { HeaderCard } from "../../../../components/headerCard";
import { RiArrowLeftSLine } from "react-icons/ri";
import { InputLine } from "../../../../components/form/InputLine";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../../../service/api";
import { InputLineMask } from "../../../../components/form/inputLineMask";
import { useEffect, useState } from "react";
import { CnpjDTO } from "../../../../dtos/CnpjDTO";
import { AllLoading } from "../../../../components/allLoading";
import { useShowOffice } from "../hooks/useShowOffice";

type createFormData = {
  cnpj: string;
  company_name: string;
  cost_center_number: string;
  name_ug: string;
  responsible: string;
  zipe_code: string;
  address: string;
  number: string;
  district: string;
  complement?: string;
  city: string;
  state: string;
  phone: string;
  cell_phone: string;
};

const createFormDataSchema = yup.object().shape({
  cnpj: yup.string().required("Campo obrigatório."),
  company_name: yup.string().required("Campo obrigatório."),
  cost_center_number: yup.string().required("Campo obrigatório."),
  name_ug: yup.string().required("Campo obrigatório."),
  responsible: yup.string().required("Campo obrigatório."),
  zipe_code: yup.string().required("Campo obrigatório."),
  address: yup.string().required("Campo obrigatório."),
  number: yup.string().required("Campo obrigatório."),
  district: yup.string().required("Campo obrigatório."),
  city: yup.string().required("Campo obrigatório."),
  state: yup.string().required("Campo obrigatório."),
  phone: yup.string().required("Campo obrigatório."),
  cell_phone: yup.string().required("Campo obrigatório."),
});

export function EditOffice() {
  const toast = useToast();
  const navigate = useNavigate();

  let { id } = useParams();

  const [loading, setLoading] = useState(false);

  const { findOffice, data: dataOffice, isLoading } = useShowOffice();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createFormDataSchema) });

  async function getCompany(cnpj: string) {
    try {
      if (cnpj.length < 18) return;
      setLoading(true);
      const response = await api.post("company/cnpj/index", { cnpj });
      const data: CnpjDTO = response.data;

      setValue("company_name", data.company_name);
      setValue("zipe_code", data.cep);
      setValue("address", data.public_place);
      setValue("district", data.neighborhood);
      setValue("city", data.city + "/" + data.state);
      setValue("state", data.state);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  const handleSend: SubmitHandler<createFormData> = async (value) => {
    try {
      const newValues = {
        ...value,
        city: value.city.split("/")[0],
      };

      await api.put("company/office/update/" + id, newValues);

      toast({
        position: "top-right",
        title: "Criar conta",
        description: "Cadastro atualizado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      navigate("/unidade-de-gestao");
    } catch (error) {
      toast({
        position: "top-right",
        title: "Criar conta",
        description: "Não foi possível atualizar.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  function setDataAll() {
    setValue("cnpj", dataOffice.cnpj ?? "");
    setValue("company_name", dataOffice.company_name ?? "");
    setValue("cost_center_number", dataOffice.cost_center_number ?? "");
    setValue("name_ug", dataOffice.name_ug ?? "");
    setValue("responsible", dataOffice.responsible ?? "");
    setValue("zipe_code", dataOffice.zipe_code ?? "");
    setValue("address", dataOffice.address ?? "");
    setValue("number", dataOffice.number ?? "");
    setValue("district", dataOffice.district ?? "");
    if (dataOffice.city && dataOffice.state) {
      setValue("city", dataOffice.city);
      setValue("state", dataOffice.state);
    }
    setValue("phone", dataOffice.phone ?? "");
    setValue("cell_phone", dataOffice.cell_phone ?? "");
  }

  useEffect(() => {
    if (dataOffice.id) {
      setDataAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataOffice]);

  useEffect(() => {
    findOffice(id as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Box>
      <HeaderCard
        title="UNIDADES DE GESTÃO"
        titleButton="Voltar"
        icon={RiArrowLeftSLine}
        link="/unidade-de-gestao"
      />

      {(loading || isLoading) && <AllLoading />}

      <Flex
        as="form"
        px={10}
        py={5}
        direction="column"
        onSubmit={handleSubmit(handleSend)}
      >
        <Flex
          flex={1}
          alignItems="flex-start"
          justifyContent="space-between"
          gap={2}
        >
          <Box
            p="4"
            pb={10}
            bg="green.50"
            minH={260}
            borderRadius={8}
            width="100%"
          >
            <Text fontWeight="bold" mb="4" color="green.800">
              IDENTIFICAÇÃO:
            </Text>

            <Flex gap="2">
              <Controller
                control={control}
                name="cnpj"
                render={({ field: { onChange, value } }) => (
                  <InputLineMask
                    isDisabled
                    mask="99.999.999-9999-99"
                    width="200px"
                    name="cnpj"
                    label="CNPJ"
                    placeholder="Informe o CNPJ"
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      getCompany(e.target.value);
                    }}
                    error={errors.cnpj}
                  />
                )}
              />

              <InputLine
                width="390px"
                isDisabled
                label="RAZÃO SOCIAL"
                placeholder="Informe o nome comercial do produto"
                {...register("company_name")}
                error={errors.company_name}
              />
            </Flex>

            <Flex gap="2" mt={5}>
              <InputLine
                width="200px"
                label="N DO CENTRO DE CUSTO"
                placeholder="Informe o centro de custo"
                {...register("cost_center_number")}
                error={errors.cost_center_number}
              />
              <InputLine
                width="390px"
                label="NOME DA UG"
                placeholder="Informe a nome da UG"
                {...register("name_ug")}
                error={errors.name_ug}
              />
            </Flex>

            <Flex gap="2" mt={5}>
              <InputLine
                width="100%"
                label="RESPONSÁVEL"
                placeholder="Informe o nome do responsável"
                {...register("responsible")}
                error={errors.responsible}
              />
            </Flex>

            <Flex gap="2" mt={5}>
              <InputLine width="100%" name="obs" label="OBSERVAÇÕES" />
            </Flex>
          </Box>

          <Box
            p="4"
            pb={10}
            bg="green.50"
            minH={260}
            borderRadius={8}
            width="100%"
          >
            <Text fontWeight="bold" mb="4" color="green.800">
              LOCALIZAÇÃO E CONTATO:
            </Text>

            <Flex gap="2">
              <InputLine
                isDisabled
                width="300px"
                label="CEP"
                placeholder="Informe o CEP"
                {...register("zipe_code")}
                error={errors.zipe_code}
              />
              <InputLine
                isDisabled
                width="100%"
                label="LOGRADOURO"
                placeholder="Informe o logradouro"
                {...register("address")}
                error={errors.address}
              />
            </Flex>

            <Flex gap="2" mt={5}>
              <InputLine
                isDisabled
                width="300px"
                label="NÚMERO"
                placeholder="Informe o número"
                {...register("number")}
                error={errors.number}
              />
              <InputLine
                isDisabled
                width="100%"
                label="BAIRRO"
                placeholder="Informe o bairro"
                {...register("district")}
                error={errors.district}
              />
            </Flex>

            <Flex gap="2" mt={5}>
              <InputLine
                width="300px"
                name="complemente"
                label="COMPLEMENTO"
                placeholder="Informe o número"
              />
              <InputLine
                isDisabled
                width="100%"
                label="MUNICÍPIO/UF"
                placeholder="Informe o bairro"
                {...register("city")}
                error={errors.city}
              />
            </Flex>

            <Flex gap="2" mt={5}>
              <InputLineMask
                width="300px"
                label="TELEFONE"
                placeholder="Informe o número de telefone"
                mask="(99)99999-99999"
                {...register("phone")}
                error={errors.phone}
              />
              <InputLineMask
                width="100%"
                label="CELULAR"
                placeholder="Informe o número de celular"
                mask="(99)99999-99999"
                {...register("cell_phone")}
                error={errors.cell_phone}
              />
            </Flex>
          </Box>
        </Flex>

        <Button
          type="submit"
          bg="green.600"
          color="white"
          alignSelf="flex-end"
          height={10}
          _hover={{
            bg: "green.800",
          }}
          mt="6"
          size="lg"
          isLoading={isSubmitting}
        >
          Atualizar
        </Button>
      </Flex>
    </Box>
  );
}
