import { useState } from "react";
import { api } from "../../../../service/api";
import { OptionsDTO } from "../../../../dtos/OptionsDTO";

export function useListProductGeneric() {
  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [dataOptionsProduct, setDataOptionsProduct] = useState<OptionsDTO[]>(
    []
  );

  const getListProductGeneric = async () => {
    try {
      setIsLoadingProduct(true);
      const response = await api.get("product/generic/list");

      const data = response.data;

      const newDate: OptionsDTO[] = [];

      for (let index = 0; index < data.length; index++) {
        const element = data[index];

        newDate.push({
          label: element.name_generic,
          value: element.id,
        });
      }

      setDataOptionsProduct(newDate);
    } catch (error) {
      setDataOptionsProduct([]);
    } finally {
      setIsLoadingProduct(false);
    }
  };

  return { isLoadingProduct, getListProductGeneric, dataOptionsProduct };
}
