import { Box } from "@chakra-ui/react";

export function PrivacyPolicy() {
  return (
    <Box padding={10}>
      <div>
        <p>
          <strong>
            <span>
              TERMOS E CONDI&Ccedil;&Otilde;ES DE USO DO SITE E APLICATIVO
              E-TANK
            </span>
          </strong>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>
            Este documento constitui os Termos e condi&ccedil;&otilde;es de Uso
            (T&amp;C) e possuem car&aacute;ter contratual e obrigacional onde
            s&atilde;o estabelecidas as rela&ccedil;&otilde;es e
            condi&ccedil;&otilde;es gerais de todo usu&aacute;rio cadastrado no
            site{" "}
          </span>
          <a
            href="http://www.etank.com.br"
            style={{ textDecoration: "underline" }}
          >
            <span>www.etank.com.br</span>
          </a>
          <span>
            , de propriedade da IBOSS TECNOLOGIAS E SISTEMAS LTDA, inscrita no
            CNPJ n&ordm; 36.773.094/0001-89, localizada &agrave; avenida Porto
            Seguro, n&ordm; 511, loja 101 B 2&ordm; andar, centro, CEP:
            45.820-006, na cidade de Eun&aacute;polis &ndash; Bahia.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>DEFINI&Ccedil;&Otilde;ES:</span>
        </p>
        <p>
          <span>
            Site: Portal online onde s&atilde;o realizados os cadastros dos
            usu&aacute;rios, quer sejam clientes ou parceiros, pessoas
            f&iacute;sicas ou jur&iacute;dicas, para utiliza&ccedil;&atilde;o no
            gerenciamento de frotas com foco no controle de abastecimentos de
            combust&iacute;veis.
          </span>
        </p>
        <p>
          <span>
            Aplicativo: Software para uso em dispositivos m&oacute;veis
            (tabletes ou smartphones) de propriedade exclusiva da detentora dos
            direitos autorais do ETANK, onde os usu&aacute;rios poder&atilde;o
            gerir, controlar, acompanhar e emitir pedidos ou
            requisi&ccedil;&otilde;es aos seus fornecedores parceiros em meio
            digital com atualiza&ccedil;&atilde;o em tempo real.
          </span>
        </p>
        <p>
          <span>
            Cliente: Todo usu&aacute;rio que se cadastrar no portal e efetuar a
            contra&ccedil;&atilde;o do plano dispon&iacute;vel de acordo com o
            tamanho de sua frota, seguindo as regras definidas para o plano
            contratado.
          </span>
        </p>
        <p>
          <span>
            Conveniado: Todo usu&aacute;rio que se cadastrar no portal como
            fornecedor de produtos e/ou servi&ccedil;os aos clientes
            cadastrados, cabendo definir suas rela&ccedil;&otilde;es comerciais
            e pol&iacute;tica de pre&ccedil;os para cada cliente, isentando o
            site e aplicativo sobre quaisquer responsabilidades e desacordos
            desta rela&ccedil;&atilde;o entre conveniado e cliente.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>
            1. Este (T&amp;C) descreve os termos e condi&ccedil;&otilde;es
            gerais aplic&aacute;veis ao uso dos servi&ccedil;os oferecidos pelo
            aplicativo e pelo site do denominado ETANK.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <ul>
          <li>
            <ul>
              <li>
                <span>
                  Os usu&aacute;rios (Clientes e Conveniados) dever&atilde;o ler
                  com aten&ccedil;&atilde;o este T&amp;C antes de aceitar os
                  termos e condi&ccedil;&otilde;es deste acordo. A
                  utiliza&ccedil;&atilde;o do site e aplicativo est&aacute;
                  condicionada &agrave; expressa manifesta&ccedil;&atilde;o de
                  aceita&ccedil;&atilde;o dos termos e condi&ccedil;&otilde;es e
                  a pol&iacute;tica de privacidade descritas neste documento.
                </span>
              </li>
            </ul>
          </li>
        </ul>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong>
            <u>
              <span>2. Declara&ccedil;&atilde;o:</span>
            </u>
          </strong>
        </p>
        <p>
          <span>
            2.1 O usu&aacute;rio ao se cadastrar e aceitar os termos e
            condi&ccedil;&otilde;es, declara ter conhecimento de todos os
            direitos e obriga&ccedil;&otilde;es constitu&iacute;das no presente
            T&amp;C, que representa o acordo legal entre as partes.
          </span>
        </p>
        <p>
          <span>
            Declara ainda que possui capacidade legal constitu&iacute;da, quer
            seja pessoa f&iacute;sica ou jur&iacute;dica, para contratar e
            utilizar as funcionalidades do site e aplicativo ETANK.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong>
            <u>
              <span>3. Cadastro:</span>
            </u>
          </strong>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>
            3.1 Para se cadastrar, o usu&aacute;rio dever&aacute; completar
            todos os campos de registro com os dados v&aacute;lidos para
            utiliza&ccedil;&atilde;o do site e aplicativo ETANK, registrando as
            informa&ccedil;&otilde;es de maneira exata, precisa e verdadeira,
            assumindo o compromisso de atualizar seus dados pessoais quando
            necess&aacute;rio. Estes dados registrados ser&atilde;o utilizados
            para identifica&ccedil;&atilde;o de cada usu&aacute;rio.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>
            3.2 &Eacute; de inteira responsabilidade dos usu&aacute;rios
            cadastrados, responder pela veracidade e exatid&atilde;o dos dados
            cadastrados no site e aplicativo ETANK.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>
            3.3 O ETANK se reserva o direito de solicitar a
            comprova&ccedil;&atilde;o dos dados cadastrados ou de suspender
            definitiva ou temporariamente os usu&aacute;rios cuja
            comprova&ccedil;&atilde;o da veracidade dos dados n&atilde;o foi
            poss&iacute;vel, sem que isto ocasione direito de ressarcimento
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>
            3.4 No momento do cadastro o usu&aacute;rio dever&aacute; cadastrar
            sua senha pessoal para acessar ao painel administrativo e o
            aplicativo ETANK, se responsabilizando pela guarda e
            confidencialidade da pr&oacute;pria senha cadastrada.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>
            3.5 A conta assim como a senha cadastrada, &eacute; &uacute;nica,
            insubstitu&iacute;vel e intransfer&iacute;vel, sendo vedado o
            registro de mais de uma conta para o mesmo usu&aacute;rio. Caso
            sejam detectadas contas que tenham dados coincidentes ou
            relacionados, o ETANK poder&aacute; cancel&aacute;-las,
            suspend&ecirc;-las ou desabilit&aacute;-las a qualquer tempo.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>
            3.6 N&atilde;o &eacute; permitida a cess&atilde;o ou
            transfer&ecirc;ncia da conta sob qualquer hip&oacute;tese.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong>
            <u>
              <span>
                4. Altera&ccedil;&otilde;es no Termo e Condi&ccedil;&otilde;es
                de Uso
              </span>
            </u>
          </strong>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>
            4.1 As altera&ccedil;&otilde;es necess&aacute;rias no T&amp;C
            poder&atilde;o ser realizadas a qualquer momento, sendo que, somente
            ser&atilde;o vigoradas ap&oacute;s 10 (dez) dias da
            publica&ccedil;&atilde;o no site. O usu&aacute;rio poder&aacute;
            manifestar seu desacordo com as altera&ccedil;&otilde;es
            introduzidas em at&eacute; 5 (cinco) dias da sua
            publica&ccedil;&atilde;o, e ap&oacute;s o t&eacute;rmino deste
            prazo, ser&atilde;o consideradas aceitas pelos usu&aacute;rios as
            altera&ccedil;&otilde;es no T&amp;C.{" "}
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong>
            <u>
              <span>5. Privacidade da Informa&ccedil;&atilde;o</span>
            </u>
          </strong>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>
            5.1 O ETANK se compromete a manter a confidencialidade, integridade
            e em seguran&ccedil;a quaisquer informa&ccedil;&otilde;es
            disponibilizadas pelos usu&aacute;rios relacionadas a sua conta.
          </span>
        </p>
        <p>
          <span>
            5.2. Com a finalidade de garantir a privacidade do usu&aacute;rio,
            bem como a seguran&ccedil;a de suas Informa&ccedil;&otilde;es, o
            ETANK se compromete a regularmente reavaliar a sua pol&iacute;tica
            de seguran&ccedil;a e adapt&aacute;-la, conforme necess&aacute;rio.
          </span>
        </p>
        <p>
          <span>
            5.3. Em nenhuma hip&oacute;tese, o ETANK ir&aacute; vender ou
            disponibilizar as informa&ccedil;&otilde;es do usu&aacute;rio ou
            geradas atrav&eacute;s do uso do aplicativo, sendo certo que somente
            as utilizar&aacute; para as seguintes finalidades, com as quais o
            usu&aacute;rio expressamente concorda e anui, conforme abaixo:
          </span>
        </p>
        <p>
          <span>
            a) Para enviar qualquer notifica&ccedil;&atilde;o administrativa,
            alertas e comunicados relevantes para o mesmo;
          </span>
        </p>
        <p>
          <span>
            b) Para cumprir com a finalidade do Portal ou do Aplicativo;
          </span>
        </p>
        <p>
          <span>
            c) Identificar o perfil, desejos ou necessidades do usu&aacute;rio a
            fim de aprimorar os produtos e/ou servi&ccedil;os oferecidos pelo
            Aplicativo ou Portal;
          </span>
        </p>
        <p>
          <span>
            d) Realizar estat&iacute;sticas gen&eacute;ricas para monitoramento
            de utiliza&ccedil;&atilde;o do Aplicativo;
          </span>
        </p>
        <p>
          <span>
            e) Para pesquisas de marketing, planejamento de projetos do Portal e
            do Aplicativo;
          </span>
        </p>
        <p>
          <span>
            f) Resolu&ccedil;&atilde;o de problemas no Portal ou Aplicativo,
            verifica&ccedil;&atilde;o e prote&ccedil;&atilde;o das
            informa&ccedil;&otilde;es, do Portal ou Aplicativo contra erros,
            fraudes ou qualquer outro crime eletr&ocirc;nico.
          </span>
        </p>
        <p>
          <span>
            5.4 Caso o usu&aacute;rio acredite que seu login e senha de acesso
            ao Portal tenham sido roubados ou sejam de conhecimento de outras
            pessoas, por qualquer raz&atilde;o, dever&aacute; imediatamente
            comunicar o ETANK atrav&eacute;s de e-mail ou outros canais de
            comunica&ccedil;&atilde;o descritos no Portal.
          </span>
        </p>
        <p>
          <span>
            5.5 Caso haja qualquer altera&ccedil;&atilde;o nas
            informa&ccedil;&otilde;es de cadastro fornecidas pelo LICENCIADO,
            este se compromete a informar o ETANK de tais
            altera&ccedil;&otilde;es de modo a garantir o correto uso e
            funcionamento do Aplicativo.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong>
            <u>
              <span>6. Obriga&ccedil;&otilde;es das Partes</span>
            </u>
          </strong>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>6.1 Obriga&ccedil;&otilde;es dos Clientes:</span>
        </p>
        <p>
          <span>
            6.1.1 Realizar o cadastro com informa&ccedil;&otilde;es fidedignas e
            precisas, sob pena de responsabiliza&ccedil;&atilde;o legal por
            informa&ccedil;&otilde;es fraudulentas;
          </span>
        </p>
        <p>
          <span>
            6.1.2 Negociar com os Conveniados a rela&ccedil;&atilde;o de
            fornecimento, bem como, a pol&iacute;tica de pre&ccedil;os e
            pagamentos, isentando o ETANK de quaisquer desaven&ccedil;as ou
            desacordos comerciais que porventura ocorram entre as partes.
          </span>
        </p>
        <p>
          <span>
            6.1.3 Selecionar qual plano se encaixa em seu perfil, de acordo o
            tamanho e necessidade de sua frota de ve&iacute;culos;
          </span>
        </p>
        <p>
          <span>
            6.1.4 Realizar os pagamentos ao ETANK das mensalidades conforme os
            crit&eacute;rios do plano contratado;
          </span>
        </p>
        <p>
          <span>
            6.1.5 Realizar os cadastros e lan&ccedil;amentos no painel
            administrativo do site, para a perfeita utiliza&ccedil;&atilde;o das
            funcionalidades do Portal e Aplicativo, conforme descritos abaixo:
          </span>
        </p>
        <ul>
          <li>
            <span>Cadastro de Unidades de Gest&atilde;o;</span>
          </li>
          <li>
            <span>Cadastro de Frotas;</span>
          </li>
          <li>
            <span>Cadastro de Motoristas;</span>
          </li>
          <li>
            <span>Cadastro de Usu&aacute;rios;</span>
          </li>
          <li>
            <span>
              Emiss&atilde;o de requisi&ccedil;&otilde;es de entradas;
            </span>
          </li>
          <li>
            <span>
              Emiss&atilde;o de requisi&ccedil;&otilde;es de sa&iacute;das;
            </span>
          </li>
          <li>
            <span>Pagamentos dos produtos fornecidos pelos conveniados.</span>
          </li>
        </ul>
        <p>
          <span>
            6.1.6 Informar o ETANK sobre falhas e problemas de funcionamento do
            Portal e Aplicativo.
          </span>
        </p>
        <p>
          <span>
            6.1.7 Gerir seus usu&aacute;rios do Portal e Aplicativo, definindo
            os perfis de acesso, obriga&ccedil;&otilde;es e responsabilidades.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>6.2 &ndash; Obriga&ccedil;&otilde;es dos Conveniados:</span>
        </p>
        <p>
          <span>
            6.2.1 Realizar o cadastro com informa&ccedil;&otilde;es fidedignas e
            precisas, sob pena de responsabiliza&ccedil;&atilde;o legal por
            informa&ccedil;&otilde;es fraudulentas.
          </span>
        </p>
        <p>
          <span>
            6.2.2 - Negociar com os clientes a rela&ccedil;&atilde;o de
            fornecimento, bem como, a pol&iacute;tica de pre&ccedil;os e
            pagamentos, isentando o ETANK de quaisquer desaven&ccedil;as ou
            desacordos comerciais que porventura ocorram entre as partes.
          </span>
        </p>
        <p>
          <span>
            6.2.3 - Realizar os cadastros e lan&ccedil;amentos no painel
            administrativo do site, para a perfeita utiliza&ccedil;&atilde;o das
            funcionalidades do Portal e Aplicativo, conforme descritos abaixo:
          </span>
        </p>
        <ul>
          <li>
            <span>
              Cadastro de produtos e servi&ccedil;os e seus respectivos
              pre&ccedil;os de venda;
            </span>
          </li>
          <li>
            <span>Cadastro de usu&aacute;rios;</span>
          </li>
          <li>
            <span>
              Confer&ecirc;ncia das requisi&ccedil;&otilde;es no ato do
              fornecimento, identificando os ve&iacute;culos pelo QRCODE;
            </span>
          </li>
          <li>
            <span>
              Registrar no Aplicativo as informa&ccedil;&otilde;es do
              fornecimento como: produto, valores, quantidade, od&ocirc;metro,
              motorista e observa&ccedil;&otilde;es;
            </span>
          </li>
        </ul>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>
            6.2.4 - Fornecer apenas para ve&iacute;culos autorizados pelo
            Aplicativo, responsabilizando-se pelos fornecimentos n&atilde;o
            autorizados;
          </span>
        </p>
        <p>
          <span>
            6.2.5 - Gerir seus usu&aacute;rios do Portal e Aplicativo, definindo
            os perfis de acesso, obriga&ccedil;&otilde;es e responsabilidades.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong>
            <u>
              <span>7. - Proibi&ccedil;&otilde;es</span>
            </u>
          </strong>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>
            7.1. - &Eacute; vedado a todos os usu&aacute;rios (cliente ou
            conveniado):
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <ul>
          <li>
            <span>
              Utilizar o Portal ou Aplicativo para divulgar
              informa&ccedil;&otilde;es de qualquer forma que possa implicar em
              viola&ccedil;&atilde;o de normas aplic&aacute;veis no Brasil, de
              direitos de propriedade do ETANK e/ou de terceiros ou dos bons
              costumes, incluindo, sem limita&ccedil;&atilde;o, a
              viola&ccedil;&atilde;o de direitos intelectuais, autorais e de
              privacidade, ou a produ&ccedil;&atilde;o e
              divulga&ccedil;&atilde;o de conte&uacute;do ilegal, imoral,
              inapropriado ou ofensivo;
            </span>
          </li>
        </ul>
        <p>
          <span>&nbsp;</span>
        </p>
        <ul>
          <li>
            <span>
              Copiar, ceder, sublicenciar, vender, dar em loca&ccedil;&atilde;o
              ou em garantia, reproduzir, doar, alienar de qualquer forma,
              transferir total ou parcialmente, sob quaisquer modalidades,
              gratuita ou onerosamente, provis&oacute;ria ou permanentemente, o
              Aplicativo, assim como seus m&oacute;dulos, partes, manuais ou
              quaisquer informa&ccedil;&otilde;es relativas ao mesmo;
            </span>
          </li>
        </ul>
        <p>
          <span>&nbsp;</span>
        </p>
        <ul>
          <li>
            <span>
              Empregar softwares, t&eacute;cnicas e/ou artif&iacute;cios com o
              intuito de utilizar indevidamente o Aplicativo para
              pr&aacute;ticas nocivas ao ETANK ou a terceiros, tais como
              exploits, spamming, flooding, spoofing, crashing, root kits, etc.;
            </span>
          </li>
        </ul>
        <p>
          <span>&nbsp;</span>
        </p>
        <ul>
          <li>
            <span>
              Reproduzir, adaptar, modificar e/ou empregar, no todo ou em parte,
              para qualquer fim, o Aplicativo ou qualquer conte&uacute;do do
              Portal sem a autoriza&ccedil;&atilde;o expressa do ETANK.
            </span>
          </li>
        </ul>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong>
            <u>
              <span>8. &ndash; Finalidade do Sistema</span>
            </u>
          </strong>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>
            8.1 - O ETANK limita-se exclusivamente a fornecer
            informa&ccedil;&otilde;es gerenciais, impress&atilde;o de
            formul&aacute;rios, relat&oacute;rios e outros documentos gerenciais
            para a gest&atilde;o e controle de fornecimento para frotas, estando
            fora deste contrato a ades&atilde;o e interface com outros programas
            e sistemas e softwares do governo para transmiss&atilde;o e/ou
            emiss&atilde;o de guias de recolhimento de impostos e taxas ou
            quaisquer outras interfaces de programa&ccedil;&atilde;o.{" "}
          </span>
        </p>
        <p>
          <span>
            Quaisquer a&ccedil;&otilde;es ou uso do Portal ou Aplicativo com o
            prop&oacute;sito de interferir nas opera&ccedil;&otilde;es do ETANK
            ser&aacute; considerada intromiss&atilde;o ou tentativa de
            viola&ccedil;&atilde;o dos direitos autorais e de propriedade
            intelectual, com san&ccedil;&otilde;es previstas em lei acarretando
            indeniza&ccedil;&atilde;o dos danos e preju&iacute;zos ao ETANK.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong>
            <u>
              <span>9. Rescis&atilde;o e San&ccedil;&otilde;es</span>
            </u>
          </strong>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>
            9.1 - Por qualquer uma das partes, desde que n&atilde;o sejam
            cumpridos os termos e condi&ccedil;&otilde;es aqui estabelecidos,
            ser&atilde;o aplicadas &agrave; parte inadimplente, cumulativamente
            ou alternativamente, a exclusivo crit&eacute;rio da parte
            prejudicada, as seguintes san&ccedil;&otilde;es:
          </span>
        </p>
        <ul>
          <li>
            <span>
              Rescis&atilde;o contratual autom&aacute;tica, independente de
              interpela&ccedil;&atilde;o judicial ou extrajudicial;
            </span>
          </li>
          <li>
            <span>
              Pagamento de multa no valor de 30% (trinta por cento) do valor
              remanescente do contrato. Esta penalidade limita-se apenas aos
              clientes, pois os conveniados n&atilde;o possuem
              obriga&ccedil;&atilde;o financeira com o ETANK;
            </span>
          </li>
          <li>
            <span>
              Perdas e danos que se apurarem, incluindo custos processuais;
            </span>
          </li>
          <li>
            <span>
              Pagamentos dos honor&aacute;rios advocat&iacute;cios e peritos,
              desde j&aacute;, fixado em 20% (vinte por cento) se for litigioso
              e 10% (dez por cento) se for amig&aacute;vel.
            </span>
          </li>
        </ul>
        <p>
          <span>
            9.2 &ndash; A rescis&atilde;o deste acordo poder&aacute; ocorrer por
            qualquer uma das partes, mediante aviso pr&eacute;vio por escrito 30
            (trinta) dias antes do prazo de encerramento do contrato.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong>
            <u>
              <span>10. Responsabilidade</span>
            </u>
          </strong>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>
            10.1 - O ETANK n&atilde;o responder&aacute;, em nenhuma
            hip&oacute;tese, ainda que em car&aacute;ter solid&aacute;rio ou
            subsidi&aacute;rio:
          </span>
        </p>
        <ul>
          <li>
            <span>
              Por eventuais preju&iacute;zos sofridos pelo usu&aacute;rio em
              raz&atilde;o da tomada de decis&otilde;es com base nas
              informa&ccedil;&otilde;es disponibilizadas no Portal ou
              Aplicativo;
            </span>
          </li>
        </ul>
        <p>
          <span>&nbsp;</span>
        </p>
        <ul>
          <li>
            <span>
              Por eventuais preju&iacute;zos sofridos pelo usu&aacute;rio em
              raz&atilde;o de falhas no sistema de inform&aacute;tica ou nos
              servidores que independam de culpa do Portal ou Aplicativo ou em
              sua conectividade com a internet de modo geral, devendo o
              usu&aacute;rio manter, &agrave;s suas expensas, linha de
              telecomunica&ccedil;&atilde;o, modem, software de
              comunica&ccedil;&atilde;o, endere&ccedil;o de correio
              eletr&ocirc;nico e outros recursos necess&aacute;rios &agrave;
              comunica&ccedil;&atilde;o com o ETANK;
            </span>
          </li>
        </ul>
        <p>
          <span>&nbsp;</span>
        </p>
        <ul>
          <li>
            <span>
              Por situa&ccedil;&otilde;es de caso fortuito ou for&ccedil;a
              maior, nos termos do artigo 393 do C&oacute;digo Civil Brasileiro;
            </span>
          </li>
        </ul>
        <p>
          <span>&nbsp;</span>
        </p>
        <ul>
          <li>
            <span>
              Por desacordos comerciais ou preju&iacute;zos ocorridos entre a
              rela&ccedil;&atilde;o entre clientes e conveniados.
            </span>
          </li>
        </ul>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>
            10.2 &ndash; O ETANK se responsabiliza em fornecer o software para
            uso pela CONTRATANTE de acordo com as condi&ccedil;&otilde;es e
            planos contratados, garantindo o pleno funcionamento do sistema
            desde que, atendidos todos os requisitos deste T&amp;C;
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>
            10.3 &ndash; O ETANK se responsabiliza em atender aos chamados de
            suporte t&eacute;cnico em um prazo m&aacute;ximo de 48 horas, a
            partir do registro do chamado;
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong>
            <u>
              <span>11. Valores e Pagamentos</span>
            </u>
          </strong>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>
            11.1 - O cliente dever&aacute; pagar ao ETANK o valor do respectivo
            plano escolhido mensalmente na data de vencimento de acordo com as
            op&ccedil;&otilde;es de pagamento disponibilizadas no ato da
            contrata&ccedil;&atilde;o.
          </span>
        </p>
        <p>
          <span>
            11.2 - Caso o cliente, no decorrer da vig&ecirc;ncia do presente
            instrumento, opte por outro plano, os valores ser&atilde;o alterados
            de forma proporcional (pro rata) de acordo com o respectivo plano
            escolhido.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>
            11.3 - A falta de pagamento de duas ou mais mensalidades, nas datas
            determinadas para seu vencimento, acarretar&aacute; na
            suspens&atilde;o de acesso ao SOFTWARE at&eacute; que as
            pend&ecirc;ncias financeiras sejam regularizadas.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong>
            <u>
              <span>12. Propriedade Intelectual</span>
            </u>
          </strong>
        </p>
        <p>
          <span>
            12.1 &ndash; O usu&aacute;rio ao aceitar com os termos e
            condi&ccedil;&otilde;es de uso, est&aacute; autorizando somente a
            utilizar da plataforma desenvolvida pelo ETANK para o gerenciamento
            de sua frota, excluindo os direitos intelectuais, de propriedade,
            marcas, patentes, desenhos, ou direitos sobre
            informa&ccedil;&otilde;es confidenciais e segredos de
            neg&oacute;cios relacionados ao Portal e Aplicativo.{" "}
          </span>
        </p>
        <p>
          <span>
            12.2 - Todo e qualquer conte&uacute;do disponibilizado no Aplicativo
            ou no Portal, tais como, mas n&atilde;o se limitando a textos,
            gr&aacute;ficos, imagens, logos, &iacute;cones, fotografias,
            conte&uacute;do editorial, notifica&ccedil;&otilde;es, softwares e
            qualquer outro material, pertencem exclusivamente &agrave; ETANK e
            s&atilde;o protegidos pela lei brasileira no que se refere a
            propriedade intelectual e aos direitos autorais.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
        <p>
          <strong>
            <u>
              <span>13. Jurisdi&ccedil;&atilde;o e Leis</span>
            </u>
          </strong>
        </p>
        <p>
          <span>
            13.1 &ndash; Este documento est&aacute; interpretado sobre a
            legisla&ccedil;&atilde;o aplic&aacute;vel brasileira. As partes
            elegem o foro da comarca de Eun&aacute;polis &ndash; BA, para
            dirimir quaisquer d&uacute;vidas ou controv&eacute;rsias oriundas do
            presente contrato, excluindo qualquer outro foro por mais
            privilegiado que seja.
          </span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
      </div>
    </Box>
  );
}
