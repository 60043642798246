import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Icon,
  SimpleGrid,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { HeaderCard } from "../../../../components/headerCard";
import { useListMotorist } from "../hooks/useListMotorist";
import { useEffect, useRef, useState } from "react";
import { RiCloseFill, RiPencilFill } from "react-icons/ri";
import { MotoristDTO } from "../../../../dtos/MotoristDTO";
import { useDeleteMotorist } from "../hooks/useDeleteMotorist";
import { formatedMonthDay } from "../../../../utils/utils";
import { FilterMotorist } from "../filter";

export function ListMotorist() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [motoristInfo, setMotoristInfo] = useState<MotoristDTO>(
    {} as MotoristDTO
  );

  const { getMotorist, isLoading, data } = useListMotorist();
  const { deleteMotorist, loadingDelete } = useDeleteMotorist();

  const cancelRef = useRef<any>(null);

  async function deleteMotoristData(motoristId: string) {
    try {
      await deleteMotorist(motoristId);

      toast({
        position: "top-right",
        title: "Motorista",
        description: "Motorista removido com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: "top-right",
        title: "Motorista",
        description: "Não foi possível remover o Motorista.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      onClose();
      setMotoristInfo({} as MotoristDTO);
      getMotorist();
    }
  }

  useEffect(() => {
    getMotorist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <HeaderCard
        isSmall
        title="MOTORISTAS"
        titleButton="Novo Motorista"
        link="/motorista/novo"
      />
      <HeaderCard
        isSmall
        isFilter
        title="MOTORISTAS"
        titleButton="Novo Motorista"
        link="/motorista/novo"
      >
        <Box height="80px" flexDirection="column" width="100%">
          <Flex justifyContent="space-between" width="100%" alignItems="center">
            <FilterMotorist />
          </Flex>
        </Box>
      </HeaderCard>

      {isLoading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={500}
        >
          <Spinner
            color="green.600"
            emptyColor="white"
            thickness="4px"
            size="xl"
            h={100}
            w={100}
          />
        </Box>
      )}

      <SimpleGrid
        flex="1"
        gap="4"
        minChildWidth="320px"
        alignItems="flex-start"
        px={1}
        py={1}
      >
        {data.length > 0 && !isLoading && (
          <Box p="4" borderRadius={8} height={500}>
            <TableContainer>
              <Table variant="striped" colorScheme="gray" size="sm">
                <Thead>
                  <Tr bg="green.600" height="10">
                    <Th color="white" textAlign="center">
                      MATRICULA
                    </Th>
                    <Th color="white" textAlign="center">
                      NOME COMPLETO
                    </Th>
                    <Th color="white" textAlign="center">
                      UNI. DE GESTÃO
                    </Th>
                    <Th color="white" textAlign="center">
                      CNH
                    </Th>
                    <Th color="white" textAlign="center">
                      VAL. CNH
                    </Th>
                    <Th color="white" textAlign="center">
                      STATUS CNH
                    </Th>
                    <Th color="white" textAlign="center">
                      AÇÕES
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((item, index) => (
                    <Tr key={index}>
                      <Td textAlign="center">{item.matriculation}</Td>
                      <Td textAlign="center">{item.name}</Td>
                      <Td textAlign="center">{item.office_branch.name_ug}</Td>
                      <Td textAlign="center">{item.number_cnh}</Td>
                      <Td textAlign="center">
                        {formatedMonthDay(item.validity_cnh)}
                      </Td>
                      <Td textAlign="center">
                        {item.status_cnh === "valid" ? "Valida" : "Vencida"}
                      </Td>
                      <Td textAlign="center" width="10">
                        <Button
                          size="sm"
                          fontSize="sm"
                          as="a"
                          href={`motorista/atualizar/${item.id}`}
                        >
                          <Icon fontSize="16px" as={RiPencilFill} />
                        </Button>
                        <Button
                          size="sm"
                          ml={1}
                          fontSize="sm"
                          onClick={() => {
                            setMotoristInfo(item);
                            onOpen();
                          }}
                        >
                          <Icon fontSize="16px" as={RiCloseFill} />
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </SimpleGrid>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Remover Motorista
            </AlertDialogHeader>

            <AlertDialogBody>
              {!loadingDelete && (
                <Text>
                  Deseja remover o motorista:{" "}
                  <strong>{motoristInfo?.name}</strong>
                </Text>
              )}

              {loadingDelete && (
                <Flex width="100%" alignItems="center" justifyContent="center">
                  <Spinner
                    color="green.600"
                    emptyColor="white"
                    thickness="4px"
                    size="xl"
                    h={50}
                    w={50}
                  />
                </Flex>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              {!loadingDelete && (
                <>
                  <Button
                    colorScheme="red"
                    onClick={() => {
                      deleteMotoristData(motoristInfo?.id);
                    }}
                  >
                    Sim
                  </Button>
                  <Button ml={3} onClick={onClose}>
                    Nāo
                  </Button>
                </>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
