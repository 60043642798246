import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";

import request from "axios";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { HeaderCard } from "../../../../components/headerCard";
import { RiArrowLeftSLine } from "react-icons/ri";
import { InputLine } from "../../../../components/form/InputLine";
import { InputSelect } from "../../../../components/form/inputSelect";
import { OfficeSelect } from "../../../../components/officeSelect";
import { BoxInputCode } from "../../../../components/boxInputCode";
import { useLiStstructureType } from "../hooks/useLiStstructureType";
import { useEffect, useState } from "react";
import { InputLineMask } from "../../../../components/form/inputLineMask";
import { InputPrice } from "../../../../components/form/inputPrice";
import { useLocatorOptions } from "../hooks/useLocatorOptions";
import { useListMarkModel } from "../hooks/useListMarkModel";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../../../service/api";
import { formatCurrency } from "../../../../utils/utils";
import { useShowCar } from "../hooks/useShowCar";
import { AllLoading } from "../../../../components/allLoading";

type createFormData = {
  structure_type_id: string;
  office_branch_id: string;
  locator_id?: string;
  group_name: string;
  code_etank: string;
  property: string;
  patrimony_number: string;
  code_car_fleet: string;
  plaque: string;
  monthly_rent?: string;
  monthly_franchise?: string;
  km_excess?: string;
  start_date?: string;
  initial_odometer: string;
  number_renavan?: string;
  number_crlv?: string;
  validity_crlv?: string;
  city_state_crlv?: string;
  status_crlv?: string;
  number_chassis?: string;
  number_motor?: string;
  number_series?: string;
  number_rntc?: string;
  validity_rntc?: string;
  ribbon_ipva?: string;
  value_ipva?: string;
  terminate_ipva?: string;
  payment_ipva?: string;
  category_cnh: string;
  mark: string;
  model: string;
  year_fabrication: string;
  year_model: string;
  color?: string;
  tara?: string;
  lotacao?: string;
  capacity_charge?: string;
  type_fuel: string;
  tank_capacity: string;
  consumer_unit: string;
  consumer_pattern: string;
  autonomy: string;
  validade_crl?: string;
  status_crl?: string;
  faixaipva?: string;
};

const createFormDataSchema = yup.object().shape({
  structure_type_id: yup.string().required("Campo obrigatório."),
  office_branch_id: yup.string().required("Campo obrigatório."),
  locator_id: yup.string(),
  group_name: yup.string().required("Campo obrigatório."),
  code_etank: yup.string().required("Campo obrigatório."),
  property: yup.string().required("Campo obrigatório."),
  patrimony_number: yup.string().required("Campo obrigatório."),
  code_car_fleet: yup.string().required("Campo obrigatório."),
  plaque: yup.string().required("Campo obrigatório."),
  monthly_rent: yup.string(),
  monthly_franchise: yup.string(),
  km_excess: yup.string(),
  start_date: yup.string(),
  initial_odometer: yup.string().required("Campo obrigatório."),
  number_renavan: yup.string(),
  number_crlv: yup.string(),
  validity_crlv: yup.string(),
  city_state_crlv: yup.string(),
  status_crlv: yup.string(),
  number_chassis: yup.string(),
  number_motor: yup.string(),
  number_series: yup.string(),
  number_rntc: yup.string(),
  validity_rntc: yup.string(),
  ribbon_ipva: yup.string(),
  value_ipva: yup.string(),
  terminate_ipva: yup.string(),
  payment_ipva: yup.string(),
  category_cnh: yup.string().required("Campo obrigatório."),
  mark: yup.string().required("Campo obrigatório."),
  model: yup.string().required("Campo obrigatório."),
  year_fabrication: yup.string().required("Campo obrigatório."),
  year_model: yup.string().required("Campo obrigatório."),
  color: yup.string(),
  tara: yup.string(),
  lotacao: yup.string(),
  capacity_charge: yup.string(),
  type_fuel: yup.string().required("Campo obrigatório."),
  tank_capacity: yup.string().required("Campo obrigatório."),
  consumer_unit: yup.string().required("Campo obrigatório."),
  consumer_pattern: yup.string().required("Campo obrigatório."),
  autonomy: yup.string().required("Campo obrigatório."),
  validade_crl: yup.string(),
  status_crl: yup.string(),
  faixaipva: yup.string(),
});

export function EditCar() {
  let { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createFormDataSchema) });

  const [isDisableLocator, setIsDisableLocator] = useState(true);
  const [groupName, setGroupName] = useState("");

  const [valueOptionTypeStruct, setValueOptionTypeStruct] = useState<any>(null);

  const [tankCapacity, setTankCapacity] = useState("");
  const [consumerUnit, setConsumerUnit] = useState("");
  const [consumerPattern, setConsumerPattern] = useState("");

  const {
    getLiStstructureGroup,
    isLoading,
    getLiStstructureType,
    dataType,
    data,
    dataOptions,
    dataOptionsType,
  } = useLiStstructureType();

  const { getLiLocator, dataLocatorOptions, isLoadingLocator } =
    useLocatorOptions();

  const {
    getLiMark,
    dataOptionsMark,
    isLoadingMark,
    getLiMarkModel,
    dataMark,
    dataOptionsModel,
    isLoadingModel,
  } = useListMarkModel();

  function getOptionLocator(value: string) {
    if (value !== "Próprio") {
      setIsDisableLocator(false);
      getLiLocator();
    } else {
      setIsDisableLocator(true);
    }
  }

  useEffect(() => {
    getLiStstructureGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!tankCapacity || !consumerUnit || !consumerPattern) return;

    let newTankCapacity = Number(
      tankCapacity.replace(".", "").replace(",", ".")
    );
    let newConsumerPattern = Number(
      consumerPattern.replace(".", "").replace(",", ".")
    );
    let autonomy = 0;

    if (
      consumerUnit === "l/hora" &&
      newTankCapacity > 0 &&
      newConsumerPattern > 0
    ) {
      autonomy = newTankCapacity / newConsumerPattern;
    } else if (newTankCapacity > 0 && newConsumerPattern > 0) {
      autonomy = newTankCapacity * newConsumerPattern;
    }

    setValue("autonomy", formatCurrency(autonomy) as string);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tankCapacity, consumerUnit, consumerPattern]);

  const { findCar, dataCar, isLoadingCar } = useShowCar();

  useEffect(() => {
    if (id) {
      findCar(id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (dataCar.id) {
      setValue("structure_type_id", dataCar.structure_type_id);
      setValue("office_branch_id", dataCar.office_branch_id);
      setValue("group_name", dataCar.group_name);
      setValue("code_etank", dataCar.code_etank);
      setValue("property", dataCar.property);
      setValue("patrimony_number", dataCar.patrimony_number);
      setValue("code_car_fleet", dataCar.code_car_fleet);
      setValue("plaque", dataCar.plaque);
      setValue(
        "monthly_rent",
        dataCar.monthly_rent > 0
          ? formatCurrency(dataCar.monthly_rent).toString()
          : ""
      );
      setValue(
        "monthly_franchise",
        dataCar.monthly_franchise > 0
          ? formatCurrency(dataCar.monthly_franchise).toString()
          : ""
      );
      setValue(
        "km_excess",
        dataCar.km_excess > 0
          ? formatCurrency(dataCar.km_excess).toString()
          : ""
      );
      setValue("initial_odometer", dataCar.initial_odometer.toString() ?? "");
      setValue("number_renavan", dataCar.number_renavan ?? "");
      setValue("number_crlv", dataCar.number_crlv ?? "");
      setValue("validity_crlv", dataCar.validity_crlv ?? "");
      setValue("city_state_crlv", dataCar.city_state_crlv ?? "");
      setValue("status_crlv", dataCar.status_crlv ?? "");
      setValue("number_chassis", dataCar.number_chassis ?? "");
      setValue("number_motor", dataCar.number_motor ?? "");
      setValue("number_series", dataCar.number_series ?? "");
      setValue("number_rntc", dataCar.number_rntc ?? "");
      setValue("validity_rntc", dataCar.validity_rntc ?? "");
      setValue("ribbon_ipva", dataCar.ribbon_ipva ?? "");
      setValue("value_ipva", dataCar.value_ipva ?? "");
      setValue("terminate_ipva", dataCar.terminate_ipva ?? "");
      setValue("payment_ipva", dataCar.payment_ipva ?? "");
      setValue("category_cnh", dataCar.category_cnh ?? "");
      setValue("mark", dataCar.mark ?? "");
      setValue("model", dataCar.model ?? "");
      setValue("year_fabrication", dataCar.year_fabrication ?? "");
      setValue("year_model", dataCar.year_model ?? "");
      setValue("color", dataCar.color ?? "");
      setValue("tara", dataCar.tara ?? "");
      setValue("lotacao", dataCar.lotacao ?? "");
      setValue("capacity_charge", dataCar.capacity_charge ?? "");
      setValue("type_fuel", dataCar.type_fuel ?? "");
      setValue(
        "tank_capacity",
        dataCar.tank_capacity > 0
          ? formatCurrency(dataCar.tank_capacity).toString()
          : ""
      );
      setValue("consumer_unit", dataCar.consumer_unit ?? "");
      setValue(
        "consumer_pattern",
        dataCar.consumer_pattern > 0
          ? formatCurrency(dataCar.consumer_pattern).toString()
          : ""
      );
      setValue(
        "autonomy",
        dataCar.autonomy > 0 ? formatCurrency(dataCar.autonomy).toString() : ""
      );

      setValue("start_date", dataCar.start_date ?? "");

      setTankCapacity(dataCar.tank_capacity.toString());
      setConsumerUnit(dataCar.consumer_unit);
      setConsumerPattern(dataCar.consumer_pattern.toString());

      dataSelect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCar]);

  function dataSelect() {
    setValueSelectChildrenGroup();
  }

  function setValueSelectChildrenGroup() {
    const groupData = data.find((it) => it.group_type === dataCar.group_name);
    if (groupData) {
      getLiMark(groupData.group_type);
      setGroupName(groupData.group_type);
      getLiStstructureType(groupData.group_type, data);
    }
  }

  function setValueSelectTypeStructData() {
    const dataTypeStruct = dataType.find(
      (it) => it.id === dataCar.structure_type_id
    );
    if (dataTypeStruct) {
      setValueOptionTypeStruct({
        value: dataCar.structure_type_id,
        label: dataTypeStruct.type,
      });
    }
  }

  function findValueMakeModelData() {
    const findMark = dataMark.find((it) => it.name === dataCar.mark);
    if (findMark) {
      getLiMarkModel(groupName, findMark.name);
    }
  }

  useEffect(() => {
    setValueSelectTypeStructData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataType]);

  useEffect(() => {
    findValueMakeModelData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMark]);

  const handleSend: SubmitHandler<createFormData> = async (value) => {
    try {
      value.tank_capacity = value.tank_capacity
        .replace(".", "")
        .replace(",", ".");
      value.consumer_pattern = value.consumer_pattern
        .replace(".", "")
        .replace(",", ".");
      value.autonomy = value.autonomy.replace(".", "").replace(",", ".");

      value.monthly_rent = value.monthly_rent
        ? value.monthly_rent.replace(".", "").replace(",", ".")
        : "0";

      value.monthly_franchise = value.monthly_franchise
        ? value.monthly_franchise.replace(".", "").replace(",", ".")
        : "0";

      value.km_excess = value.km_excess
        ? value.km_excess.replace(".", "").replace(",", ".")
        : "0";

      value.value_ipva = value.value_ipva
        ? value.value_ipva.replace(".", "").replace(",", ".")
        : "0";

      value.plaque = value.plaque.toUpperCase();

      await api.put("company/car/update/" + id, value);

      toast({
        position: "top-right",
        title: "Motorista",
        description: "Cadastro atualizado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      navigate("/frota");
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        toast({
          position: "top-right",
          title: "Motorista",
          description: error.response.data.error,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          position: "top-right",
          title: "Motorista",
          description: "Não foi possível atualizar.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Box>
      <HeaderCard
        title="ATUALIZAR FROTA"
        titleButton="Voltar"
        icon={RiArrowLeftSLine}
        link="/frota"
      />

      {isLoadingCar && <AllLoading />}

      <Flex
        as="form"
        px={10}
        py={5}
        direction="column"
        onSubmit={handleSubmit(handleSend)}
      >
        <Flex
          flex={1}
          alignItems="flex-start"
          justifyContent="space-between"
          gap={2}
        >
          <Box
            p="4"
            pb={10}
            bg="green.50"
            minH={260}
            borderRadius={8}
            width="100%"
          >
            <Text fontWeight="bold" mb="4" color="green.800">
              IDENTIFICAÇÃO:
            </Text>

            <Flex gap="2">
              <Controller
                control={control}
                name="property"
                render={({ field: { onChange, value } }) => (
                  <InputSelect
                    options={[
                      { label: "Próprio", value: "Próprio" },
                      { label: "Alugado", value: "Alugado" },
                      { label: "Emprestado", value: "Emprestado" },
                      { label: "Comodato", value: "Comodato" },
                    ]}
                    name="property"
                    width="200px"
                    label="PROPRIEDADE"
                    placeholder="Informe a propriedade"
                    value={{
                      label: value,
                      value: value,
                    }}
                    onChange={(item) => {
                      getOptionLocator(item.value);
                      onChange(item.value);
                    }}
                    error={errors.property}
                  />
                )}
              />

              <InputLine
                autoComplete="off"
                width="191px"
                label="N PATRIMÔNIO"
                placeholder="Informe n patrimonio"
                {...register("patrimony_number")}
                error={errors.patrimony_number}
              />

              <Controller
                control={control}
                name="group_name"
                render={({ field: { onChange, value } }) => (
                  <InputSelect
                    options={dataOptions}
                    isLoading={isLoading}
                    name="group_name"
                    width="191px"
                    label="GRUPO"
                    placeholder="Informe o grupo"
                    value={{
                      label: value,
                      value: value,
                    }}
                    onChange={(item) => {
                      setValueOptionTypeStruct(null);
                      getLiMark(item.label);
                      setGroupName(item.label);
                      onChange(item.label);
                      getLiStstructureType(item.label, data);
                    }}
                    error={errors.group_name}
                  />
                )}
              />
            </Flex>

            <Flex gap="2" mt={3}>
              <Controller
                control={control}
                name="structure_type_id"
                render={({ field: { onChange, value } }) => (
                  <InputSelect
                    options={dataOptionsType}
                    name="structure_type_id"
                    width="200px"
                    label="TIPO DE FROTA"
                    placeholder="Informe o tipo de frota"
                    onChange={(item) => {
                      setValueOptionTypeStruct(item);
                      onChange(item.value);
                    }}
                    value={valueOptionTypeStruct || ""}
                    error={errors.structure_type_id}
                  />
                )}
              />

              <InputLine
                autoComplete="off"
                width="191px"
                label="CÓDIGO DA FROTA"
                placeholder="Informe o numero da cnh"
                {...register("code_car_fleet")}
                error={errors.code_car_fleet}
              />

              <InputLineMask
                autoComplete="off"
                textTransform="uppercase"
                mask="aaa9*99"
                width="191px"
                label="PLACA"
                {...register("plaque")}
                error={errors.plaque}
              />
            </Flex>

            <Flex gap="2" mt={3}>
              <Controller
                control={control}
                name="monthly_rent"
                render={({ field: { onChange, value } }) => (
                  <InputPrice
                    width="200px"
                    label="ALUGUEL MENSAL"
                    name="monthly_rent"
                    onChange={onChange}
                    value={value}
                    error={errors.monthly_rent}
                  />
                )}
              />

              <Controller
                control={control}
                name="monthly_franchise"
                render={({ field: { onChange, value } }) => (
                  <InputPrice
                    width="191px"
                    label="FANQUIA MENSAL"
                    name="monthly_franchise"
                    onChange={onChange}
                    value={value}
                    error={errors.monthly_franchise}
                  />
                )}
              />

              <Controller
                control={control}
                name="km_excess"
                render={({ field: { onChange, value } }) => (
                  <InputPrice
                    precision="0"
                    width="191px"
                    label="FANQUIA MENSAL"
                    name="km_excess"
                    onChange={onChange}
                    value={value}
                    error={errors.km_excess}
                  />
                )}
              />
            </Flex>

            <Flex gap="2" mt={3}>
              <Controller
                control={control}
                name="office_branch_id"
                render={({ field: { onChange, value } }) => (
                  <OfficeSelect
                    name="office_branch_id"
                    width="200px"
                    onChange={(item) => {
                      onChange(item.value);
                    }}
                    isUpdate
                    dataUpdate={value}
                    isDisabled
                    error={errors.office_branch_id}
                  />
                )}
              />

              <Controller
                control={control}
                name="type_fuel"
                render={({ field: { onChange, value } }) => (
                  <InputSelect
                    options={[
                      { label: "Diesel", value: "Diesel" },
                      { label: "Gasolina", value: "Gasolina" },
                      { label: "Etanol", value: "Etanol" },
                      { label: "Gasolina/Etanol", value: "Gasolina/Etanol" },
                    ]}
                    value={{
                      label: value,
                      value: value,
                    }}
                    name="type_fuel"
                    width="191px"
                    label="TIPO COMBUSTÍVEL"
                    placeholder="Selecione.."
                    error={errors.type_fuel}
                    onChange={(item) => {
                      onChange(item.value);
                    }}
                  />
                )}
              />

              <Controller
                control={control}
                name="consumer_unit"
                render={({ field: { onChange, value } }) => (
                  <InputSelect
                    options={[
                      { label: "l/hora", value: "l/hora" },
                      { label: "km/l", value: "km/l" },
                    ]}
                    value={{
                      label: value,
                      value: value,
                    }}
                    name="consumer_unit"
                    width="191px"
                    label="UNIDADE CONSUMO"
                    placeholder="Selecione.."
                    error={errors.consumer_unit}
                    onChange={(item) => {
                      setConsumerUnit(item.value);
                      onChange(item.value);
                    }}
                  />
                )}
              />
            </Flex>

            <Flex gap="2" mt={3}>
              <Controller
                control={control}
                name="tank_capacity"
                render={({ field: { onChange, value } }) => (
                  <InputPrice
                    width="200px"
                    label="C. TANQUE (LITROS)"
                    name="tank_capacity"
                    onChange={(item) => {
                      onChange(item.target.value);
                      setTankCapacity(item.target.value);
                    }}
                    value={value}
                    error={errors.tank_capacity}
                  />
                )}
              />

              <Controller
                control={control}
                name="consumer_pattern"
                render={({ field: { onChange, value } }) => (
                  <InputPrice
                    width="191px"
                    label="CONSUMO PADRÃO"
                    name="consumer_pattern"
                    onChange={(item) => {
                      onChange(item.target.value);
                      setConsumerPattern(item.target.value);
                    }}
                    value={value}
                    error={errors.consumer_pattern}
                  />
                )}
              />

              <InputLine
                autoComplete="off"
                width="191px"
                label="AUTONOMIA"
                isDisabled={true}
                textAlign="center"
                placeholder="0,000"
                {...register("autonomy")}
                error={errors.autonomy}
              />
            </Flex>

            <Flex gap="2" mt={3}>
              <BoxInputCode
                textAlign="center"
                isDisabled
                width="200px"
                {...register("code_etank")}
                error={errors.code_etank}
              />

              <InputLine
                type="number"
                autoComplete="off"
                width="191px"
                label="ODÔMETRO INICIAL"
                textAlign="center"
                min="1"
                max="99999999999999999999"
                placeholder="Informe o odômetro"
                {...register("initial_odometer")}
                error={errors.initial_odometer}
              />

              <Controller
                control={control}
                name="locator_id"
                render={({ field: { onChange, value } }) => (
                  <InputSelect
                    isDisabled={isDisableLocator}
                    isLoading={isLoadingLocator}
                    options={dataLocatorOptions}
                    name="locator_id"
                    width="191px"
                    label="PROPRIETÁRIO"
                    placeholder="Selecione.."
                    error={errors.locator_id}
                    onChange={(item) => {
                      onChange(item.value);
                    }}
                  />
                )}
              />
            </Flex>
          </Box>

          <Box
            flex={1}
            p="4"
            pb={10}
            bg="green.50"
            minH={486}
            borderRadius={8}
            width="100%"
          >
            <Text fontWeight="bold" mb="4" color="green.800">
              REGISTRO DETRAN:
            </Text>

            <Flex gap="2">
              <Controller
                control={control}
                name="mark"
                render={({ field: { onChange, value } }) => (
                  <InputSelect
                    isLoading={isLoadingMark}
                    options={dataOptionsMark}
                    name="mark"
                    width="200px"
                    label="MARCA"
                    placeholder="Selecione.."
                    onChange={(item) => {
                      getLiMarkModel(groupName, item.label);
                      onChange(item.label);
                      setValue("model", "");
                    }}
                    value={{
                      label: value,
                      value: value,
                    }}
                    error={errors.mark}
                  />
                )}
              />

              <Controller
                control={control}
                name="model"
                render={({ field: { onChange, value } }) => (
                  <InputSelect
                    isLoading={isLoadingModel}
                    options={dataOptionsModel}
                    name="model"
                    width="191px"
                    label="MODELO"
                    placeholder="Selecione.."
                    onChange={(item) => {
                      onChange(item.label);
                    }}
                    value={{
                      label: value,
                      value: value,
                    }}
                    error={errors.model}
                  />
                )}
              />

              <InputLine
                type="date"
                width="191px"
                label="VALIDADE DO CRL"
                placeholder="Informe o km excedente"
                {...register("validade_crl")}
                error={errors.validade_crl}
              />
            </Flex>

            <Flex gap="2" mt={3}>
              <InputLine
                autoComplete="off"
                width="200px"
                label="N DO RENAVAM"
                placeholder="Informe o n do renavem"
                {...register("number_renavan")}
                error={errors.number_renavan}
              />
              <InputLine
                autoComplete="off"
                width="191px"
                label="N CRLV"
                placeholder="Informe a franquia mensal"
                {...register("number_crlv")}
                error={errors.number_crlv}
              />

              <InputLine
                autoComplete="off"
                width="191px"
                label="N CHASSI"
                placeholder="Informe o numero do chassi"
                {...register("number_chassis")}
                error={errors.number_chassis}
              />
            </Flex>

            <Flex gap="2" mt={3}>
              <Controller
                control={control}
                name="category_cnh"
                render={({ field: { onChange, value } }) => (
                  <InputSelect
                    options={[
                      {
                        label: "A",
                        value: "A",
                      },
                      {
                        label: "B",
                        value: "B",
                      },
                      {
                        label: "C",
                        value: "C",
                      },
                      {
                        label: "D",
                        value: "D",
                      },
                      {
                        label: "E",
                        value: "E",
                      },
                      {
                        label: "AC",
                        value: "AC",
                      },
                    ]}
                    value={{
                      label: value,
                      value: value,
                    }}
                    name="category_cnh"
                    width="200px"
                    label="CATEGORIA CNH"
                    placeholder="Informe a categoria da cnh"
                    onChange={(item) => {
                      onChange(item.value);
                    }}
                    error={errors.category_cnh}
                  />
                )}
              />

              <InputLine
                autoComplete="off"
                width="191px"
                label="STATUS CRL:"
                placeholder="Informe o status CRL"
                isDisabled={true}
                {...register("status_crl")}
                error={errors.status_crl}
              />

              <InputLine
                autoComplete="off"
                width="191px"
                label="N RNTC"
                placeholder="Informe o número RNTC"
                {...register("number_rntc")}
                error={errors.number_rntc}
              />
            </Flex>

            <Flex gap="2" mt={3}>
              <InputLine
                autoComplete="off"
                width="200px"
                label="N MOTOR"
                placeholder="Informe o número do motor"
                {...register("number_motor")}
                error={errors.number_motor}
              />
              <InputLine
                autoComplete="off"
                width="191px"
                label="N SÉRIE"
                placeholder="Informe a numero de série"
                {...register("number_series")}
                error={errors.number_series}
              />

              <Controller
                control={control}
                name="value_ipva"
                render={({ field: { onChange, value } }) => (
                  <InputPrice
                    width="191px"
                    label="VALOR IPVA(C. ÚNICA)"
                    name="value_ipva"
                    onChange={onChange}
                    value={value}
                    error={errors.value_ipva}
                  />
                )}
              />
            </Flex>

            <Flex gap="2" mt={3}>
              <InputLine
                autoComplete="off"
                type="date"
                width="200px"
                label="VALIDADE RNTC"
                placeholder="Informe a validade RNTC"
                {...register("validity_rntc")}
                error={errors.validity_rntc}
              />
              <InputLine
                autoComplete="off"
                width="191px"
                label="FAIXA IPVA"
                placeholder="Informe a faixa do IPVA"
                {...register("faixaipva")}
                error={errors.faixaipva}
              />

              <InputLineMask
                mask="9999"
                width="191px"
                label="ANO MODELO"
                placeholder="Informe o ano do modelo"
                textAlign="center"
                {...register("year_model")}
                error={errors.year_model}
              />
            </Flex>

            <Flex gap="2" mt={3}>
              <InputLine
                type="date"
                width="200px"
                label="VENCIMENTO IPVA"
                placeholder="Informe a vencimento IPVA"
                {...register("terminate_ipva")}
                error={errors.terminate_ipva}
              />
              <InputLine
                type="date"
                width="191px"
                label="PAGAMENTO IPVA"
                placeholder="Informe o pagamento do IPVA"
                {...register("payment_ipva")}
                error={errors.payment_ipva}
              />

              <InputLineMask
                textAlign="center"
                mask="9999"
                width="191px"
                label="ANO FABRICAÇÃO"
                placeholder="Informe o ano fabricação"
                {...register("year_fabrication")}
                error={errors.year_fabrication}
              />
            </Flex>
          </Box>
        </Flex>

        <Button
          mt={6}
          type="submit"
          bg="green.600"
          color="white"
          alignSelf="flex-end"
          height={10}
          _hover={{
            bg: "green.800",
          }}
          size="lg"
          isLoading={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  );
}
