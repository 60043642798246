import { useEffect, useState } from "react";
import { api } from "../../../../service/api";
import { TravelDTO } from "../../../../dtos/TravelDTO";

export function useShowTravel(id: string) {
  const [isLoadingTravel, setIsLoadingTravel] = useState(false);
  const [dataTravel, setDataTravel] = useState<TravelDTO>({} as TravelDTO);

  const findTravel = async (id: string) => {
    try {
      setIsLoadingTravel(true);
      const response = await api.get("company/trip/show/" + id);
      setDataTravel(response.data);
    } catch (error) {
      throw error;
    } finally {
      setIsLoadingTravel(false);
    }
  };

  useEffect(() => {
    findTravel(id);
  }, [id]);

  return { isLoadingTravel, dataTravel, refetch: findTravel };
}
