import { useState } from "react";
import { api } from "../../../../service/api";
import { OptionsDTO } from "../../../../dtos/OptionsDTO";
import { LocatorDTO } from "../../../../dtos/LocatorDTO";

export function useLocatorOptions() {
  const [isLoadingLocator, setIsLoadingLocator] = useState(false);
  const [dataLocator, setDataLocator] = useState<LocatorDTO[]>([]);
  const [dataLocatorOptions, setDataLocatorOptions] = useState<OptionsDTO[]>(
    []
  );

  const getLiLocator = async () => {
    try {
      setIsLoadingLocator(true);
      const response = await api.get("company/locator/list");
      const locatorData = response.data;
      setDataLocator(locatorData);

      const newDataLOcator: OptionsDTO[] = [];

      for (let index = 0; index < locatorData.length; index++) {
        const element = locatorData[index];
        newDataLOcator.push({
          label: element.name,
          value: element.id,
        });
      }

      setDataLocatorOptions(newDataLOcator);
    } catch (error) {
      throw error;
    } finally {
      setIsLoadingLocator(false);
    }
  };

  return {
    isLoadingLocator,
    getLiLocator,
    dataLocator,
    dataLocatorOptions,
  };
}
