import { useState } from "react";
import { api } from "../../../../service/api";
import { OptionsDTO } from "../../../../dtos/OptionsDTO";
import { MotoristDTO } from "../../../../dtos/MotoristDTO";

export function useListMotoristCar() {
  const [isLoadingMotorist, setIsLoadingCarMotorist] = useState(false);
  const [dataMotorist, setDataCarMotorist] = useState<MotoristDTO[]>([]);
  const [dataOptionsMotorist, setDataOptionsMotorist] = useState<OptionsDTO[]>(
    []
  );

  const getMotoristCar = async () => {
    try {
      setIsLoadingCarMotorist(true);
      const response = await api.get("/company/motorist/list");
      const carData = response.data;
      setDataCarMotorist(carData);

      const newDataCar: OptionsDTO[] = [];

      for (let index = 0; index < carData.length; index++) {
        const element = carData[index];
        newDataCar.push({
          label: element.name,
          value: element.id,
        });
      }

      setDataOptionsMotorist(newDataCar);
    } catch (error) {
      setDataOptionsMotorist([]);
      setDataCarMotorist([]);
    } finally {
      setIsLoadingCarMotorist(false);
    }
  };

  return {
    isLoadingMotorist,
    getMotoristCar,
    dataMotorist,
    dataOptionsMotorist,
  };
}
