import { useState } from "react";
import { api } from "../../../../service/api";

export function useCancelRequisition() {
  const [loadingCancel, setLoadingCancel] = useState(false);

  const cancelRequisition = async (id: string) => {
    try {
      setLoadingCancel(true);
      await api.put("company/requisition/cancel/" + id);
    } catch (error) {
      throw error;
    } finally {
      setLoadingCancel(false);
    }
  };

  return { loadingCancel, cancelRequisition };
}
