import { useRef, useState } from "react";

import request from "axios";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Checkbox,
  Flex,
  Heading,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Input } from "../../../../../components/form/input";
import { ProcessInfo } from "./select-process";
import { FunisherInfo } from "./select-furnisher";
import { RequistionTypeCar } from "../../types/requistion-type-car";
import { CateogryInfo } from "./select-categories";
import { ProductOrServiceInfo } from "./product-or-service";
import { formatCurrency } from "../../../../../utils/utils";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../../service/api";

type Props = {
  processInfo: ProcessInfo;
  funisherInfo: FunisherInfo;
  requistionTypeCar: RequistionTypeCar;
  cateogryInfo: CateogryInfo;
  productOrServiceInfo: ProductOrServiceInfo;
};

export function DataResume({
  processInfo,
  funisherInfo,
  requistionTypeCar,
  cateogryInfo,
  productOrServiceInfo,
}: Props) {
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [itinerary, setItinerary] = useState("");
  const [observation, setObservation] = useState("");
  const [quantityBlocked, setQuantityBlocked] = useState(false);
  const [discountFurnisher, setDiscountFurnisher] = useState(false);

  const [loading, setLoading] = useState(false);

  const cancelRef = useRef<any>(null);

  function cancelRequistion() {
    navigate("/requisicoes");
  }

  async function sendRequistion() {
    try {
      setLoading(true);

      await api.post("/company/requisition/create", {
        ...processInfo,
        ...funisherInfo,
        ...requistionTypeCar,
        ...cateogryInfo,
        ...productOrServiceInfo,
        itinerary,
        observation,
        quantity_blocked: quantityBlocked,
        discount_furnisher: discountFurnisher,
      });

      toast({
        position: "top-right",
        title: "Requisição",
        description: "Requisição enviada com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      navigate("/requisicoes");
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        toast({
          position: "top-right",
          title: "Requisição",
          description: error.response.data.error,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          position: "top-right",
          title: "Requisição",
          description: "Não foi possível enviar requisição.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box mb={10}>
      <Text fontSize="large">
        Confira as informações da sua requisição e clique em enviar
      </Text>
      <Box mt={5} width={745}>
        <Stack spacing="4">
          <Card>
            <CardHeader>
              <Heading size="sm">INFORMAÇÕES DO VEICULO:</Heading>
            </CardHeader>

            <CardBody>
              <Stack divider={<StackDivider />} spacing="4">
                <Box display="flex" gap={4}>
                  {cardInfo("TIPO:", processInfo.operation_name)}
                  {cardInfo("GRUPO:", requistionTypeCar.group_name)}
                  {cardInfo("NOME:", requistionTypeCar.name)}
                  {cardInfo("CÓDIGO:", requistionTypeCar.fleet_code)}
                </Box>

                <Box display="flex" gap={4}>
                  {cardInfo("PLACA:", requistionTypeCar.plaque)}
                  {cardInfo("MARCA:", requistionTypeCar.mark)}
                  {cardInfo("MODELO:", requistionTypeCar.model)}
                </Box>
                <Box display="flex" gap={4}>
                  {cardInfo("OBSERVAÇÕES:", "")}
                </Box>
              </Stack>
            </CardBody>
          </Card>
        </Stack>

        <Stack spacing="4" mt={5}>
          <Card>
            <CardHeader>
              <Heading size="sm">INFORMAÇÕES EXTRAS:</Heading>
            </CardHeader>

            <CardBody>
              <Stack divider={<StackDivider />} spacing="4">
                <Box display="flex" gap={4}>
                  {cardInfo("CONVENIADO:", funisherInfo.furnisher_name)}
                  {cardInfo("CATEGORIA:", cateogryInfo.category_name)}
                  {cardInfo(
                    "PRODUTO/SERVIÇO:",
                    productOrServiceInfo.product_name
                  )}
                </Box>

                <Box display="flex" gap={4}>
                  {cardInfo(
                    "QUANTIDADE:",
                    formatCurrency(
                      productOrServiceInfo.quantity_solicit
                    ).toString()
                  )}
                  {cardInfo(
                    "PREÇO UNITARIO:",
                    formatCurrency(
                      productOrServiceInfo.unitary_price
                    ).toString()
                  )}
                  {cardInfo(
                    "PREÇO TOAL:",
                    formatCurrency(productOrServiceInfo.total_price).toString()
                  )}
                </Box>
                <Box display="flex" gap={4}>
                  {cardInfo("MOTORISTA:", requistionTypeCar.motorist_name)}
                </Box>
              </Stack>
            </CardBody>
          </Card>
        </Stack>

        <Flex gap="2" mt={5}>
          <Input
            label="ITINERÁRIO"
            size="md"
            name="plan_id"
            width="100%"
            placeholder="Informe..."
            value={itinerary}
            onChange={(item) => {
              setItinerary(item.target.value);
            }}
          />
          <Input
            label="OBSERVAÇÕES"
            size="md"
            name="plan_id"
            width="100%"
            placeholder="Informe..."
            value={observation}
            onChange={(item) => {
              setObservation(item.target.value);
            }}
          />
        </Flex>

        <Flex gap="2" mt={5}>
          <Checkbox
            size="lg"
            colorScheme="green"
            borderColor="gray.600"
            isChecked={quantityBlocked}
            onChange={(e) => {
              setQuantityBlocked(e.target.checked);
            }}
          >
            BLOQUEAR
          </Checkbox>
          <Checkbox
            size="lg"
            colorScheme="green"
            borderColor="gray.600"
            isChecked={discountFurnisher}
            onChange={(e) => {
              setDiscountFurnisher(e.target.checked);
            }}
          >
            DESCONTAR
          </Checkbox>
        </Flex>

        <Box
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="flex-end"
          gap={2}
        >
          <Button
            type="submit"
            height={10}
            colorScheme="red"
            mt="6"
            size="lg"
            onClick={() => {
              onOpen();
            }}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            bg="green.600"
            color="white"
            alignSelf="flex-end"
            height={10}
            _hover={{
              bg: "green.800",
            }}
            mt="6"
            size="lg"
            isLoading={loading}
            onClick={sendRequistion}
          >
            Enviar
          </Button>
        </Box>
      </Box>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Requisição de entrada
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>Deseja cancelar a requisição de entrada</Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    cancelRequistion();
                  }}
                >
                  Sim
                </Button>
                <Button ml={3} onClick={onClose}>
                  Nāo
                </Button>
              </>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}

function cardInfo(title: string, descrition: string) {
  return (
    <Box>
      <Heading size="xs" textTransform="uppercase" color="gray.800">
        {title}
      </Heading>
      <Text pt="1" textAlign="center" fontSize="sm" color="gray.600">
        {descrition}
      </Text>
    </Box>
  );
}
