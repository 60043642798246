import { useState } from "react";
import { api } from "../../../../service/api";

export function useUpdateStatusCar() {
  const [loadingStatus, setLoadingStatus] = useState(false);

  const updateStatusCar = async (id: string): Promise<string> => {
    try {
      setLoadingStatus(true);
      await api.put("company/car/updatestatus/" + id);
    } catch (error) {
      throw error;
    } finally {
      setLoadingStatus(false);
      return "ok";
    }
  };

  return { loadingStatus, updateStatusCar };
}
