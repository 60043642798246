import { useState } from "react";
import { api } from "../../../../service/api";
import { CarDTO } from "../../../../dtos/CarDTO";

export function useListCar() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<CarDTO[]>([]);

  const getCar = async () => {
    try {
      setIsLoading(true);
      const response = await api.get("company/car/list");
      setData(response.data);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, getCar, data };
}
