import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { FRONTAL_TRUCK_ICON, TANK_TRUCK_ICON } from "../../../../../assets";

export interface ProcessInfo {
  operation_name: string;
  operation: string;
}
type Props = {
  callBackSuccess: (data: ProcessInfo) => void;
};

export function SelectProcess({ callBackSuccess }: Props) {
  return (
    <Box>
      <Text fontSize="large">Selecione uma das opções</Text>
      <Box mt={5} width={745}>
        <Flex gap={2}>
          {BoxContent("Veículo da frota", FRONTAL_TRUCK_ICON, function () {
            callBackSuccess({
              operation: "fleet_vehicle",
              operation_name: "Veículo da frota",
            });
          })}
          {BoxContent(
            "Não cadastrado",
            FRONTAL_TRUCK_ICON,
            function () {
              callBackSuccess({
                operation: "not_registered",
                operation_name: "Não cadastrado",
              });
            },
            true
          )}
          {BoxContent("Tanque comboio", TANK_TRUCK_ICON, function () {
            callBackSuccess({
              operation: "tank_convoy",
              operation_name: "Tanque comboio",
            });
          })}
          {/* {BoxContent("Frota em viagem", TRAVELLING_VEHICLES_ICON)} */}
        </Flex>
      </Box>
    </Box>
  );
}

function BoxContent(
  title: string,
  icon: string,
  onClick: () => void,
  isRed?: boolean
) {
  return (
    <Box
      onClick={onClick}
      display="flex"
      width={180}
      height={180}
      bg={"gray.50"}
      borderWidth={1}
      borderColor={isRed ? "red.600" : "green.400"}
      borderRadius={10}
      transition="0.5s"
      _hover={{
        cursor: "pointer",
        borderColor: isRed ? "red.800" : "green.600",
        transition: "0.5s",
      }}
      alignItems="center"
      alignContent="center"
      justifyContent="center"
      flexDir="column"
    >
      <Image src={icon} />
      <Text
        color={isRed ? "red.800" : "green.600"}
        fontFamily="heading"
        fontWeight="bold"
        mt={2}
      >
        {title}
      </Text>
    </Box>
  );
}
