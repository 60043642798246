import {
  Badge,
  Box,
  Flex,
  Icon,
  SimpleGrid,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { HeaderCard } from "../../../../components/headerCard";
import { CardInfoData } from "../../../../components/cardInfoData";
import {
  RiArrowLeftSLine,
  RiCarFill,
  RiRepeatOneLine,
  RiSquareLine,
} from "react-icons/ri";
import {
  formatCurrency,
  formatedDateTimer,
  lmWord,
  typeOperagions,
} from "../../../../utils/utils";
import { useParams } from "react-router-dom";
import { useShowStock } from "../hooks/useShowStock";
import { FilterMovimentation } from "../filter/filterMovimentation";

export function StockDetail() {
  let { id } = useParams();

  const { dataList, loading } = useShowStock(id as string);

  return (
    <Box>
      <HeaderCard
        title="MOVIMENTAÇÕES"
        titleButton="Voltar"
        icon={RiArrowLeftSLine}
        link="/relatorio/estoque"
      />

      {loading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={500}
        >
          <Spinner
            color="green.600"
            emptyColor="white"
            thickness="4px"
            size="xl"
            h={100}
            w={100}
          />
        </Box>
      )}

      {!loading && dataList.id && (
        <>
          <Flex mt={5} px={5} alignItems="center" gap={2}>
            <CardInfoData
              icon={<Icon fontSize="25px" as={RiCarFill} />}
              title="Marca"
              subTitle={lmWord(dataList.car_fleet.mark ?? "", 35)}
              isWdAll
            />
            <CardInfoData
              icon={<Icon fontSize="25px" as={RiCarFill} />}
              title="Modelo"
              subTitle={lmWord(dataList.car_fleet.model ?? "", 35)}
              isWdAll
            />

            <CardInfoData
              icon={<Icon fontSize="25px" as={RiRepeatOneLine} />}
              title="Código"
              subTitle={dataList.car_fleet.code_car_fleet}
              isWdAll
            />

            <CardInfoData
              icon={<Icon fontSize="25px" as={RiSquareLine} />}
              title="Produto"
              subTitle={dataList.product_generic.name_generic}
              isWdAll
            />
          </Flex>
          <Flex mt={5} px={5} alignItems="center" gap={2}>
            <CardInfoData
              icon={<Icon fontSize="25px" as={RiRepeatOneLine} />}
              title="Estoque atual"
              subTitle={formatCurrency(
                stockActual(
                  dataList.requisition_entry_sum_quantity_applied,
                  dataList.requisition_exit_sum_quantity_applied
                ) ?? dataList.actual_stock
              ).toString()}
              isWdAll
            />
            <CardInfoData
              icon={<Icon fontSize="25px" as={RiRepeatOneLine} />}
              title="Valor do estoque"
              subTitle={formatCurrency(
                priceActual(
                  dataList.requisition_entry_sum_total_price,
                  dataList.requisition_exit_sum_total_price
                ) ?? dataList.value_stock
              ).toString()}
              isWdAll
            />

            <CardInfoData
              icon={<Icon fontSize="25px" as={RiRepeatOneLine} />}
              title="Entradas"
              subTitle={formatCurrency(
                dataList.requisition_entry_sum_quantity_applied ??
                  dataList.actual_stock
              ).toString()}
              isWdAll
            />

            <CardInfoData
              icon={<Icon fontSize="25px" as={RiRepeatOneLine} />}
              title="Saídas"
              subTitle={formatCurrency(
                dataList.requisition_exit_sum_quantity_applied ?? 0
              ).toString()}
              isWdAll
            />
          </Flex>
        </>
      )}
      {!loading && (
        <Flex px={5}>
          <FilterMovimentation stock_id={id as string} />
        </Flex>
      )}
      <SimpleGrid
        flex="1"
        gap="4"
        minChildWidth="320px"
        alignItems="flex-start"
        px={1}
        py={1}
      >
        {!loading &&
          dataList.requisitions &&
          dataList.requisitions.length > 0 && (
            <Box p="4" borderRadius={8} height={500} overflow="scroll">
              <TableContainer>
                <Table variant="striped" colorScheme="gray" size="sm">
                  <Thead>
                    <Tr bg="green.600" height="10">
                      <Th color="white" textAlign="center">
                        STATUS
                      </Th>
                      <Th color="white" textAlign="center">
                        TIPO
                      </Th>
                      <Th color="white" textAlign="center">
                        DATA
                      </Th>
                      <Th color="white" textAlign="center">
                        N REQ
                      </Th>
                      <Th color="white" textAlign="center">
                        CÓDIGO
                      </Th>
                      <Th color="white" textAlign="center">
                        PLACA
                      </Th>
                      <Th color="white" textAlign="center">
                        MARCA
                      </Th>
                      <Th color="white" textAlign="center">
                        PROCESSO
                      </Th>
                      <Th color="white" textAlign="center">
                        ODM
                      </Th>
                      <Th color="white" textAlign="center">
                        PRODUTO
                      </Th>
                      <Th color="white" textAlign="center">
                        QTD
                      </Th>
                      <Th color="white" textAlign="center">
                        VALOR
                      </Th>
                      <Th color="white" textAlign="center">
                        TOTAL
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {dataList.requisitions.map((item, index) => (
                      <Tr key={index}>
                        <Td textAlign="center">
                          {statusRquisition(item.status)}
                        </Td>
                        <Td textAlign="center">
                          {item.request_type === "entry" ? "Entrada" : "Saída"}
                        </Td>
                        <Td textAlign="center">
                          {formatedDateTimer(item.end_date, true)}
                        </Td>
                        <Td textAlign="center">{item.code_requisition}</Td>
                        <Td textAlign="center">{item.fleet_code}</Td>
                        <Td textAlign="center">{item.plaque}</Td>
                        <Td textAlign="center">{lmWord(item.mark, 10)}</Td>
                        <Td textAlign="center">
                          {typeOperagions(item.operation)}
                        </Td>
                        <Td textAlign="center">{item.end_odometer}</Td>
                        <Td textAlign="center">{item.product_name}</Td>
                        <Td textAlign="center" color="blue">
                          {item.quantity_applied > 0
                            ? formatCurrency(item.quantity_applied)
                            : formatCurrency(item.quantity_solicit)}
                        </Td>
                        <Td textAlign="center" color="green">
                          {formatCurrency(item.unitary_price)}
                        </Td>
                        <Td textAlign="center" color="green">
                          {formatCurrency(item.total_price)}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          )}
      </SimpleGrid>
    </Box>
  );
}

function statusRquisition(status: string) {
  if (status === "finished") {
    return <Badge colorScheme="green">Finalizada</Badge>;
  }

  if (status === "canceled") {
    return <Badge colorScheme="red">Cancelada</Badge>;
  }

  if (status === "progress") {
    return <Badge>Andamento</Badge>;
  }
}

function stockActual(
  entryQuantity: number | null,
  exitQuantity: number | null
) {
  if (entryQuantity && entryQuantity > 0 && exitQuantity && exitQuantity > 0) {
    return entryQuantity - exitQuantity;
  }

  let newEntryQuantity = entryQuantity && entryQuantity > 0 ? entryQuantity : 0;
  let newExitQuantity = exitQuantity && exitQuantity > 0 ? exitQuantity : 0;

  return newEntryQuantity - newExitQuantity;
}

function priceActual(
  entryTotalPrice: number | null,
  exitTotalPrice: number | null
) {
  if (
    entryTotalPrice &&
    entryTotalPrice > 0 &&
    exitTotalPrice &&
    exitTotalPrice > 0
  ) {
    return entryTotalPrice - exitTotalPrice;
  }

  let newEntryTotalPrice =
    entryTotalPrice && entryTotalPrice > 0 ? entryTotalPrice : 0;
  let newExitTotalPrice =
    exitTotalPrice && exitTotalPrice > 0 ? exitTotalPrice : 0;

  return newEntryTotalPrice - newExitTotalPrice;
}
