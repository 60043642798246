import { useState } from "react";
import { api } from "../../../../service/api";
import { OptionsDTO } from "../../../../dtos/OptionsDTO";
import { MarkDTO } from "../../../../dtos/MarkDTO";

export function useListMarkCar() {
  const [isLoadingMark, setIsLoadingCarMark] = useState(false);
  const [dataMark, setDataMark] = useState<MarkDTO[]>([]);
  const [dataOptionsMark, setDataOptionsMark] = useState<OptionsDTO[]>([]);

  const getMarkCar = async () => {
    try {
      setIsLoadingCarMark(true);
      const response = await api.get("/company/mark/index");
      const markCarData = response.data;
      setDataMark(markCarData);

      const newDataMarkCar: OptionsDTO[] = [];

      for (let index = 0; index < markCarData.length; index++) {
        const element = markCarData[index];
        newDataMarkCar.push({
          label: element.name,
          value: element.id,
        });
      }

      setDataOptionsMark(newDataMarkCar);
    } catch (error) {
      setDataOptionsMark([]);
      setDataMark([]);
    } finally {
      setIsLoadingCarMark(false);
    }
  };

  return {
    isLoadingMark,
    getMarkCar,
    dataMark,
    dataOptionsMark,
  };
}
