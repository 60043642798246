import { useState } from "react";
import { api } from "./api";
import { OfficeDTO } from "../dtos/OfficeDTO";
import { OptionsDTO } from "../dtos/OptionsDTO";

export function useOffice() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<OfficeDTO[]>([]);
  const [dataOptions, setDataOptions] = useState<OptionsDTO[]>([]);

  const getOffice = async (id?: string) => {
    try {
      setIsLoading(true);
      const response = await api.get("company/office/list");

      const officeData = response.data;
      setData(officeData);

      const newDataOffice: OptionsDTO[] = [];

      for (let index = 0; index < officeData.length; index++) {
        const element = officeData[index];

        const officeExist = id === element.id ? true : false;

        if (!officeExist) {
          newDataOffice.push({
            label: element.name_ug + " - " + element.cnpj,
            value: element.id,
          });
        }
      }

      setDataOptions(newDataOffice);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, dataOptions, getOffice, data };
}
