import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  Text,
  useToast,
} from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { Input } from "../../../../../components/form/input";
import { InputPriceAll } from "../../../../../components/form/inputPriceAll";
import { ReviewsDTO } from "../../../../../dtos/ReviewsDTO";
import { formatedDateTimer } from "../../../../../utils/utils";
import { api } from "../../../../../service/api";

type createFormData = {
  completion_date: string;
  price: string;
  review_odometer: string;
  observation?: string;
};

const createFormDataSchema = yup.object().shape({
  completion_date: yup.string().required("Campo obrigatório."),
  price: yup.string().required("Campo obrigatório."),
  review_odometer: yup.string().required("Campo obrigatório."),
  observation: yup.string(),
});

type Props = {
  data: ReviewsDTO;
  is_open: boolean;
  onClose: () => void;
  onCloseSend: () => void;
};
export function ModalFinalizeReview({
  is_open,
  onClose,
  onCloseSend,
  data,
  ...rest
}: Props) {
  const toast = useToast();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createFormDataSchema) });

  const handleSend: SubmitHandler<createFormData> = async (value) => {
    try {
      const dataValues = {
        ...value,
        price: value.price.replace(".", "").replace(",", "."),
      };

      await api.put("company/maintenance/finished/" + data.id, dataValues);

      toast({
        position: "top-right",
        title: "Revisão programada",
        description: "Revisão finalizada com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onCloseSend();
    } catch (error) {
      let errorMessas = error as any;
      toast({
        position: "top-right",
        title: "Revisão programada",
        description: errorMessas.response.data.error
          ? errorMessas.response.data.error
          : "Não foi possível finalizar revisão.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Modal isOpen={is_open} onClose={onClose} isCentered {...rest}>
        <ModalOverlay />
        <ModalContent display="flex" flexDir="column">
          <ModalHeader textAlign="center">FINALIZAR REVISÃO</ModalHeader>
          <ModalCloseButton />
          <ModalBody px={10} pb={10}>
            <Stack
              divider={<StackDivider />}
              spacing="4"
              as="form"
              onSubmit={handleSubmit(handleSend)}
            >
              <Flex justifyContent="space-between">
                <Text fontFamily="heading" fontWeight="bold">
                  TIPO DE REVISÃO
                </Text>
                <Text>{data?.type_review}</Text>
              </Flex>

              <Flex justifyContent="space-between">
                <Text fontFamily="heading" fontWeight="bold">
                  DATA PREVISTA
                </Text>
                <Text>
                  {data.expected_date &&
                    formatedDateTimer(data.expected_date, true)}
                </Text>
              </Flex>

              <Flex justifyContent="space-between">
                <Text fontFamily="heading" fontWeight="bold">
                  ODÔMETRO PREVISTO
                </Text>
                <Text>{data?.expected_odometer}</Text>
              </Flex>

              <Flex gap="2" flexDirection="row">
                <Input
                  type="date"
                  label="REALIZADA EM"
                  size="md"
                  width="100%"
                  {...register("completion_date")}
                  error={errors.completion_date}
                />
                <Controller
                  control={control}
                  name="price"
                  render={({ field: { onChange, value } }) => (
                    <InputPriceAll
                      label="VALOR DA REVISÃO"
                      name="price"
                      width="100%"
                      onChange={onChange}
                      value={value}
                      error={errors.price}
                    />
                  )}
                />
              </Flex>

              <Flex gap="2" flexDirection="column">
                <Input
                  type="number"
                  label="ODÔMETRO NA REVISÃO"
                  size="md"
                  width="100%"
                  textAlign="center"
                  placeholder="Informe o odômetro da revisão"
                  {...register("review_odometer")}
                  error={errors.review_odometer}
                />
              </Flex>

              <Flex gap="2" flexDirection="column">
                <Input
                  label="OBSERVAÇÕES"
                  size="md"
                  width="100%"
                  placeholder="Observações"
                  {...register("observation")}
                  error={errors.observation}
                />
              </Flex>

              <Button
                width="170px"
                type="submit"
                bg="green.600"
                color="white"
                alignSelf="flex-end"
                height={10}
                _hover={{
                  bg: "green.800",
                }}
                mt="6"
                size="lg"
                isLoading={isSubmitting}
              >
                Finalizar
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
