import axios from "axios";
import { useState } from "react";

interface IZipCode {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

export function useZipCode() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IZipCode>({} as IZipCode);

  const getZipCode = async (zipcode: string): Promise<IZipCode> => {
    try {
      const newZipCode = zipcode.replace("-", "");
      setIsLoading(true);
      const response = await axios.get(
        `https://viacep.com.br/ws/${newZipCode}/json/`
      );
      setData(response.data);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, getZipCode, data };
}
