import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  Icon,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { HeaderCard } from "../../../../components/headerCard";
import {
  RiArrowLeftLine,
  RiArrowLeftSLine,
  RiArrowRightLine,
  RiMapPin2Fill,
} from "react-icons/ri";
import { useShowTravel } from "../hooks/useShowTravel";
import { AllLoading } from "../../../../components/allLoading";
import { useParams } from "react-router-dom";
import { formatCurrency, formatedDateTimer } from "../../../../utils/utils";
import { useRef } from "react";
import { useCancelTravel } from "../hooks/useCancelTravel";
import { useFinishTravel } from "../hooks/useFinishTravel";

export function DetailTravel() {
  let { id } = useParams();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const { dataTravel, isLoadingTravel, refetch } = useShowTravel(id as string);

  const cancelRef = useRef<any>(null);

  const { cancelTravel, loadingCancel } = useCancelTravel();
  const { finishTravel, loadingFinish } = useFinishTravel();

  async function updateStatusTravelData(
    idTravel: string,
    status: "calcel" | "finish"
  ) {
    try {
      if (status === "calcel") {
        await cancelTravel(idTravel);
      } else if (status === "finish") {
        await finishTravel(idTravel);
      }

      let titleSuccess =
        status === "calcel"
          ? "Frota em viagem cancelada com sucesso."
          : "Frota em viagem finalizada com sucesso.";

      toast({
        position: "top-right",
        title: "Frota em viagem",
        description: titleSuccess,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      let titleError =
        status === "calcel"
          ? "Não foi possível cancelar a frota em viagem."
          : "Não foi possível finalizar a frota em viagem.";
      toast({
        position: "top-right",
        title: "Frota em viagem",
        description: titleError,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      onClose();
      refetch(id as string);
    }
  }

  return (
    <Box>
      <HeaderCard
        title="FROTAS EM VIAGENS"
        titleButton="Voltar"
        icon={RiArrowLeftSLine}
        link="/frotas-em-viagens"
      />

      {isLoadingTravel && <AllLoading />}

      <Flex px={10} gap={2} mt={5}>
        <Box width="100%">
          <Card height={500}>
            <CardHeader>
              <Heading size="sm">INFORMAÇÕES DA VIAGEM:</Heading>
            </CardHeader>
            <CardBody>
              <Flex
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Text fontWeight="bold" color="#347885" fontSize="large">
                    {dataTravel.type === "outward_trip"
                      ? "SOMENTE IDA"
                      : "IDA E VOLTA"}
                  </Text>
                </Box>
                <Box>
                  <Flex alignItems="center">
                    <Icon
                      fontSize="20px"
                      color="#347885"
                      as={RiArrowRightLine}
                    />
                    <Text fontSize="small" fontWeight="bold">
                      {formatedDateTimer(dataTravel.departure_date, true)}
                    </Text>
                  </Flex>
                  <Flex alignItems="center">
                    <Text fontSize="small" fontWeight="bold">
                      {formatedDateTimer(dataTravel.arrival_date, true)}
                    </Text>
                    <Icon
                      fontSize="20px"
                      color="#347885"
                      as={RiArrowLeftLine}
                    />
                  </Flex>
                </Box>
              </Flex>

              <Flex mt={5} alignItems="center">
                <Icon fontSize="20px" color="#347885" as={RiMapPin2Fill} />
                <Text ml={1}>Local de origem: {dataTravel.origin_travel}</Text>
              </Flex>

              <Flex mt={1} alignItems="center">
                <Icon fontSize="20px" color="#347885" as={RiMapPin2Fill} />
                <Text ml={1}>
                  Local destino: {dataTravel.destination_travel}
                </Text>
              </Flex>

              <Text mt={2}>PLACA: {dataTravel?.car_fleet?.plaque}</Text>
              <Text mt={1}>ODÔMETRO: {dataTravel.arrival_odometer}</Text>
              <Text mt={1}>MOTORISTA: {dataTravel?.motorist?.name}</Text>
              <Text mt={1}>MOTIVO: Outros</Text>

              <Flex mt={5} alignItems="center" justifyContent="center">
                <Text fontWeight="bold" color="#347885" fontSize="x-large">
                  Status da viagem:
                </Text>
                {statusTravel(dataTravel.status)}
              </Flex>

              {dataTravel.status === "progress" && (
                <Box
                  mt={10}
                  display="flex"
                  width="100%"
                  alignItems="center"
                  justifyContent="center"
                  gap={2}
                >
                  <Button
                    type="submit"
                    height={10}
                    colorScheme="red"
                    mt="6"
                    size="lg"
                    onClick={() => {
                      onOpen();
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    bg="green.600"
                    color="white"
                    alignSelf="flex-end"
                    height={10}
                    _hover={{
                      bg: "green.800",
                    }}
                    mt="6"
                    size="lg"
                    isLoading={loadingFinish}
                    onClick={() => {
                      updateStatusTravelData(dataTravel.id, "finish");
                    }}
                  >
                    Finalizar
                  </Button>
                </Box>
              )}
            </CardBody>
          </Card>
        </Box>
        <Box width="100%">
          <Card height={500}>
            <CardHeader>
              <Heading size="sm">ABASTECIMENTOS:</Heading>
            </CardHeader>
            {dataTravel.requisitions && dataTravel.requisitions.length > 0 && (
              <>
                <CardBody height={400} overflow="auto">
                  {dataTravel.requisitions.map((item, index) => {
                    return (
                      <Box
                        key={index}
                        width="100%"
                        height="105px"
                        backgroundColor="green.400"
                        borderWidth={1}
                        borderColor="green.600"
                        borderRadius={10}
                        mb={5}
                        p={2}
                      >
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Text color="white">
                            DATA: {formatedDateTimer(item.end_date, true)}
                          </Text>

                          <Text color="white">
                            PRODUTO: {item.product_name}
                          </Text>
                        </Flex>
                        <Flex
                          mt={1}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Text color="white">
                            POSTO: {item.furnisher_name}
                          </Text>
                          <Text color="white">
                            ODÔMETRO: {item.end_odometer}
                          </Text>
                        </Flex>
                        <Flex
                          mt={1}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Text color="white">
                            QTD: {formatCurrency(item.quantity_applied)}
                          </Text>
                          <Text color="white">
                            P. Unitário: R$ {formatCurrency(item.unitary_price)}
                          </Text>
                          <Text color="white">
                            Valor Total: R$ {formatCurrency(item.total_price)}
                          </Text>
                        </Flex>
                      </Box>
                    );
                  })}
                </CardBody>

                <CardFooter>
                  <Flex
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Flex alignItems="center" justifyContent="center">
                      <Text
                        fontWeight="bold"
                        color="#347885"
                        fontSize="x-large"
                      >
                        Quantidade Total:
                      </Text>
                      <Text ml={1} fontSize="x-large">
                        {formatCurrency(
                          calcTotalQuantity(dataTravel.requisitions)
                        )}
                      </Text>
                    </Flex>
                    <Flex alignItems="center" justifyContent="center">
                      <Text
                        fontWeight="bold"
                        color="#347885"
                        fontSize="x-large"
                      >
                        Valor Total:
                      </Text>
                      <Text ml={1} fontSize="x-large">
                        R${" "}
                        {formatCurrency(
                          calcTotalPrice(dataTravel.requisitions)
                        )}
                      </Text>
                    </Flex>
                  </Flex>
                </CardFooter>
              </>
            )}

            {(!dataTravel.requisitions ||
              dataTravel?.requisitions.length === 0) && (
              <Flex flex={1} alignItems="center" justifyContent="center">
                <Text>Sem histórico de abastecimentos</Text>
              </Flex>
            )}
          </Card>
        </Box>
      </Flex>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Cancelar frota em viagem
            </AlertDialogHeader>

            <AlertDialogBody>
              {!loadingCancel && (
                <Text>
                  Deseja cancelar a viagem de{" "}
                  <strong>{dataTravel?.origin_travel}</strong> para{" "}
                  <strong>{dataTravel?.destination_travel}</strong> da frota{" "}
                  <strong>{dataTravel?.car_fleet?.code_car_fleet}</strong>
                </Text>
              )}

              {loadingCancel && (
                <Flex width="100%" alignItems="center" justifyContent="center">
                  <Spinner
                    color="green.600"
                    emptyColor="white"
                    thickness="4px"
                    size="xl"
                    h={50}
                    w={50}
                  />
                </Flex>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              {!loadingCancel && (
                <>
                  <Button
                    colorScheme="red"
                    onClick={() => {
                      updateStatusTravelData(dataTravel?.id, "calcel");
                    }}
                  >
                    Sim
                  </Button>
                  <Button ml={3} onClick={onClose}>
                    Nāo
                  </Button>
                </>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}

function statusTravel(status: string) {
  if (status === "finished") {
    return (
      <Flex
        ml={2}
        bg="green.700"
        width="130px"
        height="30px"
        alignItems="center"
        justifyContent="center"
        borderRadius={10}
        borderWidth={1}
        borderColor="green.400"
      >
        <Text color="white" fontWeight="bold">
          Finalizada
        </Text>
      </Flex>
    );
  }

  if (status === "canceled") {
    return (
      <Flex
        ml={2}
        bg="red.600"
        width="130px"
        height="30px"
        alignItems="center"
        justifyContent="center"
        borderRadius={10}
        borderWidth={1}
        borderColor="red.800"
      >
        <Text color="white" fontWeight="bold">
          Cancelada
        </Text>
      </Flex>
    );
  }

  if (status === "progress") {
    return (
      <Flex
        ml={2}
        bg="gray.600"
        width="130px"
        height="30px"
        alignItems="center"
        justifyContent="center"
        borderRadius={10}
        borderWidth={1}
        borderColor="gray.800"
      >
        <Text color="white" fontWeight="bold">
          Andamento
        </Text>
      </Flex>
    );
  }
}

function calcTotalQuantity(requisitions: any) {
  let quantity = 0;
  for (let index = 0; index < requisitions.length; index++) {
    quantity += requisitions[index].quantity_applied;
  }

  return quantity;
}

function calcTotalPrice(requisitions: any) {
  let total = 0;
  for (let index = 0; index < requisitions.length; index++) {
    total += requisitions[index].total_price;
  }

  return total;
}
