import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Icon,
  SimpleGrid,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { HeaderCard } from "../../../../components/headerCard";
import { useEffect, useRef, useState } from "react";
import { UsersDTO } from "../../../../dtos/UsersDTO";
import { api } from "../../../../service/api";
import { RiCloseFill, RiEdit2Fill } from "react-icons/ri";

export function ListUsers() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [userInfo, setUserInfo] = useState<UsersDTO>({} as UsersDTO);

  const cancelRef = useRef<any>(null);

  async function deleteUser(userId: string) {
    try {
      setLoadingDelete(true);
      await api.delete(`company/admin/user/delete/${userId}`);
      toast({
        position: "top-right",
        title: "Contrato",
        description: "Usuário removida com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: "top-right",
        title: "Contrato",
        description: "Não foi remover usuário.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoadingDelete(false);
      onClose();
      setUserInfo({} as UsersDTO);
      getUsers();
    }
  }

  const [usersData, setUsersData] = useState<UsersDTO[]>([]);

  async function getUsers() {
    try {
      setLoading(true);
      const response = await api.get("company/admin/user/list");
      setUsersData(response.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Box>
      <HeaderCard
        title="USUÁRIOS"
        titleButton="Novo usuário"
        link="/criar-usuario"
      />

      {loading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={500}
        >
          <Spinner
            color="green.600"
            emptyColor="white"
            thickness="4px"
            size="xl"
            h={100}
            w={100}
          />
        </Box>
      )}

      <SimpleGrid
        flex="1"
        gap="4"
        minChildWidth="320px"
        alignItems="flex-start"
        px={1}
        py={1}
      >
        {usersData.length > 0 && !loading && (
          <Box p="4" borderRadius={8} height={500}>
            <TableContainer>
              <Table variant="striped" colorScheme="gray" size="sm">
                <Thead>
                  <Tr bg="green.600" height="10">
                    <Th color="white" textAlign="center">
                      NOME
                    </Th>
                    <Th color="white" textAlign="center">
                      EMAIL
                    </Th>
                    <Th color="white" textAlign="center">
                      CELULAR
                    </Th>
                    <Th color="white" textAlign="center">
                      ACESSO PAINEL
                    </Th>
                    <Th color="white" textAlign="center">
                      ACESSO APP
                    </Th>
                    <Th color="white" textAlign="center">
                      TIPO
                    </Th>
                    <Th color="white" textAlign="center">
                      AÇÕES
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {usersData.map((item, index) => (
                    <Tr key={index}>
                      <Td textAlign="center">{item.name}</Td>
                      <Td textAlign="center">{item.email}</Td>
                      <Td textAlign="center">{item.cellphone}</Td>
                      <Td textAlign="center">
                        {item.is_panel ? "Sim" : "Não"}
                      </Td>
                      <Td textAlign="center">
                        {item.is_panel ? "Sim" : "Não"}
                      </Td>
                      <Td textAlign="center">
                        {item.state === "admin" ? "ADMIN" : "USUÁRIO"}
                      </Td>
                      <Td textAlign="center" width="10">
                        <Button
                          as="a"
                          size="sm"
                          fontSize="sm"
                          href={`/atualizar-usuario/${item.id}`}
                        >
                          <Icon fontSize="16px" as={RiEdit2Fill} />
                        </Button>
                        <Button
                          size="sm"
                          ml={1}
                          fontSize="sm"
                          onClick={() => {
                            setUserInfo(item);
                            onOpen();
                          }}
                        >
                          <Icon fontSize="16px" as={RiCloseFill} />
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </SimpleGrid>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Remover usuário
            </AlertDialogHeader>

            <AlertDialogBody>
              {!loadingDelete && (
                <Text>
                  Deseja remover o usuário: <strong>{userInfo.name}</strong>
                </Text>
              )}

              {loadingDelete && (
                <Flex width="100%" alignItems="center" justifyContent="center">
                  <Spinner
                    color="green.600"
                    emptyColor="white"
                    thickness="4px"
                    size="xl"
                    h={50}
                    w={50}
                  />
                </Flex>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              {!loadingDelete && (
                <>
                  <Button
                    colorScheme="red"
                    onClick={() => {
                      deleteUser(userInfo.id);
                    }}
                  >
                    Sim
                  </Button>
                  <Button ml={3} onClick={onClose}>
                    Nāo
                  </Button>
                </>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
