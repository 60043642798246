import { useSearchParams } from "react-router-dom";
import { useAuth } from "../../../../hooks/useAuth";
import { useEffect, useState } from "react";
import { Flex, IconButton } from "@chakra-ui/react";
import { InputFilter } from "../../../../components/filter/inputFilter";
import { RiCloseFill, RiFileExcel2Fill, RiFilterLine } from "react-icons/ri";
import { URL_WEB } from "../../../../utils/utils";

export function FilterMileage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { user } = useAuth();

  const start_date = searchParams.get("start_date");
  const end_date = searchParams.get("search");
  const s = searchParams.get("search");

  const [startDate, setStartDate] = useState(start_date ?? "");
  const [endDate, setEndDate] = useState(end_date ?? "");
  const [search, setSearch] = useState(s ?? "");

  function handleFilter() {
    setSearchParams((state) => {
      if (startDate) {
        state.set("start_date", startDate);
      } else {
        state.delete("start_date");
      }

      if (endDate) {
        state.set("end_date", endDate);
      } else {
        state.delete("end_date");
      }

      if (search) {
        state.set("search", search);
      } else {
        state.delete("search");
      }

      return state;
    });
  }

  function handleClear() {
    setSearchParams((state) => {
      state.delete("start_date");
      setStartDate("");

      state.delete("end_date");
      setEndDate("");

      state.delete("search");
      setSearch("");
      return state;
    });

    window.location.reload();
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (search.length !== 0) {
        handleFilter();
      } else {
        handleFilter();
      }
    }, 300);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Flex gap="1">
      <InputFilter
        width="350px"
        label="PESQUISAR POR PLACA OU CÓDIGO:"
        placeholder="Pesquisar por placa ou código"
        name="search"
        autoComplete="off"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />

      <InputFilter
        width="150px"
        type="date"
        label="DATA INÍCIO:"
        name="start_date"
        value={startDate}
        onChange={(e) => {
          setStartDate(e.target.value);
        }}
      />
      <InputFilter
        width="150px"
        type="date"
        label="DATA FINAL:"
        name="end_date"
        value={endDate}
        onChange={(e) => {
          setEndDate(e.target.value);
        }}
      />

      {startDate && endDate && (
        <>
          <IconButton
            ml={3}
            mt={7}
            aria-label="Filtro"
            icon={<RiFilterLine />}
            onClick={handleFilter}
          />
          <IconButton
            mt={7}
            aria-label="Limpar"
            colorScheme="red"
            variant="outline"
            onClick={handleClear}
            icon={<RiCloseFill />}
          />
          <IconButton
            mt={7}
            aria-label="Excel"
            colorScheme="teal"
            variant="outline"
            as="a"
            target="_blank"
            href={`${URL_WEB}/company/report/mileagecarall?user_id=${
              user.id
            }&start_date=${startDate ?? ""}&end_date=${endDate ?? ""}`}
            icon={<RiFileExcel2Fill />}
          />
        </>
      )}
    </Flex>
  );
}
