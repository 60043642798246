import { useEffect, useState } from "react";
import { api } from "../../../service/api";
import { NotificationDTO } from "../../../dtos/NotificationDTO";

export function useListNotification() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<NotificationDTO[]>([]);

  const getNotification = async () => {
    try {
      setIsLoading(true);
      const response = await api.get("company/notifications/list");
      setData(response.data);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading, getNotification, data };
}
