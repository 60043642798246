import { Box, Checkbox, Spinner } from "@chakra-ui/react";
import { useUpdateStatusCar } from "../../../hooks/useUpdateStatusCar";

type Porps = {
  id: string;
  status: string;
  onChange: () => void;
};
export function UpdateStatus({ id, status, onChange }: Porps) {
  const { loadingStatus, updateStatusCar } = useUpdateStatusCar();

  async function send() {
    const result = await updateStatusCar(id);
    if (result === "ok") {
      onChange();
    }
  }

  return (
    <Box width="10" display="flex" alignItems="center" justifyContent="center">
      {loadingStatus ? (
        <Spinner
          color="green.600"
          emptyColor="white"
          thickness="4px"
          h={5}
          w={5}
        />
      ) : (
        <Checkbox
          margin="auto"
          size="lg"
          colorScheme="green"
          borderColor="gray.600"
          isChecked={status === "active" ? true : false}
          onChange={(e) => {
            send();
          }}
        />
      )}
    </Box>
  );
}
