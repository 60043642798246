import { useState } from "react";
import { api } from "../../../../service/api";
import { PartnerDTO } from "../../../../dtos/PartnerDTO";

export function useListPartner() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<PartnerDTO[]>([]);

  const getPartner = async () => {
    try {
      setIsLoading(true);
      const response = await api.get("/company/partner/list");
      setData(response.data);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, getPartner, data };
}
