import { Navigate, Route, Routes } from "react-router-dom";
import { RecoverPassword } from "../pages/auth/recoverPassword";
import { Login } from "../pages/auth/login";
import { Home } from "../pages/dash/home";
import { AuthRoute } from "./auth-route";

import { NotFound } from "../pages/extra/notFound";
import { UpdatePassword } from "../pages/auth/updatePassword";
import { RegisterAccount } from "../pages/auth/registerAccount";
import { RegisterAccountSuccess } from "../pages/auth/registerAccount/success";
import { Profile } from "../pages/extra/profile";
import { ListUsers } from "../pages/registrations/users/list";
import { CreateUser } from "../pages/registrations/users/create";
import { UpdateUser } from "../pages/registrations/users/edit";
import { ListOffices } from "../pages/registrations/office/list";
import { CreateOffice } from "../pages/registrations/office/create";
import { ShowSignature } from "../pages/extra/signature";
import { ListInvoice } from "../pages/extra/signature/invoice";
import { UpdatePlan } from "../pages/extra/signature/plan";
import { ListMotorist } from "../pages/registrations/motorist/list";
import { CreateMotorist } from "../pages/registrations/motorist/create";
import { EditMotorist } from "../pages/registrations/motorist/edit";
import { ListCar } from "../pages/registrations/car/list";
import { CreateCar } from "../pages/registrations/car/create";
import { ListLocator } from "../pages/registrations/locator/list";
import { CreateLocator } from "../pages/registrations/locator/create";
import { EditLocator } from "../pages/registrations/locator/edit";
import { ListPartner } from "../pages/registrations/partner/list";
import { PartnerContrat } from "../pages/registrations/partner/contract";
import { EditCar } from "../pages/registrations/car/edit";
import { ListRequisition } from "../pages/launch/requisition/list";
import { CreateRequisition } from "../pages/launch/requisition/create";
import { ListFleetMovement } from "../pages/launch/fleetMovement/list";
import { CreateFleetMovement } from "../pages/launch/fleetMovement/create";
import { DetailFleetMovement } from "../pages/launch/fleetMovement/detail";
import { ListStock } from "../pages/launch/stock/list";
import { CreateStock } from "../pages/launch/stock/create";
import { ListTravel } from "../pages/launch/travel/list";
import { DetailTravel } from "../pages/launch/travel/detail";
import { CreateTravel } from "../pages/launch/travel/create";
import { MaintenanceList } from "../pages/launch/maintenance/list";
import { ReviewDetailMaintenance } from "../pages/launch/maintenance/reviewDetail";
import { EditOffice } from "../pages/registrations/office/edit";
import { RequestsIssued } from "../pages/report/requestsIssued";
import { Mileage } from "../pages/report/mileage";
import { MileageDetail } from "../pages/report/mileage/detail";
import { PrivacyPolicy } from "../pages/extra/privacyPolicy";
import { ReportStock } from "../pages/report/stock";
import { StockDetail } from "../pages/report/stock/detail";

export function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" replace />} />
      <Route path="/login" element={<Login />} />
      <Route path="/recuperacao-senha" element={<RecoverPassword />} />
      <Route path="/atualizar-senha/:email" element={<UpdatePassword />} />
      <Route path="/cadastro" element={<RegisterAccount />} />
      <Route path="/cadastro/sucesso" element={<RegisterAccountSuccess />} />
      <Route
        path="/home"
        element={
          <AuthRoute path="/home">
            <Home />
          </AuthRoute>
        }
      />

      <Route
        path="/unidade-de-gestao"
        element={
          <AuthRoute path="/unidade-de-gestao">
            <ListOffices />
          </AuthRoute>
        }
      />

      <Route
        path="/unidade-de-gestao/nova"
        element={
          <AuthRoute path="/unidade-de-gestao">
            <CreateOffice />
          </AuthRoute>
        }
      />

      <Route
        path="/unidade-de-gestao/atualizar/:id"
        element={
          <AuthRoute path="/unidade-de-gestao">
            <EditOffice />
          </AuthRoute>
        }
      />

      <Route
        path="/motorista"
        element={
          <AuthRoute path="/motorista">
            <ListMotorist />
          </AuthRoute>
        }
      />

      <Route
        path="/motorista/novo"
        element={
          <AuthRoute path="/motorista">
            <CreateMotorist />
          </AuthRoute>
        }
      />

      <Route
        path="motorista/atualizar/:id"
        element={
          <AuthRoute path="/motorista">
            <EditMotorist />
          </AuthRoute>
        }
      />

      <Route
        path="/frota"
        element={
          <AuthRoute path="/frota">
            <ListCar />
          </AuthRoute>
        }
      />

      <Route
        path="/frota/novo"
        element={
          <AuthRoute path="/frota">
            <CreateCar />
          </AuthRoute>
        }
      />

      <Route
        path="/frota/atualizar/:id"
        element={
          <AuthRoute path="/frota">
            <EditCar />
          </AuthRoute>
        }
      />

      <Route
        path="/locadores"
        element={
          <AuthRoute path="/locadores">
            <ListLocator />
          </AuthRoute>
        }
      />

      <Route
        path="/locadores/novo"
        element={
          <AuthRoute path="/locadores">
            <CreateLocator />
          </AuthRoute>
        }
      />

      <Route
        path="/locadores/atualizar/:id"
        element={
          <AuthRoute path="/locadores">
            <EditLocator />
          </AuthRoute>
        }
      />

      <Route
        path="/parceiros"
        element={
          <AuthRoute path="/parceiros">
            <ListPartner />
          </AuthRoute>
        }
      />

      <Route
        path="/parceiros/contrato/:id"
        element={
          <AuthRoute path="/parceiros">
            <PartnerContrat />
          </AuthRoute>
        }
      />

      <Route
        path="/usuarios"
        element={
          <AuthRoute path="/usuarios">
            <ListUsers />
          </AuthRoute>
        }
      />

      <Route
        path="/criar-usuario"
        element={
          <AuthRoute path="/usuarios">
            <CreateUser />
          </AuthRoute>
        }
      />

      <Route
        path="/atualizar-usuario/:id"
        element={
          <AuthRoute path="/usuarios">
            <UpdateUser />
          </AuthRoute>
        }
      />

      <Route
        path="/perfil"
        element={
          <AuthRoute path="/perfil">
            <Profile />
          </AuthRoute>
        }
      />

      <Route
        path="/requisicoes"
        element={
          <AuthRoute path="/requisicoes">
            <ListRequisition />
          </AuthRoute>
        }
      />

      <Route
        path="/requisicoes/nova"
        element={
          <AuthRoute path="/requisicoes">
            <CreateRequisition />
          </AuthRoute>
        }
      />

      <Route
        path="/movimentacao-frota"
        element={
          <AuthRoute path="/movimentacao-frota">
            <ListFleetMovement />
          </AuthRoute>
        }
      />

      <Route
        path="/movimentacao-frota/novo"
        element={
          <AuthRoute path="/movimentacao-frota">
            <CreateFleetMovement />
          </AuthRoute>
        }
      />

      <Route
        path="/movimentacao-frota/detalhes/:id"
        element={
          <AuthRoute path="/movimentacao-frota">
            <DetailFleetMovement />
          </AuthRoute>
        }
      />

      <Route
        path="/aquisicoes-de-estoque"
        element={
          <AuthRoute path="/aquisicoes-de-estoque">
            <ListStock />
          </AuthRoute>
        }
      />

      <Route
        path="/aquisicoes-de-estoque/nova"
        element={
          <AuthRoute path="/aquisicoes-de-estoque">
            <CreateStock />
          </AuthRoute>
        }
      />

      <Route
        path="/frotas-em-viagens/nova"
        element={
          <AuthRoute path="/frotas-em-viagens">
            <CreateTravel />
          </AuthRoute>
        }
      />

      <Route
        path="/frotas-em-viagens/datalhes/:id"
        element={
          <AuthRoute path="/frotas-em-viagens">
            <DetailTravel />
          </AuthRoute>
        }
      />

      <Route
        path="/frotas-em-viagens"
        element={
          <AuthRoute path="/frotas-em-viagens">
            <ListTravel />
          </AuthRoute>
        }
      />

      <Route
        path="/revisoes-programadas"
        element={
          <AuthRoute path="/revisoes-programadas">
            <MaintenanceList />
          </AuthRoute>
        }
      />

      <Route
        path="/revisoes-programadas/car/:id"
        element={
          <AuthRoute path="/revisoes-programadas">
            <ReviewDetailMaintenance />
          </AuthRoute>
        }
      />

      <Route
        path="/assinatura"
        element={
          <AuthRoute path="/assinatura">
            <ShowSignature />
          </AuthRoute>
        }
      />

      <Route
        path="/faturas"
        element={
          <AuthRoute path="/assinatura">
            <ListInvoice />
          </AuthRoute>
        }
      />

      <Route
        path="/atualiza-plano"
        element={
          <AuthRoute path="/assinatura">
            <UpdatePlan />
          </AuthRoute>
        }
      />

      <Route
        path="/requisicoes-emitidas/:request_type"
        element={
          <AuthRoute path="/requisicoes-emitidas/entrada">
            <RequestsIssued />
          </AuthRoute>
        }
      />

      <Route
        path="/requisicoes-saidas/:request_type"
        element={
          <AuthRoute path="/requisicoes-saidas/saida">
            <RequestsIssued />
          </AuthRoute>
        }
      />

      <Route
        path="/quilometragem-da-frota"
        element={
          <AuthRoute path="/quilometragem-da-frota">
            <Mileage />
          </AuthRoute>
        }
      />

      <Route
        path="/quilometragem-da-frota/:id"
        element={
          <AuthRoute path="/quilometragem-da-frota">
            <MileageDetail />
          </AuthRoute>
        }
      />

      <Route
        path="/relatorio/estoque"
        element={
          <AuthRoute path="/relatorio/estoque">
            <ReportStock />
          </AuthRoute>
        }
      />

      <Route
        path="/relatorio/estoque/movimentacao/:id"
        element={
          <AuthRoute path="/relatorio/estoque">
            <StockDetail />
          </AuthRoute>
        }
      />

      <Route path="/politica/privacidade" element={<PrivacyPolicy />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
