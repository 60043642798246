import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Icon,
  SimpleGrid,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { HeaderCard } from "../../../../components/headerCard";
import { useListAcquisitionStock } from "../hooks/useListAcquisitionStock";
import { formatCurrency, formatedDateTimer } from "../../../../utils/utils";
import { RiCloseFill } from "react-icons/ri";
import { StockAcquisitionDTO } from "../../../../dtos/StockAcquisitionDTO";
import { useRef, useState } from "react";
import { useDeleteAcquisitionStock } from "../hooks/useDeleteAcquisitionStock";

export function ListStock() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [stockInfo, setStockInfo] = useState<StockAcquisitionDTO>(
    {} as StockAcquisitionDTO
  );

  const { dataList, loading, refetch } = useListAcquisitionStock();
  const { deleteStock, loadingDelete } = useDeleteAcquisitionStock();

  const cancelRef = useRef<any>(null);

  async function deleteStockData(id: string) {
    try {
      await deleteStock(id);

      toast({
        position: "top-right",
        title: "Estoque",
        description: "Aquisição do estoque removido com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: "top-right",
        title: "Estoque",
        description: "Não foi possível remover a aquisição do estoque.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      onClose();
      setStockInfo({} as StockAcquisitionDTO);
      refetch();
    }
  }

  return (
    <Box>
      <HeaderCard
        title="AQUISIÇÕES DE ESTOQUE"
        titleButton="Nova aquisição"
        link="/aquisicoes-de-estoque/nova"
      />

      {loading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={500}
        >
          <Spinner
            color="green.600"
            emptyColor="white"
            thickness="4px"
            size="xl"
            h={100}
            w={100}
          />
        </Box>
      )}

      <SimpleGrid
        flex="1"
        gap="4"
        minChildWidth="320px"
        alignItems="flex-start"
        px={1}
        py={1}
      >
        {dataList.length > 0 && !loading && (
          <Box p="4" borderRadius={8} height={500}>
            <TableContainer>
              <Table variant="striped" colorScheme="gray" size="sm">
                <Thead>
                  <Tr bg="green.600" height="10">
                    <Th color="white" textAlign="center">
                      DATA
                    </Th>
                    <Th color="white" textAlign="center">
                      CÓDIGO
                    </Th>
                    <Th color="white" textAlign="center">
                      PLACA
                    </Th>
                    <Th color="white" textAlign="center">
                      MARCA
                    </Th>
                    <Th color="white" textAlign="center">
                      MODELO
                    </Th>
                    <Th color="white" textAlign="center">
                      PRODUTO
                    </Th>
                    <Th color="white" textAlign="center">
                      QUANTIDADE
                    </Th>
                    <Th color="white" textAlign="center">
                      PREÇO UNITÁRIO
                    </Th>
                    <Th color="white" textAlign="center">
                      TOTAL
                    </Th>
                    <Th color="white" textAlign="center">
                      AÇÕES
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {dataList.map((item, index) => (
                    <Tr key={index}>
                      <Td textAlign="center">
                        {formatedDateTimer(item.created_at.split("T")[0], true)}
                      </Td>
                      <Td textAlign="center">
                        {item.car_fleet.code_car_fleet}
                      </Td>
                      <Td textAlign="center">{item.car_fleet.plaque}</Td>
                      <Td textAlign="center">{item.car_fleet.mark}</Td>
                      <Td textAlign="center">{item.car_fleet.model}</Td>
                      <Td textAlign="center">
                        {item.product_generic.name_generic}
                      </Td>
                      <Td textAlign="center">
                        {formatCurrency(item.quantity)}
                      </Td>
                      <Td textAlign="center">
                        R$ {formatCurrency(item.unit_price)}
                      </Td>
                      <Td textAlign="center">
                        R$ {formatCurrency(item.total_price)}
                      </Td>

                      <Td textAlign="center" width="10">
                        <Button
                          size="sm"
                          fontSize="sm"
                          onClick={() => {
                            setStockInfo(item);
                            onOpen();
                          }}
                        >
                          <Icon fontSize="16px" as={RiCloseFill} />
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </SimpleGrid>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Remover aquisiçao estoque
            </AlertDialogHeader>

            <AlertDialogBody>
              {!loadingDelete && (
                <Text>
                  Deseja remover aquisiçao ao estoque:{" "}
                  <strong>{stockInfo?.car_fleet?.code_car_fleet}</strong>
                </Text>
              )}

              {loadingDelete && (
                <Flex width="100%" alignItems="center" justifyContent="center">
                  <Spinner
                    color="green.600"
                    emptyColor="white"
                    thickness="4px"
                    size="xl"
                    h={50}
                    w={50}
                  />
                </Flex>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              {!loadingDelete && (
                <>
                  <Button
                    colorScheme="red"
                    onClick={() => {
                      deleteStockData(stockInfo?.id);
                    }}
                  >
                    Sim
                  </Button>
                  <Button ml={3} onClick={onClose}>
                    Nāo
                  </Button>
                </>
              )}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
