import { useEffect, useState } from "react";
import request from "axios";

import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  Text,
  useToast,
} from "@chakra-ui/react";
import { InputPriceAll } from "../../../../../components/form/inputPriceAll";
import { formatCurrency } from "../../../../../utils/utils";
import { Input } from "../../../../../components/form/input";
import { RequisitionDTO } from "../../../../../dtos/RequisitionDTO";
import { api } from "../../../../../service/api";

type Props = {
  data: RequisitionDTO;
  is_open: boolean;
  onClose: () => void;
  onCloseSend: () => void;
};
export function ModalFinalizeRequest({
  is_open,
  onClose,
  onCloseSend,
  data,
  ...rest
}: Props) {
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const [price, setPrice] = useState(0);
  const [endOdometer, setEndOdometer] = useState("");
  const [totalPrice, setTotalPrice] = useState("0,00");
  const [totalPriceNumber, setTotalPriceNumber] = useState(0);
  const [quantity, setQuantity] = useState("0,00");

  useEffect(() => {
    if (data.id) {
      setQuantity(data.quantity_solicit.toString());
      setTotalPrice(formatCurrency(data.total_price) as string);
      setTotalPriceNumber(data.total_price);
      setPrice(data.unitary_price);
    }
  }, [data]);

  async function sendRequisition() {
    try {
      setLoading(true);
      const dataBody = {
        quantity_applied: Number(quantity.replace(".", "").replace(",", ".")),
        end_odometer: Number(endOdometer),
        unitary_price: price,
        total_price: totalPriceNumber,
      };

      await api.put("company/requisition/finalize/" + data?.id, dataBody);

      toast({
        position: "top-right",
        title: "Crédito",
        description: "Requisição finalizada com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setEndOdometer("");
      onCloseSend();
    } catch (error) {
      if (request.isAxiosError(error) && error.response) {
        toast({
          position: "top-right",
          title: "Requisição",
          description: error.response.data.error,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          position: "top-right",
          title: "Crédito",
          description: "Não foi possível finalizar requisição.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Modal isOpen={is_open} onClose={onClose} isCentered {...rest}>
        <ModalOverlay />
        <ModalContent display="flex" flexDir="column">
          <ModalHeader textAlign="center">FINALIZAR REQUISICÃO</ModalHeader>
          <ModalCloseButton />
          <ModalBody px={10} pb={10}>
            <Stack divider={<StackDivider />} spacing="4">
              <Flex justifyContent="space-between">
                <Text fontFamily="heading" fontWeight="bold">
                  N REQUISIÇÃO
                </Text>
                <Text>{data?.code_requisition}</Text>
              </Flex>

              <Flex justifyContent="space-between">
                <Text fontFamily="heading" fontWeight="bold">
                  CÓDIGO
                </Text>
                <Text>{data?.fleet_code}</Text>
              </Flex>

              <Flex justifyContent="space-between">
                <Text fontFamily="heading" fontWeight="bold">
                  PLACA
                </Text>
                <Text>{data?.plaque}</Text>
              </Flex>

              <Flex justifyContent="space-between">
                <Text fontFamily="heading" fontWeight="bold">
                  PRODUTO
                </Text>
                <Text>{data?.product_name}</Text>
              </Flex>

              <Flex gap="2" flexDirection="column">
                <Input
                  type="number"
                  label="ODÔMETRO"
                  size="md"
                  name="end_odometer"
                  width="100%"
                  textAlign="center"
                  fontWeight="bold"
                  placeholder="000"
                  value={endOdometer}
                  onChange={(item) => {
                    setEndOdometer(item.target.value);
                  }}
                />
              </Flex>

              <Flex gap="2" flexDirection="column">
                <InputPriceAll
                  label="QUANTIDADE"
                  name="plan_id"
                  width="100%"
                  onChange={(item) => {
                    const qtd = item.target.value;
                    setQuantity(qtd);
                    let newAtd = Number(qtd.replace(".", "").replace(",", "."));
                    let newPrice = Number(price);
                    let newTotalPrice = newAtd * newPrice;
                    setTotalPriceNumber(newTotalPrice);
                    setTotalPrice(formatCurrency(newTotalPrice) as string);
                  }}
                  value={quantity}
                />
              </Flex>
              <Flex gap="2">
                <Input
                  label="PREÇO UNITÁRIO"
                  size="md"
                  name="plan_id"
                  width="100%"
                  textAlign="center"
                  isDisabled
                  fontWeight="bold"
                  placeholder="0,00"
                  value={formatCurrency(price)}
                />

                <Input
                  label="VALOR TOTAL"
                  size="md"
                  name="plan_id"
                  width="100%"
                  textAlign="center"
                  isDisabled
                  fontWeight="bold"
                  placeholder="0,00"
                  value={totalPrice}
                />
              </Flex>
              <Button
                width="170px"
                type="submit"
                bg="green.600"
                color="white"
                alignSelf="flex-end"
                height={10}
                _hover={{
                  bg: "green.800",
                }}
                mt="6"
                size="lg"
                isDisabled={totalPrice === "0,0" || endOdometer === ""}
                onClick={sendRequisition}
                isLoading={loading}
              >
                Finalizar
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
