import { ReactNode, createContext, useEffect, useState } from "react";
import { Cookies } from "react-cookie-consent";
import { api } from "../service/api";

//TODO: Mudar para DTO

type UserDto = {
  id: string;
  name: string;
  email: string;
  type: string;
  image: string;
};

type SignInCredentias = {
  email: string;
  password: string;
};

type AuthContextProviderProps = {
  children: ReactNode;
};

type AuthContextData = {
  signIn(credentias: SignInCredentias): Promise<void>;
  logout(): void;
  user: UserDto;
  isAuthenticated: boolean;
  isAtuhLoading: boolean;
};

export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: AuthContextProviderProps) {
  const [user, setUser] = useState<UserDto>({} as UserDto);
  const [isAtuhLoading, setIsAtuhLoading] = useState(true);

  const isAuthenticated = !!user.id;

  async function signIn({ email, password }: SignInCredentias): Promise<void> {
    try {
      const response = await api.post("company/login", {
        email: email,
        password: password,
        device_name: "admin-company-" + email,
      });

      const token = response.data.token;

      Cookies.set("entankcompany.token", token);

      api.defaults.headers["Authorization"] = `Bearer ${token}`;

      setUser(response.data);
    } catch (error) {
      throw error;
    }
  }

  function logout() {
    Cookies.remove("entankcompany.token");
    setUser({} as UserDto);
  }

  async function getUser() {
    const token = Cookies.get("entankcompany.token");
    if (token && !user.id) {
      setIsAtuhLoading(true);
      const response = await api.get("company/user/show");
      setUser(response.data);
    }
    setIsAtuhLoading(false);
  }

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{ signIn, isAuthenticated, user, isAtuhLoading, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
}
