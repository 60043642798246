import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { RiArrowLeftSLine } from "react-icons/ri";
import { HeaderCard } from "../../../../components/headerCard";
import { useNavigate } from "react-router-dom";
import { AllLoading } from "../../../../components/allLoading";
import { InputLineMask } from "../../../../components/form/inputLineMask";
import { InputLine } from "../../../../components/form/InputLine";
import { InputPrice } from "../../../../components/form/inputPrice";
import { useEffect, useState } from "react";
import { api } from "../../../../service/api";
import { CnpjDTO } from "../../../../dtos/CnpjDTO";
import { useListCarGroup } from "../../requisition/hooks/useListCarGroup";
import { InputSelect } from "../../../../components/form/inputSelect";
import { useListMotoristCar } from "../../requisition/hooks/useListMotoristCar";
import { useListProductGeneric } from "../hooks/useListProductGeneric";
import { formatCurrency } from "../../../../utils/utils";

type createFormData = {
  document_number: string;
  company_name: string;
  car_fleet_id: string;
  initial_odometer: string;
  product_generic_id: string;
  motorist_id: string;
  quantity: string;
  unit_price: string;
  total_price: string;
  observation?: string;
};

const createFormDataSchema = yup.object().shape({
  document_number: yup.string().required("Campo obrigatório."),
  company_name: yup.string().required("Campo obrigatório."),
  car_fleet_id: yup.string().required("Campo obrigatório."),
  initial_odometer: yup.string().required("Campo obrigatório."),
  product_generic_id: yup.string().required("Campo obrigatório."),
  motorist_id: yup.string().required("Campo obrigatório."),
  quantity: yup.string().required("Campo obrigatório."),
  unit_price: yup.string().required("Campo obrigatório."),
  total_price: yup.string().required("Campo obrigatório."),
  observation: yup.string(),
});

export function CreateStock() {
  const toast = useToast();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createFormDataSchema) });

  const { getListCarGroup, dataOptionsCar, isLoadingCar } = useListCarGroup();

  const { getMotoristCar, dataOptionsMotorist, isLoadingMotorist } =
    useListMotoristCar();

  const { getListProductGeneric, dataOptionsProduct, isLoadingProduct } =
    useListProductGeneric();

  async function getCompany(cnpj: string) {
    try {
      if (cnpj.length < 18) return;
      setLoading(true);
      const response = await api.post("company/cnpj/index", { cnpj });
      const data: CnpjDTO = response.data;

      setValue("company_name", data.company_name);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  const documentNumber = watch("document_number");

  useEffect(() => {
    getCompany(documentNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentNumber]);

  useEffect(() => {
    getListCarGroup("Comboio");
    getMotoristCar();
    getListProductGeneric();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const quantityProduct = watch("quantity");
  const unitPrice = watch("unit_price");

  function calculationTotal() {
    if (quantityProduct === undefined || quantityProduct === null) return;
    if (unitPrice === undefined || unitPrice === null) return;

    if (quantityProduct !== "0,00" && unitPrice !== "0,00") {
      const qtd = Number(quantityProduct.replace(".", "").replace(",", "."));
      const price = Number(unitPrice.replace(".", "").replace(",", "."));
      let newTotalPrice = qtd * price;
      let totalPriceFormat = formatCurrency(newTotalPrice) as string;
      setValue("total_price", totalPriceFormat);
    }
  }

  useEffect(() => {
    calculationTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantityProduct, unitPrice]);

  const handleSend: SubmitHandler<createFormData> = async (value) => {
    try {
      const data = {
        ...value,
        quantity: value.quantity.replace(".", "").replace(",", "."),
        unit_price: value.unit_price.replace(".", "").replace(",", "."),
        total_price: value.total_price.replace(".", "").replace(",", "."),
      };

      await api.post("/company/stock/acquisition/create", data);

      toast({
        position: "top-right",
        title: "Produto",
        description: "Aquisição ao estoque cadastrado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      navigate("/aquisicoes-de-estoque");
    } catch (error) {
      toast({
        position: "top-right",
        title: "Produto",
        description: "Não foi possível cadastrar Aquisição ao estoque.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <HeaderCard
        title="AQUISIÇÃO DE ESTOQUE"
        titleButton="Voltar"
        icon={RiArrowLeftSLine}
        link="/aquisicoes-de-estoque"
      />

      {(loading || isLoadingCar || isLoadingMotorist) && <AllLoading />}

      <Flex justifyContent="center" width="100%">
        <Box>
          <Flex
            px={10}
            py={5}
            width="100%"
            flexDirection="column"
            as="form"
            onSubmit={handleSubmit(handleSend)}
          >
            <Flex flex={1} alignItems="center" justifyContent="space-between">
              <Box
                p="4"
                bg="green.50"
                minHeight={370}
                borderRadius={8}
                width="879px"
              >
                <Text fontWeight="bold" mb="4" color="green.800">
                  INFORMAÇÕES DA AQUISICÃO:
                </Text>

                <Flex gap="2">
                  <InputLineMask
                    mask="99.999.999/9999-99"
                    autoComplete="off"
                    width="200px"
                    label="CNPJ"
                    placeholder="Informe o CNPJ do fornecedor"
                    {...register("document_number")}
                    error={errors.document_number}
                  />

                  <InputLine
                    autoComplete="off"
                    width="640px"
                    label="FORNECEDOR"
                    placeholder="Informe o fornecedor"
                    isDisabled
                    {...register("company_name")}
                    error={errors.company_name}
                  />
                </Flex>

                <Flex gap="2" mt={5}>
                  <Controller
                    control={control}
                    name="car_fleet_id"
                    render={({ field: { onChange, value } }) => (
                      <InputSelect
                        label="VEÍCULO DA FROTA"
                        name="car_fleet_id"
                        isLoading={isLoadingCar}
                        options={dataOptionsCar}
                        width="410px"
                        placeholder="Selecione o veículo da frota"
                        onChange={(item) => {
                          onChange(item.value);
                        }}
                        error={errors.car_fleet_id}
                      />
                    )}
                  />

                  <InputLine
                    autoComplete="off"
                    width="210px"
                    label="ODÔMETRO ATUAL"
                    type="number"
                    placeholder="Informe o odômetro atual"
                    textAlign="center"
                    {...register("initial_odometer")}
                    error={errors.initial_odometer}
                  />

                  <Controller
                    control={control}
                    name="product_generic_id"
                    render={({ field: { onChange, value } }) => (
                      <InputSelect
                        label="PRODUTO"
                        name="product_generic_id"
                        isLoading={isLoadingProduct}
                        options={dataOptionsProduct}
                        width="210px"
                        placeholder="Selecione o produto"
                        onChange={(item) => {
                          onChange(item.value);
                        }}
                        error={errors.product_generic_id}
                      />
                    )}
                  />
                </Flex>

                <Flex gap="2" mt={5}>
                  <Controller
                    control={control}
                    name="quantity"
                    render={({ field: { onChange, value } }) => (
                      <InputPrice
                        width="200px"
                        name="quantity"
                        label="QUANTIDADE"
                        value={value}
                        onChange={onChange}
                        error={errors.quantity}
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="unit_price"
                    render={({ field: { onChange, value } }) => (
                      <InputPrice
                        width="200px"
                        name="unit_price"
                        label="PREÇO UNITÁRIO"
                        value={value}
                        onChange={onChange}
                        error={errors.unit_price}
                      />
                    )}
                  />

                  <InputLine
                    autoComplete="off"
                    width="210px"
                    label="VALOR TOTAL"
                    textAlign="center"
                    isDisabled
                    placeholder="0,00"
                    {...register("total_price")}
                    error={errors.total_price}
                  />

                  <Controller
                    control={control}
                    name="motorist_id"
                    render={({ field: { onChange, value } }) => (
                      <InputSelect
                        label="MOTORISTA"
                        name="motorist_id"
                        isLoading={isLoadingMotorist}
                        options={dataOptionsMotorist}
                        width="210px"
                        placeholder="Selecione motorista"
                        onChange={(item) => {
                          onChange(item.value);
                        }}
                        error={errors.motorist_id}
                      />
                    )}
                  />
                </Flex>

                <Flex mt={5} gap="2">
                  <InputLine
                    autoComplete="off"
                    width="100%"
                    label="OBSERVAÇÕES"
                    placeholder="Informe as observações"
                    {...register("observation")}
                    error={errors.observation}
                  />
                </Flex>
              </Box>
            </Flex>

            <Button
              type="submit"
              bg="green.600"
              color="white"
              alignSelf="flex-end"
              height={10}
              _hover={{
                bg: "green.800",
              }}
              mt="6"
              size="lg"
              isLoading={isSubmitting}
            >
              Salvar
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}
