import { useEffect, useState } from "react";
import { api } from "../../../../service/api";
import { MovementStockDTO } from "../../../../dtos/MovementStockDTO";
import { useSearchParams } from "react-router-dom";

export function useShowStock(id: string) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataList, setDataList] = useState<MovementStockDTO>(
    {} as MovementStockDTO
  );

  const [searchParams] = useSearchParams();

  const status = searchParams.get("status") ?? "";
  const start_date = searchParams.get("start_date") ?? "";
  const end_date = searchParams.get("end_date") ?? "";

  const fetchData = async (id: string) => {
    try {
      setLoading(true);
      const response = await api.get(
        `/company/stock/movement/list/${id}?request_type=${status}&start_date=${start_date}&end_date=${end_date}`
      );
      setDataList(response.data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, status, start_date, end_date]);

  return {
    dataList,
    loading,
    error,
    refetch: fetchData,
  };
}
