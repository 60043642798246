import { useState } from "react";
import { api } from "../../../../service/api";

export function useUpdatePartner() {
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

  const updatePartner = async (id: string, status: string) => {
    try {
      setIsLoadingUpdate(true);
      await api.put("/company/partner/update/" + id, { status });
    } catch (error) {
      throw error;
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  return { isLoadingUpdate, updatePartner };
}
