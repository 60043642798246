import { Button, Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { RiAddLine } from "react-icons/ri";

type Props = {
  title?: string;
  titleButton?: string;
  link?: string;
  isFilter?: boolean;
  isSmall?: boolean;
  isModal?: boolean;
  onPressModal?: () => void;
  children?: React.ReactNode;
  icon?: any;
};
export function HeaderCard({
  children,
  title,
  titleButton,
  link,
  isFilter,
  onPressModal,
  icon = RiAddLine,
  isModal = false,
  isSmall = false,
}: Props) {
  return (
    <Flex
      px={5}
      alignItems="center"
      minHeight={isSmall ? "0px" : "82px"}
      pt={isSmall ? "20px" : "0px"}
      width="100%"
      bg="green.400"
    >
      {!isFilter && (
        <Flex width="100%" justifyContent="space-between">
          <Text fontSize="22px" fontWeight="bold" color="white">
            {title}
          </Text>
          {!isModal && titleButton && (
            <Button
              as="a"
              size="sm"
              fontSize="sm"
              colorScheme="primary"
              leftIcon={<Icon as={icon} />}
              href={link}
            >
              {titleButton}
            </Button>
          )}

          {isModal && titleButton && (
            <Button
              size="sm"
              fontSize="sm"
              colorScheme="primary"
              leftIcon={<Icon as={icon} />}
              onClick={onPressModal}
            >
              {titleButton}
            </Button>
          )}
        </Flex>
      )}

      {isFilter && children}
    </Flex>
  );
}
