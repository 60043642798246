import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import { HeaderCard } from "../../../../components/headerCard";
import { RiArrowLeftSLine } from "react-icons/ri";
import { useSignature } from "../../../../service/signatureService";
import { useEffect, useState } from "react";
import { AllLoading } from "../../../../components/allLoading";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { PlanDTO } from "../../../../dtos/PlanDTO";
import { OptionsDTO } from "../../../../dtos/OptionsDTO";
import { api } from "../../../../service/api";
import { InputFilterSelect } from "../../../../components/filter/inputFilterSelect";
import { formatCurrency } from "../../../../utils/utils";

type createFormData = {
  due_day: number;
  plan_id: string;
};

const list_of_due_dates = [10, 15, 20, 25];

const createFormDataSchema = yup.object().shape({
  due_day: yup.number().required("Campo obrigatório."),
  plan_id: yup.string().required("Campo obrigatório."),
});

export function UpdatePlan() {
  const navigate = useNavigate();
  const toast = useToast();

  const [loadingPlans, setLoadingPlans] = useState(false);

  const [plans, setPlans] = useState<PlanDTO[]>([]);
  const [planSelected, setPlanSelected] = useState<PlanDTO>({} as PlanDTO);

  const [plansOptions, setPlansOptions] = useState<OptionsDTO[]>([]);

  const { getSignature, data, isLoading } = useSignature();

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createFormDataSchema) });

  const handleSend: SubmitHandler<createFormData> = async (value) => {
    try {
      await api.put("company/signature/update/" + data.id, value);

      navigate("/assinatura");

      toast({
        position: "top-right",
        title: "Criar conta",
        description: "Assinatura atualizada com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: "top-right",
        title: "Criar conta",
        description: "Não foi possível atualizar assinatura.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  async function getPlans() {
    try {
      setLoadingPlans(true);
      const response = await api.get("company/plan/index");

      const planData = response.data;

      setPlans(planData);

      const newDataPlan: OptionsDTO[] = [];

      for (let index = 0; index < planData.length; index++) {
        const element = planData[index];
        newDataPlan.push({
          label: element.name,
          value: element.id,
        });
      }

      setPlansOptions(newDataPlan);
    } catch (error) {
    } finally {
      setLoadingPlans(false);
    }
  }

  useEffect(() => {
    getPlans();
  }, []);

  useEffect(() => {
    getSignature();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data && data.id && plansOptions.length > 0) {
      setValue("due_day", data.due_day);
      setValue("plan_id", data.plan.id);
      setPlanSelected({
        id: data.plan.id,
        name: data.plan.name,
        annual_price: data.plan.annual_price,
        monthly_price: data.plan.monthly_price,
        cars_number: data.plan.cars_number,
        description: data.plan.description,
        is_tank_car: false,
        is_travel_fleet: false,
        status: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, plansOptions]);

  return (
    <Box>
      <HeaderCard
        title="ATUALIZAR PLANO"
        titleButton="Voltar"
        icon={RiArrowLeftSLine}
        link="/assinatura"
      />
      {(isLoading || isSubmitting) && <AllLoading />}

      <Box display="flex" alignItems="center" justifyContent="center">
        <Box as="form" onSubmit={handleSubmit(handleSend)}>
          <Box mt={10}>
            <Text fontWeight="bold" mb={2} fontSize="20px" color="gray.600">
              Planos:
            </Text>
            <Controller
              control={control}
              name="plan_id"
              render={({ field: { onChange, value } }) => (
                <InputFilterSelect
                  name="plan_id"
                  isLoading={loadingPlans}
                  options={plansOptions}
                  width="400px"
                  placeholder="Selecione um plano"
                  value={{
                    label:
                      plans.length > 0
                        ? plans.find((p) => p.id === value)?.name
                        : data?.plan?.name,
                    value: value,
                  }}
                  onChange={(item) => {
                    onChange(item.value);

                    const plan = plans.find((p) => p.id === item.value);
                    if (plan) {
                      setPlanSelected(plan);
                    }
                  }}
                  error={errors.plan_id}
                />
              )}
            />
            {planSelected && planSelected.id && (
              <Flex mt={5} alignItems="center" width="400px">
                <Box
                  display="flex"
                  minWidth={82}
                  height={39}
                  bg="green.400"
                  px={5}
                  borderRadius={999}
                  alignItems="center"
                  justifyItems="center"
                >
                  <Text color="white" fontWeight="bold">
                    R$ {formatCurrency(planSelected.monthly_price)}
                  </Text>
                </Box>
                <Text ml={2} fontWeight="bold">
                  {planSelected.cars_number} Veículos
                </Text>
              </Flex>
            )}
          </Box>
          <Box mt={5}>
            <Text fontWeight="bold" mb={2} fontSize="20px" color="gray.600">
              Dia do vencimento:
            </Text>

            <Controller
              control={control}
              name="due_day"
              render={({ field: { onChange, value } }) => (
                <Box>
                  <Flex mt={1} alignItems="center" width="400px" gap={2}>
                    {list_of_due_dates.map((item, index) => (
                      <Box
                        key={index}
                        display="flex"
                        minWidth={50}
                        height={39}
                        bg={item === value ? "green.600" : "green.50"}
                        borderColor="green.600"
                        borderWidth={1}
                        px={5}
                        borderRadius={999}
                        alignItems="center"
                        justifyItems="center"
                        cursor="pointer"
                        _hover={{
                          bg: "green.600",
                          transition: "1s",
                        }}
                        role="group"
                        onClick={() => {
                          onChange(item);
                        }}
                      >
                        <Text
                          color={item === value ? "white" : "green.600"}
                          _groupHover={{
                            color: "white",
                          }}
                          fontWeight="bold"
                        >
                          {item}
                        </Text>
                      </Box>
                    ))}
                  </Flex>
                  <Text color="red.500">{errors.due_day?.message}</Text>
                </Box>
              )}
            />
          </Box>

          <Box mt={10}>
            <Button
              type="submit"
              bg="green.600"
              color="white"
              _hover={{
                bg: "green.800",
              }}
              size="lg"
              width="100%"
            >
              ATUALIZAR
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
