import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Flex } from "@chakra-ui/react";
import { InputFilter } from "../../../../components/filter/inputFilter";

export function FilterMotorist() {
  const [searchParams, setSearchParams] = useSearchParams();

  const s = searchParams.get("search");

  const [search, setSearch] = useState(s ?? "");

  function handleFilter() {
    setSearchParams((state) => {
      if (search) {
        state.set("search", search);
      } else {
        state.delete("search");
      }

      return state;
    });
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (search.length !== 0) {
        handleFilter();
      } else {
        handleFilter();
      }
    }, 300);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Flex gap="1">
      <InputFilter
        width="450px"
        label="PESQUISAR POR NOME:"
        placeholder="Pesquisar por nome"
        name="search"
        autoComplete="off"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
    </Flex>
  );
}
