import { useState } from "react";
import { api } from "../../../../service/api";
import { LocatorDTO } from "../../../../dtos/LocatorDTO";

export function useShowLocator() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<LocatorDTO>({} as LocatorDTO);

  const findLocator = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await api.get("company/locator/show/" + id);
      setData(response.data);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, findLocator, data };
}
