import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { InputFilterSelect } from "../../../../../components/filter/inputFilterSelect";
import { Input } from "../../../../../components/form/input";
import { InputMask } from "../../../../../components/form/inputMask";
import { useEffect, useState } from "react";
import { RequistionTypeCar } from "../../types/requistion-type-car";
import { useListMarkCar } from "../../hooks/useListMarkCar";
import { useListMarkModelCar } from "../../hooks/useListMarkModelCar";

type Props = {
  callBackSuccess: (data: RequistionTypeCar) => void;
};

export function FormUnregisteredVehicle({ callBackSuccess }: Props) {
  const { getMarkCar, dataMark, dataOptionsMark, isLoadingMark } =
    useListMarkCar();

  const { getMarkModelCar, dataOptionsMarkModel, isLoadingMarkModel } =
    useListMarkModelCar();

  const [nameMark, setNameMark] = useState("");
  const [nameModel, setNameModel] = useState("");
  const [plaque, setPlaque] = useState("");
  const [motoristName, setMotoristName] = useState("");

  function send() {
    const data = dataMark.find((it) => it.name === nameMark);

    if (data) {
      callBackSuccess({
        car_fleet_id: "",
        fleet_code: "",
        group_name: data.category,
        mark: nameMark,
        model: nameModel,
        motorist_name: motoristName,
        name: nameModel,
        plaque,
        type_fuel: "all",
      });
    }
  }

  useEffect(() => {
    getMarkCar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Text fontSize="large">Selecione as informações para continuar</Text>
      <Box display="flex" mt={5} width={745} flexDirection="column">
        <Flex gap="2">
          <InputFilterSelect
            colorLabel="#1E293B"
            label="MARCA"
            name="marca"
            isLoading={isLoadingMark}
            options={dataOptionsMark}
            width="100%"
            placeholder="Selecione a maraca"
            onChange={(item) => {
              getMarkModelCar(item.label);
              setNameMark(item.label);
            }}
          />
          <InputFilterSelect
            colorLabel="#1E293B"
            label="MODELO"
            name="modelo"
            isLoading={isLoadingMarkModel}
            options={dataOptionsMarkModel}
            width="100%"
            placeholder="Selecione o modelo"
            onChange={(item) => {
              setNameModel(item.label);
            }}
          />
        </Flex>
        <Flex gap="2" mt={5}>
          <InputMask
            mask="aaa9*99"
            label="PLACA"
            size="md"
            name="plaque"
            width="100%"
            placeholder="Informe a placa"
            value={plaque}
            onChange={(item) => {
              setPlaque(item.target.value);
            }}
          />
          <Input
            label="NOME DO MOTORISTA"
            size="md"
            name="motorist"
            width="100%"
            placeholder="Informe o nome do motorista"
            value={motoristName}
            onChange={(item) => {
              setMotoristName(item.target.value);
            }}
          />
        </Flex>

        <Flex gap="2" mt={5}>
          <Input
            label="OBSERVAÇÕES"
            size="md"
            name="plan_id"
            width="100%"
            placeholder="Observações..."
          />
        </Flex>

        <Button
          type="submit"
          bg="green.600"
          color="white"
          alignSelf="flex-end"
          height={10}
          _hover={{
            bg: "green.800",
          }}
          mt="6"
          size="lg"
          isDisabled={!nameMark || !nameModel || !motoristName}
          onClick={send}
        >
          Continuar
        </Button>
      </Box>
    </Box>
  );
}
