import { Box, Button, Flex, Icon, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { RiCheckFill } from "react-icons/ri";
import { Input } from "../../../../../components/form/input";
import { InputPriceAll } from "../../../../../components/form/inputPriceAll";
import { formatCurrency } from "../../../../../utils/utils";
import { useListProduct } from "../../hooks/useListProduct";
import { LoadingProduct } from "../../components/loading/loading-product";
import { ProductDataDTO } from "../../../../../dtos/ProductDataDTO";

export interface ProductOrServiceInfo {
  furnisher_office_product_id: string;
  product_name: string;
  quantity_solicit: number;
  product_type: string;
  unitary_price: number;
  total_price: number;
}

type Props = {
  furnisher_id: string;
  office_id: string;
  category: string;
  type: string;
  callBackSuccess: (data: ProductOrServiceInfo) => void;
};

export function ProductOrService({
  furnisher_id,
  office_id,
  category,
  type,
  callBackSuccess,
}: Props) {
  const [positionIndex, setPositionIndex] = useState(-1);
  const [price, setPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState("0,0");
  const [quantity, setQuantity] = useState("0,00");
  const [productSelected, setProductSelected] = useState<ProductDataDTO>(
    {} as ProductDataDTO
  );

  const { getProducts, dataProduct, isLoadingProduct } = useListProduct();

  useEffect(() => {
    getProducts({
      furnisher_id,
      office_id,
      category,
      type,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [furnisher_id, category, type]);

  function sendProduct() {
    callBackSuccess({
      furnisher_office_product_id: productSelected.id,
      product_name: productSelected.product_name,
      product_type: type,
      quantity_solicit: Number(quantity.replace(".", "").replace(",", ".")),
      unitary_price: price,
      total_price: Number(totalPrice.replace(".", "").replace(",", ".")),
    });
  }

  return (
    <Box>
      <Text fontSize="large">
        Selecione um produto ou serviço para prosseguir
      </Text>
      <Box mt={5}>
        {isLoadingProduct ? (
          <LoadingProduct />
        ) : (
          <Stack spacing="4">
            {dataProduct.map((item, index) => {
              return (
                <Flex
                  key={index}
                  justifyContent="space-between"
                  height={67}
                  width={745}
                  bg={positionIndex === index ? "green.400" : "green.50"}
                  borderRadius={10}
                  borderWidth={1}
                  borderColor="green.400"
                  alignItems="center"
                  transition="0.5s"
                  px={10}
                  _hover={{
                    cursor: "pointer",
                    borderColor: "green.600",
                    transition: "0.5s",
                  }}
                  onClick={() => {
                    setPositionIndex(index);
                    setPrice(item.price_client);
                    setProductSelected(item);
                  }}
                >
                  <Flex>
                    <Text
                      fontFamily="heading"
                      fontWeight="bold"
                      fontSize="large"
                    >
                      {item.product_name}
                    </Text>

                    <Text fontFamily="heading" ml={5}>
                      UND: {item.unit}
                    </Text>

                    <Text fontFamily="heading" ml={5}>
                      QTD: {item.quantity}
                    </Text>

                    <Text fontFamily="heading" ml={5}>
                      PREÇO UNITÁRIO: R$ {formatCurrency(item.price_client)}
                    </Text>
                  </Flex>

                  <Icon fontSize="16px" as={RiCheckFill} />
                </Flex>
              );
            })}
          </Stack>
        )}

        {positionIndex >= 0 && (
          <Flex gap="2" mt={5}>
            <InputPriceAll
              label="QUANTIDADE"
              name="plan_id"
              width="100%"
              onChange={(item) => {
                const qtd = item.target.value;
                setQuantity(qtd);
                let newAtd = Number(qtd.replace(".", "").replace(",", "."));
                let newPrice = Number(price);
                let newTotalPrice = newAtd * newPrice;
                setTotalPrice(formatCurrency(newTotalPrice) as string);
              }}
              value={quantity}
            />
            <Input
              label="VALOR TOTAL"
              size="md"
              name="plan_id"
              width="100%"
              textAlign="center"
              isDisabled
              fontWeight="bold"
              placeholder="0,00"
              value={totalPrice}
            />

            <Button
              width="170px"
              type="submit"
              bg="green.600"
              color="white"
              alignSelf="flex-end"
              height={10}
              _hover={{
                bg: "green.800",
              }}
              mt="6"
              size="lg"
              onClick={sendProduct}
            >
              Continuar
            </Button>
          </Flex>
        )}
      </Box>
    </Box>
  );
}
