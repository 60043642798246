import { useState } from "react";
import { api } from "../../../../service/api";

export function useDeleteMotorist() {
  const [loadingDelete, setLoadingDelete] = useState(false);

  const deleteMotorist = async (motoristId: string) => {
    try {
      setLoadingDelete(true);
      await api.delete("company/motorist/delete/" + motoristId);
    } catch (error) {
      throw error;
    } finally {
      setLoadingDelete(false);
    }
  };

  return { loadingDelete, deleteMotorist };
}
