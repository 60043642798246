import { Box, Text } from "@chakra-ui/react";
import React from "react";
import Dropzone from "react-dropzone";

export interface IImage {
  file: any;
  name: string;
  size: string;
  preview: string;
}

type Props = {
  onChange: (value: any) => void;
};

export function InputFileImage({ onChange }: Props) {
  function renderDragMessage(isDragActive: boolean, isDragReject: boolean) {
    if (!isDragActive) {
      return <Text textAlign="center">Selecione uma imagem</Text>;
    }

    if (isDragReject) {
      return <Text textAlign="center">Imagem não suporta</Text>;
    }

    return <Text textAlign="center">Solte s imagem aqui</Text>;
  }

  return (
    <Dropzone
      multiple={false}
      accept={{ "image/*": [".png", ".gif", ".jpeg", ".jpg"] }}
      onDropAccepted={onChange}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <Box
          {...getRootProps()}
          borderColor={
            isDragActive && !isDragReject
              ? "green.400"
              : isDragReject
              ? "red.600"
              : "gray.600"
          }
          display="flex"
          borderWidth={2}
          borderRadius="5px"
          borderStyle="dashed"
          width="100%"
          alignItems="center"
          justifyContent="center"
          height="180px"
          cursor="pointer"
        >
          <input {...getInputProps()} />
          {renderDragMessage(isDragActive, isDragReject)}
        </Box>
      )}
    </Dropzone>
  );
}
