import {
  Flex,
  Icon,
  Text,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from "@chakra-ui/react";
import { RiArrowDownSFill, RiLogoutBoxLine, RiUser3Line } from "react-icons/ri";
import { DrawerNotification } from "../drawerNotification";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { formatedExtenseDate, splitFullname } from "../../utils/utils";
import { NotificationPay } from "../notificationPay";
import { useSignature } from "../../service/signatureService";
import { useEffect } from "react";

export function Header() {
  const { user, logout } = useAuth();

  const navigate = useNavigate();

  const { getSignature, data } = useSignature();

  useEffect(() => {
    getSignature();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const pathUrl = window.location.pathname.replace("/", "");

    if (
      pathUrl !== "assinatura" &&
      pathUrl !== "faturas" &&
      data.status === "disabled"
    ) {
      navigate("/assinatura");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Flex direction="column">
      {data.id && data.status === "disabled" && (
        <NotificationPay linkBillet={"test"} linkContact={"test"} />
      )}
      <Flex
        w="100%"
        as="header"
        h="40"
        mx="auto"
        px="3"
        bg="green.600"
        borderBottomWidth={1}
        alignItems="center"
      >
        <Flex
          h="20"
          alignItems="center"
          mx="auto"
          width="100%"
          alignSelf="flex-start"
          mt={1}
        >
          <Flex direction="column">
            <Text fontWeight="bold" fontSize="26px" color="white">
              Bem vindo, {splitFullname(user.name)}
            </Text>

            <Text color="white" fontWeight="bold">
              {formatedExtenseDate()}
            </Text>
          </Flex>

          <Flex ml="auto" align="center">
            <Menu>
              <MenuButton
                variant="outline"
                border="none"
                px={4}
                py={2}
                transition="all 0.2s"
                _hover={{ bg: "green.600" }}
                _expanded={{ bg: "green.600" }}
                as={Button}
                rightIcon={<Icon color="white" as={RiArrowDownSFill} />}
              >
                <Flex ml="auto" align="center">
                  <Avatar src={user.image} mr={1} size="sm" />
                  <Text mr={2} fontWeight="bold" color="white">
                    {splitFullname(user.name)}
                  </Text>
                </Flex>
              </MenuButton>
              <MenuList p={5}>
                <MenuItem
                  icon={<Icon as={RiUser3Line} />}
                  _hover={{ bg: "gray.50" }}
                  _focus={{ bg: "gray.50" }}
                  as="a"
                  href="/perfil"
                >
                  Perfil
                </MenuItem>
                <MenuItem
                  icon={<Icon as={RiLogoutBoxLine} />}
                  _hover={{ bg: "gray.50" }}
                  _focus={{ bg: "gray.50" }}
                  onClick={() => {
                    logout();
                    navigate("/login");
                  }}
                >
                  Sair
                </MenuItem>
              </MenuList>
            </Menu>

            <DrawerNotification />
          </Flex>
        </Flex>
      </Flex>

      <Flex
        w="100%"
        as="header"
        h="40"
        mx="auto"
        bg="green.600"
        borderBottomWidth={1}
        alignItems="center"
        borderBottomRadius={600}
        mt={-1}
      ></Flex>
    </Flex>
  );
}
