import {
  Box,
  // Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  // HStack,
  Icon,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { RiNotificationLine } from "react-icons/ri";
import { lmWord } from "../../utils/utils";
import { useListNotification } from "./hooks/useListNotification";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { api } from "../../service/api";

export function DrawerNotification() {
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, isLoading } = useListNotification();
  const [isClosetNotification, setIsClosetNotification] = useState(false);

  const handleClick = () => {
    onOpen();
    setIsClosetNotification(true);
    readNotification();
  };

  async function readNotification() {
    try {
      api.put("company/notifications/read");
    } catch (error) {}
  }

  return (
    <>
      <Button
        onClick={() => handleClick()}
        bg="transparent"
        border="none"
        _hover={{
          bg: "green.400",
        }}
        position="relative"
      >
        <Icon color="white" fontSize={25} as={RiNotificationLine} />
        {!isLoading && data.length > 0 && !isClosetNotification && (
          <Box
            display="flex"
            position="absolute"
            width="26px"
            height="26px"
            top={1}
            right={1}
            bg="#BA4B4B"
            borderRadius="13px"
            alignItems="center"
            justifyContent="center"
          >
            <Text color="white" fontSize="12px">
              {data.length}
            </Text>
          </Box>
        )}
      </Button>

      <Drawer onClose={onClose} isOpen={isOpen} size={"xs"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Central de notificações</DrawerHeader>
          <DrawerBody>
            {!isLoading &&
              data.length > 0 &&
              data.map((item, index) => (
                <Box
                  key={index}
                  width="100%"
                  borderRadius={5}
                  bg="green.400"
                  transition="all 0.5s"
                  _hover={{ bg: "green.600", cursor: "pointer" }}
                  mb={1}
                  onClick={() => {
                    if (item.path_url) {
                      navigate(item.path_url);
                    }
                  }}
                >
                  <HStack alignItems="center" height="70px" px={2}>
                    <Box width="30px" textAlign="center">
                      <Icon color="green.800" as={RiNotificationLine} />
                    </Box>
                    <Text>{lmWord(item.description, 60)}</Text>
                  </HStack>
                </Box>
              ))}

            {!isLoading && data.length <= 0 && (
              <Text textAlign="center">Sem notificações.</Text>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
