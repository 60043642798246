import { useEffect, useState } from "react";
import { api } from "../../../../service/api";
import { MotoristDTO } from "../../../../dtos/MotoristDTO";
import { useSearchParams } from "react-router-dom";

export function useListMotorist() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<MotoristDTO[]>([]);

  const [searchParams] = useSearchParams();

  const search = searchParams.get("search") ?? "";

  const getMotorist = async () => {
    try {
      setIsLoading(true);
      const response = await api.get(`company/motorist/list?search=${search}`);
      setData(response.data);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMotorist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return { isLoading, getMotorist, data };
}
