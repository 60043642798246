import { useState } from "react";
import { api } from "../../../../service/api";
import { FleetMovementDTO } from "../../../../dtos/FleetMovementDTO";

export function useListMovement() {
  const [isLoadingMovement, setIsLoadingMovement] = useState(false);
  const [dataMovement, setDataMovement] = useState<FleetMovementDTO[]>([]);

  const getMovement = async () => {
    try {
      setIsLoadingMovement(true);
      const response = await api.get("company/frota/movement/list");
      setDataMovement(response.data);
    } catch (error) {
      throw error;
    } finally {
      setIsLoadingMovement(false);
    }
  };

  return { isLoadingMovement, getMovement, dataMovement };
}
