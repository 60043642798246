import {
  Box,
  Button,
  Flex,
  Icon,
  SimpleGrid,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { HeaderCard } from "../../../components/headerCard";
import { formatCurrency, lmWord } from "../../../utils/utils";
import { RiAlignJustify } from "react-icons/ri";
import { useListStock } from "./hooks/useListStock";

export function ReportStock() {
  const { dataList, loading } = useListStock();

  return (
    <Box>
      <HeaderCard isFilter>
        <Box height="120px" flexDirection="column" width="100%">
          <Flex
            mt={5}
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          ></Flex>
        </Box>
      </HeaderCard>

      {loading && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={500}
        >
          <Spinner
            color="green.600"
            emptyColor="white"
            thickness="4px"
            size="xl"
            h={100}
            w={100}
          />
        </Box>
      )}

      <SimpleGrid
        flex="1"
        gap="4"
        minChildWidth="320px"
        alignItems="flex-start"
        px={1}
        py={1}
      >
        {dataList.length > 0 && !loading && (
          <Box p="4" borderRadius={8} height={500} overflow="scroll">
            <TableContainer>
              <Table variant="striped" colorScheme="gray" size="sm">
                <Thead>
                  <Tr bg="green.600" height="10">
                    <Th color="white" textAlign="center">
                      PRODUTO
                    </Th>
                    <Th color="white" textAlign="center">
                      CÓDIGO
                    </Th>
                    <Th color="white" textAlign="center">
                      MARCA
                    </Th>
                    <Th color="white" textAlign="center">
                      MODELO
                    </Th>
                    <Th color="white" textAlign="center">
                      ESTOQUE INCIAL
                    </Th>
                    <Th color="white" textAlign="center">
                      ESTOQUE ATUAL
                    </Th>
                    <Th color="white" textAlign="center">
                      VALOR DO ESTOQUE
                    </Th>
                    <Th color="white" textAlign="center">
                      ENTRADA
                    </Th>
                    <Th color="white" textAlign="center">
                      SAIDA
                    </Th>

                    <Th color="white" textAlign="center">
                      AÇÕES
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {dataList.map((item, index) => (
                    <Tr key={index}>
                      <Td textAlign="center">
                        {item.product_generic.name_generic}
                      </Td>
                      <Td textAlign="center">{item.car_fleet.plaque}</Td>
                      <Td textAlign="center">
                        {lmWord(item.car_fleet.mark, 10)}
                      </Td>
                      <Td textAlign="center">
                        {lmWord(item.car_fleet.model, 10)}
                      </Td>
                      <Td textAlign="center" color="blue">
                        {formatCurrency(item.initial_stock)}
                      </Td>
                      <Td textAlign="center" color="blue">
                        {formatCurrency(
                          stockActual(
                            item.requisition_entry_sum_quantity_applied,
                            item.requisition_exit_sum_quantity_applied
                          ) ?? item.actual_stock
                        )}
                      </Td>
                      <Td textAlign="center" color="blue">
                        {formatCurrency(
                          priceActual(
                            item.requisition_entry_sum_total_price,
                            item.requisition_exit_sum_total_price
                          ) ?? item.value_stock
                        )}
                      </Td>
                      <Td textAlign="center" color="blue">
                        {formatCurrency(
                          item.requisition_entry_sum_quantity_applied ?? item.actual_stock
                        )}
                      </Td>
                      <Td textAlign="center" color="blue">
                        {formatCurrency(
                          item.requisition_exit_sum_quantity_applied ?? 0
                        )}
                      </Td>

                      <Td textAlign="center" width="10">
                        <Button
                          size="sm"
                          fontSize="sm"
                          as="a"
                          href={`/relatorio/estoque/movimentacao/${item.id}`}
                        >
                          <Icon fontSize="16px" as={RiAlignJustify} />
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </SimpleGrid>
    </Box>
  );
}

function stockActual(
  entryQuantity: number | null,
  exitQuantity: number | null
) {
  if (entryQuantity && entryQuantity > 0 && exitQuantity && exitQuantity > 0) {
    return entryQuantity - exitQuantity;
  }

  let newEntryQuantity = entryQuantity && entryQuantity > 0 ? entryQuantity : 0;
  let newExitQuantity = exitQuantity && exitQuantity > 0 ? exitQuantity : 0;

  return newEntryQuantity - newExitQuantity;
}

function priceActual(
  entryTotalPrice: number | null,
  exitTotalPrice: number | null
) {
  if (
    entryTotalPrice &&
    entryTotalPrice > 0 &&
    exitTotalPrice &&
    exitTotalPrice > 0
  ) {
    return entryTotalPrice - exitTotalPrice;
  }

  let newEntryTotalPrice = entryTotalPrice && entryTotalPrice > 0 ? entryTotalPrice : 0;
  let newExitTotalPrice = exitTotalPrice && exitTotalPrice > 0 ? exitTotalPrice : 0;

  return newEntryTotalPrice - newExitTotalPrice;
}
