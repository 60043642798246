import { useState } from "react";
import { api } from "../../../../service/api";
import { FleetMovementDTO } from "../../../../dtos/FleetMovementDTO";

export function useShowMovement() {
  const [isLoadingMovement, setIsLoadingMovement] = useState(false);
  const [dataMovement, setDataMovement] = useState<FleetMovementDTO>(
    {} as FleetMovementDTO
  );

  const showMovement = async (id: string) => {
    try {
      setIsLoadingMovement(true);
      const response = await api.get("company/frota/movement/show/" + id);
      setDataMovement(response.data);
    } catch (error) {
      throw error;
    } finally {
      setIsLoadingMovement(false);
    }
  };

  return { isLoadingMovement, showMovement, dataMovement };
}
