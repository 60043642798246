import { useState } from "react";
import { api } from "../../../../service/api";
import { MotoristDTO } from "../../../../dtos/MotoristDTO";

export function useShowMotorist() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<MotoristDTO>({} as MotoristDTO);

  const findMotorist = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await api.get("company/motorist/show/" + id);
      setData(response.data);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, findMotorist, data };
}
