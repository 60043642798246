import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { HeaderCard } from "../../../../components/headerCard";
import { RiArrowLeftSLine } from "react-icons/ri";
import { InputLine } from "../../../../components/form/InputLine";
import { InputLineMask } from "../../../../components/form/inputLineMask";
import { InputSelect } from "../../../../components/form/inputSelect";
import { OfficeSelect } from "../../../../components/officeSelect";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../../../service/api";
import { useShowMotorist } from "../hooks/useShowMotorist";
import { useEffect } from "react";
import { AllLoading } from "../../../../components/allLoading";
import axios from "axios";

type createFormData = {
  office_branch_id: string;
  matriculation: string;
  name: string;
  number_cnh: string;
  validity_cnh: string;
  category_cnh: string;
  status_cnh?: string;
  zip_code?: string;
  street?: string;
  number?: string;
  district?: string;
  complement?: string;
  city?: string;
  state?: string;
  cell_phone?: string;
  cell_whatsapp?: string;
  email?: string;
  observation?: string;
};

const createFormDataSchema = yup.object().shape({
  office_branch_id: yup.string().required("Campo obrigatório."),
  matriculation: yup.string().required("Campo obrigatório."),
  name: yup.string().required("Campo obrigatório."),
  number_cnh: yup.string().required("Campo obrigatório."),
  validity_cnh: yup.string().required("Campo obrigatório."),
  category_cnh: yup.string().required("Campo obrigatório."),
  status_cnh: yup.string(),
  zip_code: yup.string(),
  street: yup.string(),
  number: yup.string(),
  district: yup.string(),
  complement: yup.string(),
  city: yup.string(),
  state: yup.string(),
  cell_phone: yup.string(),
  cell_whatsapp: yup.string(),
  email: yup.string(),
  observation: yup.string(),
});

export function EditMotorist() {
  let { id } = useParams();

  const toast = useToast();
  const navigate = useNavigate();

  const { data, findMotorist, isLoading } = useShowMotorist();

  useEffect(() => {
    if (id) {
      findMotorist(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createFormDataSchema) });

  useEffect(() => {
    if (data) {
      setValue("matriculation", data.matriculation ?? "");
      setValue("name", data.name ?? "");
      setValue("number_cnh", data.number_cnh ?? "");
      setValue("category_cnh", data.category_cnh);
      setValue("validity_cnh", data.validity_cnh ?? "");
      setValue("office_branch_id", data.office_branch_id ?? "");
      setValue("zip_code", data.zip_code ?? "");
      setValue("street", data.street ?? "");
      setValue("number", data.number ?? "");
      setValue("district", data.district ?? "");
      setValue("complement", data.complement ?? "");
      setValue("city", data.city ?? "");
      setValue("cell_phone", data.cell_phone ?? "");
      setValue("cell_whatsapp", data.cell_whatsapp ?? "");
      setValue("observation", data.observation ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  async function getAddress(zipeCodeValue: string) {
    try {
      const zipeCode = zipeCodeValue.replace(".", "").replace("-", "");

      const response = await axios.get(
        `https://viacep.com.br/ws/${zipeCode}/json`
      );
      const data = response.data;
      let city = data.localidade + "-" + data.uf;
      setValue("city", city ?? "");
      setValue("state", data.uf ?? "");
      setValue("street", data.logradouro ?? "");
      setValue("district", data.bairro ?? "");
    } catch (error) {}
  }

  const handleSend: SubmitHandler<createFormData> = async (value) => {
    try {
      await api.put("company/motorist/update/" + id, value);

      toast({
        position: "top-right",
        title: "Motorista",
        description: "Cadastro realizado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      navigate("/motorista");
    } catch (error) {
      toast({
        position: "top-right",
        title: "Motorista",
        description: "Não foi possível cadastrar.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <HeaderCard
        title="ATUALIZAR MOTORISTA"
        titleButton="Voltar"
        icon={RiArrowLeftSLine}
        link="/motorista"
      />

      {isLoading && <AllLoading />}

      <Flex
        as="form"
        px={10}
        py={5}
        direction="column"
        onSubmit={handleSubmit(handleSend)}
      >
        <Flex
          flex={1}
          alignItems="flex-start"
          justifyContent="space-between"
          gap={2}
        >
          <Box
            p="4"
            pb={10}
            bg="green.50"
            minH={260}
            borderRadius={8}
            width="100%"
          >
            <Text fontWeight="bold" mb="4" color="green.800">
              IDENTIFICAÇÃO:
            </Text>

            <Flex gap="2">
              <InputLine
                width="200px"
                label="MATRICULA"
                placeholder="Informe a matricula"
                {...register("matriculation")}
                error={errors.matriculation}
              />

              <InputLine
                width="390px"
                label="NOME COMPLETO"
                placeholder="Informe o nome completo"
                {...register("name")}
                error={errors.name}
              />
            </Flex>

            <Flex gap="2" mt={5}>
              <InputLine
                width="200px"
                label="N DA CNH"
                placeholder="Informe o numero da cnh"
                {...register("number_cnh")}
                error={errors.number_cnh}
              />
              <Controller
                control={control}
                name="category_cnh"
                render={({ field: { onChange, value } }) => (
                  <InputSelect
                    options={[
                      {
                        label: "A",
                        value: "A",
                      },
                      {
                        label: "B",
                        value: "B",
                      },
                      {
                        label: "C",
                        value: "C",
                      },
                      {
                        label: "D",
                        value: "D",
                      },
                      {
                        label: "E",
                        value: "E",
                      },
                      {
                        label: "AB",
                        value: "AB",
                      },
                    ]}
                    onChange={(item) => {
                      onChange(item.value);
                    }}
                    name="category_cnh"
                    width="191px"
                    label="C. CNH"
                    placeholder="Informe a categoria da cnh"
                    error={errors.category_cnh}
                    value={{
                      label: value,
                      value: value,
                    }}
                  />
                )}
              />

              <InputLine
                type="date"
                width="191px"
                label="VALIDADE CNH"
                placeholder="Informe a validade da cnh"
                {...register("validity_cnh")}
                error={errors.validity_cnh}
              />
            </Flex>

            <Flex gap="2" mt={5}>
              <Controller
                control={control}
                name="office_branch_id"
                render={({ field: { onChange, value } }) => (
                  <OfficeSelect
                    name="office_branch_id"
                    width="100%"
                    onChange={(item) => {
                      onChange(item.value);
                    }}
                    value={{
                      label:
                        data &&
                        data.office_branch &&
                        `${data.office_branch.name_ug} - ${data.office_branch.cnpj}`,
                      value: value,
                    }}
                    error={errors.office_branch_id}
                  />
                )}
              />
            </Flex>

            <Flex gap="2" mt={5}>
              <InputLine
                width="100%"
                label="OBSERVAÇÕES"
                {...register("observation")}
                error={errors.observation}
              />
            </Flex>
          </Box>

          <Box
            p="4"
            pb={10}
            bg="green.50"
            minH={260}
            borderRadius={8}
            width="100%"
          >
            <Text fontWeight="bold" mb="4" color="green.800">
              LOCALIZAÇÃO E CONTATO:
            </Text>

            <Flex gap="2">
              <InputLineMask
                {...register("zip_code")}
                width="300px"
                label="CEP"
                placeholder="Informe o CEP"
                error={errors.zip_code}
                mask="99999-999"
                onBlur={(e) => {
                  let zipecodeValueName = e.currentTarget.value;
                  if (zipecodeValueName.length >= 8) {
                    getAddress(zipecodeValueName);
                  }
                }}
              />
              <InputLine
                width="100%"
                label="LOGRADOURO"
                placeholder="Informe o logradouro"
                {...register("street")}
                error={errors.street}
              />
            </Flex>

            <Flex gap="2" mt={5}>
              <InputLine
                width="300px"
                label="NÚMERO"
                placeholder="Informe o número"
                {...register("number")}
                error={errors.number}
              />
              <InputLine
                width="100%"
                label="BAIRRO"
                placeholder="Informe o bairro"
                {...register("district")}
                error={errors.district}
              />
            </Flex>

            <Flex gap="2" mt={5}>
              <InputLine
                width="300px"
                label="COMPLEMENTO"
                placeholder="Informe o número"
                {...register("complement")}
                error={errors.complement}
              />
              <InputLine
                width="100%"
                label="MUNICÍPIO/UF"
                placeholder="Informe a cidade"
                {...register("city")}
                error={errors.city}
              />
            </Flex>

            <Flex gap="2" mt={5}>
              <InputLineMask
                width="300px"
                label="TELEFONE"
                placeholder="Informe o número de telefone"
                mask="(99)99999-99999"
                {...register("cell_phone")}
                error={errors.cell_phone}
              />
              <InputLineMask
                width="100%"
                label="CELULAR"
                placeholder="Informe o número de celular"
                mask="(99)99999-99999"
                {...register("cell_whatsapp")}
                error={errors.cell_whatsapp}
              />
            </Flex>
          </Box>
        </Flex>
        <Button
          type="submit"
          bg="green.600"
          color="white"
          alignSelf="flex-end"
          height={10}
          _hover={{
            bg: "green.800",
          }}
          mt="6"
          size="lg"
          isLoading={isSubmitting}
        >
          Salvar
        </Button>
      </Flex>
    </Box>
  );
}
